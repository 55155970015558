import { AnimatePresence, motion } from "framer-motion";
import React from "react";

import { useSparkCookie } from "../common/SparkCookie";
import { VISIBILITY_BANNER_COOKIE_KEY } from "./";
import { useStudentAssessmentContext } from "../StudentAssessment";
import { XMarkIcon } from "@heroicons/react/16/solid";

const VisibilityInfoBanner = () => {
  const { targetGoal } = useStudentAssessmentContext();
  const [isDismissed, setIsDismissed] = useSparkCookie(VISIBILITY_BANNER_COOKIE_KEY, "false");
  const onDismiss = () => {
    setIsDismissed("true");
  };

  return (
    <AnimatePresence>
      {isDismissed === "false" ? (
        <motion.div
          className="bg-blue-50 border-b border-blue-100 rounded-tr-lg space-y-2 sm:space-y-3 overflow-hidden"
          initial={{ height: 0 }}
          animate={{ height: "auto" }}
          exit={{ height: 0 }}
        >
          <div className="flex items-center justify-between pt-3.5 px-4 sm:px-5">
            <h5 className="font-semibold text-gray-700 sm:pt-1.5 sm:pl-5 text-xs sm:text-sm">
              Why am I seeing this?
            </h5>
            <button
              className="flex items-center space-x-1.5 text-xs text-gray-500 hover:text-gray-700 hover:underline"
              onClick={onDismiss}
              type="button"
            >
              <XMarkIcon className="w-4 h-4" />
              Close
            </button>
          </div>
          <div className="pb-3.5 sm:pb-5 px-4 sm:px-10 text-gray-600 text-xs sm:text-sm">
            <p>
              This drill provides additional at-bats for letter name practice. It disappears once
              the student reaches the goal of <strong>{targetGoal}</strong> Letters Correct Per
              Minute (LCPM).
            </p>
          </div>
        </motion.div>
      ) : null}
    </AnimatePresence>
  );
};

export default VisibilityInfoBanner;
