import React from "react";
import { Tooltip, TooltipContent, TooltipTrigger } from "../Tooltip";

export default function oneLessonADayBadge({
  "data-testid": testId = "xBadge",
  children,
  tooltip,
}) {
  return (
    <Tooltip>
      <TooltipTrigger>
        <span data-testid={testId}>{children}</span>
      </TooltipTrigger>
      <TooltipContent>{tooltip}</TooltipContent>
    </Tooltip>
  );
}
