import capitalize from "lodash/capitalize";
import { format, parse } from "date-fns";
import React from "react";
import { ArrowPathRoundedSquareIcon, CalendarDaysIcon } from "@heroicons/react/20/solid";
import { ClockIcon } from "@heroicons/react/24/outline";

import {
  assignmentTypes,
  MERIDIEM_TIME_FORMAT_ZERO_PADDED,
  MONTH_DAY_YEAR_DATE_FORMAT,
  SHORT_DAY_OF_WEEK,
} from "../../constants";
import InvitedTimeBlockForm from "./InvitedTimeBlockForm";
import AssignmentBadge from "./AssignmentBadge";

export default ({
  assignmentType,
  endDate,
  endTime,
  schedule,
  startDate,
  startTime,
  title,
  uuid,
}) => (
  <div className="pt-3.5 pb-5 px-5">
    <div className="flex items-center mb-2">
      <h4
        className="text-sm font-medium leading-none text-zinc-700 truncate mt-0.5"
        data-heap-redact-text
      >
        {title}
      </h4>
      <AssignmentBadge assignmentType={assignmentType} />
    </div>
    <p className="flex items-center text-xs text-zinc-500 mb-1.5">
      <CalendarDaysIcon className="w-3.5 h-3.5 mr-1" />
      <span>Start date: {format(startDate, MONTH_DAY_YEAR_DATE_FORMAT)}</span>
    </p>
    {assignmentType === assignmentTypes.SUB_LONG_TERM ? (
      <p className="flex items-center text-xs text-zinc-500 mb-1.5">
        <CalendarDaysIcon className="w-3.5 h-3.5 mr-1" />
        <span>End date: {format(endDate, MONTH_DAY_YEAR_DATE_FORMAT)}</span>
      </p>
    ) : null}
    <p className="flex items-center text-xs text-zinc-500 mb-1.5">
      <ClockIcon className="w-3.5 h-3.5 mr-1" />
      <span>
        {format(startTime, MERIDIEM_TIME_FORMAT_ZERO_PADDED)} -{" "}
        {format(endTime, MERIDIEM_TIME_FORMAT_ZERO_PADDED)}
      </span>
    </p>
    {assignmentType === assignmentTypes.PRIMARY ? (
      <p className="flex items-center text-xs text-zinc-500 mb-1.5">
        <ArrowPathRoundedSquareIcon className="w-3.5 h-3.5 mr-1" />
        <span>
          Repeats{" "}
          {schedule
            .map((day) => {
              return format(
                parse(capitalize(day), SHORT_DAY_OF_WEEK, new Date()),
                SHORT_DAY_OF_WEEK
              );
            })
            .join(", ")}
        </span>
      </p>
    ) : null}
    <div className="flex items-center mt-3.5 space-x-2">
      <InvitedTimeBlockForm studentName={title} tutorStudentUuid={uuid} />
    </div>
  </div>
);
