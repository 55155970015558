import {
  addDays,
  addMonths,
  addWeeks,
  isFriday,
  isMonday,
  startOfMonth,
  startOfWeek,
  subDays,
  subMonths,
  subWeeks,
} from "date-fns";
import React, { useContext, useMemo } from "react";
import { twMerge } from "tailwind-merge";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/outline";

import { CurrentDateContext, CurrentViewContext } from "../../../contexts/Calendar";
import {
  CALENDAR_DAILY_VIEW,
  CALENDAR_MONTLY_VIEW,
  CALENDAR_WEEKLY_VIEW,
} from "../../../constants";
import { useCalendarViewMode } from "../../../hooks/useCalendarViewMode";

const DateNavigator = () => {
  const { currentView } = useContext(CurrentViewContext);
  const { setCurrentDate } = useContext(CurrentDateContext);
  const { isSubMode } = useCalendarViewMode();
  const onClickNextDate = () => {
    setCurrentDate((currentDate) => {
      if (currentView === CALENDAR_WEEKLY_VIEW) {
        return addWeeks(currentDate, 1);
      } else if (currentView === CALENDAR_MONTLY_VIEW) {
        return startOfMonth(addMonths(currentDate, 1));
      }

      return addDays(currentDate, isFriday(currentDate) ? 3 : 1);
    });
  };
  const onClickPrevDate = () => {
    setCurrentDate((currentDate) => {
      if (currentView === CALENDAR_WEEKLY_VIEW) {
        return subWeeks(currentDate, 1);
      } else if (currentView === CALENDAR_MONTLY_VIEW) {
        return startOfMonth(subMonths(currentDate, 1));
      }

      return subDays(currentDate, isMonday(currentDate) ? 3 : 1);
    });
  };
  const onClickToday = () => {
    /*
     * The calendar automatically passes `start_date` and `end_date` to the API when querying for
     * events. In weekly view, we set `currentDate` to the start of the week so we correctly get all
     * events for the whole week, rather than just those from today onward.
     */
    setCurrentDate(currentView === CALENDAR_WEEKLY_VIEW ? startOfWeek(new Date()) : new Date());
  };
  const prevButtonLabel = useMemo(() => {
    if (currentView === CALENDAR_DAILY_VIEW) {
      return "Previous day";
    } else if (currentView === CALENDAR_MONTLY_VIEW) {
      return "Previous month";
    }

    return "Previous week";
  }, [currentView]);
  const nextButtonLabel = useMemo(() => {
    if (currentView === CALENDAR_DAILY_VIEW) {
      return "Next day";
    } else if (currentView === CALENDAR_MONTLY_VIEW) {
      return "Next month";
    }

    return "Next week";
  }, [currentView]);

  return (
    <div className="flex items-center">
      <button
        aria-label={prevButtonLabel}
        className={twMerge(
          "button-secondary py-1.5 px-1.5 md:px-2 rounded-r-none",
          isSubMode ? "bg-zinc-800 text-white ring-zinc-600 hover:enabled:bg-zinc-700/30" : ""
        )}
        onClick={onClickPrevDate}
        type="button"
      >
        <ArrowLeftIcon className="w-[18px] md:w-[20px] h-[18px] md:h-[20px]" />
      </button>
      <button
        className={twMerge(
          "button-secondary text-sm rounded-none py-1.5 px-4 md:px-2.5 -ml-[1px] hidden md:block",
          isSubMode ? "bg-zinc-800 text-white ring-zinc-600 hover:enabled:bg-zinc-700/30" : ""
        )}
        onClick={onClickToday}
        type="button"
      >
        Today
      </button>
      <button
        aria-label={nextButtonLabel}
        className={twMerge(
          "button-secondary py-1.5 px-1.5 md:px-2 rounded-l-none -ml-[1px]",
          isSubMode ? "bg-zinc-800 text-white ring-zinc-600 hover:enabled:bg-zinc-700/30" : ""
        )}
        onClick={onClickNextDate}
        type="button"
      >
        <ArrowRightIcon className="w-[18px] md:w-[20px] h-[18px] md:h-[20px]" />
      </button>
    </div>
  );
};

export default DateNavigator;
