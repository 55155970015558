import React from "react";
import { BookOpenIcon, XCircleIcon } from "@heroicons/react/20/solid";
import { TrophyIcon } from "@heroicons/react/24/outline";

import { StudentAssessmentResults, StudentAssessmentResultsItem } from "../StudentAssessment";
import { useStudentAssessmentWordCountQuery } from "./queries";

export default () => {
  const studentAssessmentWordCountQuery = useStudentAssessmentWordCountQuery();
  const { attempted, correct, errored } = studentAssessmentWordCountQuery.data || {};

  return (
    <StudentAssessmentResults>
      <StudentAssessmentResultsItem
        icon={<TrophyIcon />}
        isPrimary
        label="Total Correct"
        value={correct}
      />
      <StudentAssessmentResultsItem
        icon={<BookOpenIcon />}
        label="Total Attempted"
        value={attempted}
      />
      <StudentAssessmentResultsItem icon={<XCircleIcon />} label="Errors" value={errored} />
    </StudentAssessmentResults>
  );
};
