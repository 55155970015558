import React from "react";
import { XMarkIcon } from "@heroicons/react/24/solid";

import { DrawerClose } from "./index";

const DrawerHeader = ({ children, closeButtonDataTestid }) => (
  <div className="bg-white sticky top-0 px-4 md:px-6 lg:pl-12 lg:pr-6 py-4 md:py-6 lg:pb-6 lg:pt-8 border-b flex items-center justify-between z-10">
    {children}
    <DrawerClose
      className="text-zinc-500 rounded-full hover:bg-zinc-100 hover:text-zinc-700 p-1"
      data-testid={closeButtonDataTestid}
    >
      <XMarkIcon className="w-6 h-6" />
    </DrawerClose>
  </div>
);

export default DrawerHeader;
