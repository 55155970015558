import range from "lodash/range";
import uniq from "lodash/uniq";
import React from "react";
import Select from "react-select";
import { PlusIcon, TrashIcon } from "@heroicons/react/24/outline";

import FormSelectOption from "./FormSelectOption";
import { formatTimeToMeridiem, minutesCountToTime, timeToMinutesCount } from "../../utils";

export default ({
  endDate,
  highDemandIntervals,
  maxEndTimeInMinutes,
  minStartTimeInMinutes,
  onChangeEndTime,
  onChangeStartTime,
  onOpenAddAvailabilityForm,
  onRemoveAvailability,
  startDate,
  shouldShowAddButton,
}) => {
  const endTimeInMinutes = timeToMinutesCount(endDate);
  const startTimeInMinutes = timeToMinutesCount(startDate);
  const startTimeIntervalsInMinutes = range(minStartTimeInMinutes, endTimeInMinutes, 15);
  const endTimeIntervalsInMinutes = range(startTimeInMinutes + 15, maxEndTimeInMinutes + 15, 15);
  const startTimeOptions = uniq([...startTimeIntervalsInMinutes, timeToMinutesCount(startDate)])
    .sort((a, b) => a - b)
    .map((interval) => {
      const time = minutesCountToTime(interval);

      return {
        label: formatTimeToMeridiem(time),
        value: time,
      };
    });
  const endTimeOptions = uniq([...endTimeIntervalsInMinutes, timeToMinutesCount(endDate)])
    .sort((a, b) => a - b)
    .map((interval) => {
      const time = minutesCountToTime(interval);

      return {
        label: formatTimeToMeridiem(time),
        value: time,
      };
    });

  return (
    <div className="flex items-center mb-3">
      <Select
        className="w-[124px] md:w-[134px] mr-2 md:mr-4 text-sm md:text-base"
        components={{ Option: FormSelectOption }}
        highDemandIntervals={highDemandIntervals}
        isSearchable={false}
        onChange={onChangeStartTime}
        options={startTimeOptions}
        styles={{
          menu: (baseStyles) => ({ ...baseStyles, zIndex: 5 }),
        }}
        value={{
          label: formatTimeToMeridiem(startDate),
          value: startDate,
        }}
      />
      <span className="text-gray-500 text-sm md:text-base">to</span>
      <Select
        className="w-[124px] md:w-[134px] ml-2 md:ml-4 mr-2 md:mr-3 text-sm md:text-base"
        components={{ Option: FormSelectOption }}
        highDemandIntervals={highDemandIntervals}
        isSearchable={false}
        onChange={onChangeEndTime}
        options={endTimeOptions}
        styles={{
          menu: (baseStyles) => ({ ...baseStyles, zIndex: 5 }),
        }}
        value={{
          label: formatTimeToMeridiem(endDate),
          value: endDate,
        }}
      />
      <button
        className="mr-1 md:mr-2 p-1 text-gray-500"
        onClick={onRemoveAvailability}
        type="button"
      >
        {/* Heroicon name: outline/trash */}
        <TrashIcon className="w-5 h-5" />
      </button>
      {shouldShowAddButton ? (
        <button className="p-1 text-gray-500" onClick={onOpenAddAvailabilityForm} type="button">
          {/* Heroicon name: outline/plus */}
          <PlusIcon className="w-5 h-5" />
        </button>
      ) : null}
    </div>
  );
};
