import React from "react";
import { TrophyIcon } from "@heroicons/react/24/solid";

import {
  StudentAssessmentContentCollapsed,
  StudentAssessmentReadingStatsItem,
  useStudentAssessmentQuery,
} from "../StudentAssessment";

const ContentCollapsed = () => {
  const studentAssessmentQuery = useStudentAssessmentQuery();
  const { correctWordsCount, hasEndWord } = studentAssessmentQuery.data || {};

  if (!hasEndWord) return null;

  if (!studentAssessmentQuery.data) {
    if (studentAssessmentQuery.isError) {
      return (
        <span className="flex items-center justify-center text-red-600 min-h-[200px]">
          Error: {studentAssessmentQuery.error.message}
        </span>
      );
    }

    return (
      <span className="flex items-center justify-center text-gray-400 min-h-[200px]">
        Loading...
      </span>
    );
  }

  return (
    <StudentAssessmentContentCollapsed>
      <ul className="flex space-x-1 md:space-x-4 mb-6">
        <StudentAssessmentReadingStatsItem
          emphasized
          icon={<TrophyIcon />}
          label="Total Correct"
          unitOfMeasurement="Words Correct"
          value={correctWordsCount}
        />
      </ul>
    </StudentAssessmentContentCollapsed>
  );
};

export default ContentCollapsed;
