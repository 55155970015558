import React from "react";

import { useStudentQuery } from "../Student/queries";
import { DocumentTextIcon } from "@heroicons/react/16/solid";
import UpcomingEventBanner from "../common/UpcomingEventBanner";

export const MOP_SCREENER_BANNER_COOKIE_KEY = "mop_banner_dismissed";

const UpcomingMopBanner = () => {
  const studentQuery = useStudentQuery();
  const { assessmentScriptURL, isUpcomingMop } = studentQuery.data || {};

  return (
    <UpcomingEventBanner
      visible={isUpcomingMop}
      testId="upcoming-mop-banner"
      dismissedText="MOP Screener is in less than 7 days"
      cookie={MOP_SCREENER_BANNER_COOKIE_KEY}
    >
      <h2 className="text-base font-semibold text-gray-600">Upcoming Mid of Program Screener </h2>
      <p className="text-gray-500 text-sm flex items-center">
        This student’s MOP Screener is coming up in less than 7 days, make sure you have everything
        you need.
        <a
          className="inline-flex items-center text-sm font-medium text-brand-600 hover:text-zinc-800 hover:underline ml-3 whitespace-nowrap"
          data-testid="mop-screener-script-link"
          href={assessmentScriptURL}
          target="_blank"
          rel="noreferrer"
        >
          <DocumentTextIcon className="w-5 h-5 mr-1" />
          Screener Script
        </a>
      </p>
    </UpcomingEventBanner>
  );
};

export default UpcomingMopBanner;
