import React from "react";

import { Modal, ModalClose, ModalContent, ModalTrigger } from "../common/Modal";
import { useStudentQuery } from "./queries";
import { XMarkIcon } from "@heroicons/react/24/solid";

const SpecialEducationField = () => {
  const studentQuery = useStudentQuery();
  const { specialEducationProgram, specialEducationProgramAccommodations } =
    studentQuery.data || {};

  return specialEducationProgram !== "None" && specialEducationProgramAccommodations ? (
    <Modal>
      <ModalTrigger>
        <button className="button-as-link" type="button">
          {specialEducationProgram}
        </button>
      </ModalTrigger>
      <ModalContent>
        <ModalClose className="ring-0 enabled:shadow-none absolute right-2 top-2 p-1">
          <XMarkIcon className="w-6 h-6" />
        </ModalClose>
        <div className="sm:flex sm:items-start">
          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <h3 className="text-base font-medium leading-6 text-gray-900" id="modal-title">
              {specialEducationProgram}
            </h3>
            <div>
              <p className="text-sm text-gray-500 font-normal">
                {specialEducationProgramAccommodations}
              </p>
            </div>
          </div>
        </div>
      </ModalContent>
    </Modal>
  ) : (
    specialEducationProgram
  );
};

export default SpecialEducationField;
