import React, { useMemo } from "react";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { Tooltip, TooltipContent, TooltipTrigger } from "../../common/Tooltip";
import Markdown from "react-markdown";
import PropTypes from "prop-types";

export default function TaskTooltip({
  "data-testid": dataTestId = "xTooltip",
  content,
  href,
  text,
}) {
  const testId = useMemo(() => {
    return `${dataTestId}-${text.split(" ").join("-")}-tooltip`;
  }, [dataTestId, text]);

  return (
    <Tooltip>
      <TooltipTrigger>
        <a
          data-testid={testId}
          className="font-bold underline text-brand-600"
          onClick={(event) => event.preventDefault()}
          href={href}
        >
          {text}
        </a>
      </TooltipTrigger>
      <TooltipContent>
        <div className="max-w-[360px] text-left min-w-min text-xs md:text-sm px-2 md:px-3 py-2">
          <Markdown
            components={{
              h1({ children }) {
                return (
                  <h1 className="flex flex-row items-center font-bold mb-4">
                    <InformationCircleIcon className="mr-2" height="20" width="20" />
                    {children}
                  </h1>
                );
              },
              p({ children }) {
                return <p className="mb-4">{children}</p>;
              },
              ol({ children }) {
                return <ol className="my-4 pl-8 list-decimal">{children}</ol>;
              },
              ul({ children }) {
                return <ul className="my-4 pl-8 list-disc">{children}</ul>;
              },
              li({ children }) {
                return <li className="mb-4">{children}</li>;
              },
              a({ href, children }) {
                return (
                  <a className="font-bold underline text-brand-600" href={href}>
                    {children}
                  </a>
                );
              },
            }}
          >
            {content}
          </Markdown>
        </div>
      </TooltipContent>
    </Tooltip>
  );
}

TaskTooltip.propTypes = {
  "data-testid": PropTypes.string,
  content: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};
