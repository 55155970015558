import React from "react";

import { Modal, ModalClose, ModalContent } from "./Modal";
import { XMarkIcon } from "@heroicons/react/24/outline";
import ClappingHands from "../../../assets/images/clapping-hands.svg";

const CelebratoryModal = ({ "data-testid": dataTestid, open, onOpenChange, title, text }) => (
  <Modal open={open} onOpenChange={onOpenChange}>
    <ModalContent data-testid={dataTestid}>
      <ModalClose
        className="ring-0 enabled:shadow-none absolute right-2 top-2 p-1"
        data-testid="celebratory-modal-close-button"
      >
        <XMarkIcon className="w-6 h-6" />
      </ModalClose>
      <div className="flex flex-col items-center py-6">
        <ClappingHands className="w-20 h-20 mb-6" />
        <h5 className="text-gray-700 font-semibold text-lg mb-3">{title}</h5>
        <p className="text-gray-500 text-center">{text}</p>
      </div>
    </ModalContent>
  </Modal>
);

export default CelebratoryModal;
