import kebabCase from "lodash/kebabCase";
import React from "react";
import { PhonologicalAwareness } from "@Ignite-Reading/ui-kit/icons";

import { StudentAssessment, StudentAssessmentHeader } from "../StudentAssessment";
import ContentCollapsed from "./ContentCollapsed";
import ContentExpanded from "./ContentExpanded";
import { studentOneMinuteChallengeType } from "../../constants";

const {
  InitialSoundSorting,
  SayItAndMoveIt,
  OralBlending,
  OralSegmenting,
  TeachingLetterPatterns,
  LetterPatternMatching,
} = studentOneMinuteChallengeType;
const typeToNameMap = {
  [InitialSoundSorting]: "Initial Sound Sorting",
  [SayItAndMoveIt]: "Say It And Move It",
  [OralBlending]: "Oral Blending",
  [OralSegmenting]: "Oral Segmenting",
  [TeachingLetterPatterns]: "Letter Patterns",
  [LetterPatternMatching]: "Pattern Matching",
};
const typeToTooltipContentMap = {
  [InitialSoundSorting]: [
    <p className="mb-6" key="paragraph-1">
      This activity comes after introducing pictures and their initial sounds. Stay on the page with{" "}
      <strong>pictures only</strong>, no letters.
      <br />
      <strong>This activity is meant to be a guided practice, not a timed drill.</strong>
    </p>,
    <h5 className="font-semibold mb-1.5" key="title-2">
      Script
    </h5>,
    <p className="mb-6" key="paragraph-2">
      “I will say a word. Listen for the first sound. Tell me what (picture) word has the same first
      sound.” <em>Signal picture choices while giving these instructions.</em>
    </p>,
    <h5 className="font-semibold mb-1.5" key="title-3">
      Errors
    </h5>,
    <p className="mb-2" key="paragraph-3">
      If the student cannot match the words with the same initial sound on the 1st try, mark it and
      follow the error correction process.
    </p>,
  ],
  [SayItAndMoveIt]: [
    <p className="mb-6" key="paragraph-1">
      Show the slide with Elkonin boxes and mark each phoneme in the boxes on GSB.
      <br />
      <strong>This activity is meant to be a guided practice, not a timed drill.</strong>
    </p>,
    <h5 className="font-semibold mb-1.5" key="title-2">
      Script
    </h5>,
    <p className="mb-6" key="paragraph-2">
      “Let’s work with sounds in words! I will say a word. Then I will say it and move it! After me,
      it’s your turn! My turn: (word). <strong>/x/ /x/ /x/,.</strong> Mark each phoneme in the
      Elkonin boxes as you say it. Your turn!”
    </p>,
    <h5 className="font-semibold mb-1.5" key="title-3">
      Errors
    </h5>,
    <p className="mb-2" key="paragraph-3">
      If the student cannot copy the model correctly on the 1st try, mark it and follow error
      correction process.
    </p>,
  ],
  [OralBlending]: [
    <p className="mb-6" key="paragraph-1">
      Share the slide with the listening image.
      <br />
      <strong>This activity is meant to be a guided practice, not a timed drill.</strong>
    </p>,
    <h5 className="font-semibold mb-1.5" key="title-2">
      Script
    </h5>,
    <p className="mb-6" key="paragraph-2">
      “I will tap the sounds in a word. You tell me what word the sounds make! Watch my fingers so
      we can stay together.“
    </p>,
    <h5 className="font-semibold mb-1.5" key="title-3">
      Errors
    </h5>,
    <p className="mb-2" key="paragraph-3">
      If the student can not blend the sounds together on the 1st, try, click on the word and follow
      the error correction process.
    </p>,
  ],
  [OralSegmenting]: [
    <p className="mb-6" key="paragraph-1">
      Stay on the slide with the listening image.
      <br />
      <strong>This activity is meant to be a guided practice, not a timed drill.</strong>
    </p>,
    <h5 className="font-semibold mb-1.5" key="title-2">
      Script
    </h5>,
    <p className="mb-6" key="paragraph-2">
      “Now let’s switch! I will say a word, and you tap the sounds!“
    </p>,
    <h5 className="font-semibold mb-1.5" key="title-3">
      Errors
    </h5>,
    <p className="mb-2" key="paragraph-3">
      If the student can not segment the sounds on the 1st, try, click on the word and follow the
      error correction process.
    </p>,
  ],
  [TeachingLetterPatterns]: [
    <p className="mb-6" key="paragraph-1">
      Share the slide with the pictures and patterns. This activity is for the Guided Practice
      section.
      <br />
      <strong>This activity is meant to be a guided practice, not a timed drill.</strong>
    </p>,
    <h5 className="font-semibold mb-1.5" key="title-2">
      Script
    </h5>,
    <p className="mb-6" key="paragraph-2">
      “I’ll say a word. Listen carefully and think about the sound pattern at the end of my word.
      Then, tell me the word (picture) that matches!”{" "}
      <em>Signal the picture/word choices for matching.</em>
    </p>,
    <h5 className="font-semibold mb-1.5" key="title-3">
      Errors
    </h5>,
    <p className="mb-2" key="paragraph-3">
      If the student cannot match the patterns on the 1st try, mark it and follow the error
      correction process.
    </p>,
  ],
  [LetterPatternMatching]: [
    <p className="mb-6" key="paragraph-1">
      Share the slide with the images and clue words <strong>only</strong>.<br />
      <strong>This activity is meant to be a guided practice, not a timed drill.</strong>
    </p>,
    <h5 className="font-semibold mb-1.5" key="title-2">
      Script
    </h5>,
    <p className="mb-6" key="paragraph-2">
      “We are going to listen for vowel sounds! First, we’ll review short vowel sounds like /x/ in
      ___ and /x/ in ___. Then, we’ll listen for long vowel sounds like /X/ in ___ and /X/ in ____.
      Listen to each word I say. You’ll determine the vowel sound, then say the word with the
      matching sound.“
    </p>,
    <h5 className="font-semibold mb-1.5" key="title-3">
      Errors
    </h5>,
    <p className="mb-2" key="paragraph-3">
      If the student cannot match the vowel sounds on the 1st try, mark it and follow the error
      correction process.
    </p>,
  ],
};

const StudentPhonologicalAwarenessAssessment = (props) => (
  <StudentAssessment
    {...props}
    data-testid={`${kebabCase(props.type)}-lesson-card`}
    queryKey={["student-pa-drill", props.assessmentId]}
    resetModalContent="All phonological awareness data you marked will be erased and reset for this lesson. Are you sure you want to reset?"
    resetModalTitle="Reset Phonological Awareness"
  >
    <StudentAssessmentHeader
      timerSecondsRemaining={props.timerSecondsRemaining}
      displayTimer={props.displayTimer}
      showStickyTimer={props.showStickyTimer}
      assessmentDuration={props.durationSeconds}
      assessmentName={typeToNameMap[props.type]}
      assessmentSubtitle="Phonological Awareness"
      icon={<PhonologicalAwareness className="size-10" />}
      tooltipContent={
        <>
          <h5 className="font-semibold mb-1.5" key="title-1">
            {typeToNameMap[props.type]} Overview
          </h5>
          {typeToTooltipContentMap[props.type]}
        </>
      }
    />
    <ContentExpanded />
    <ContentCollapsed />
  </StudentAssessment>
);

export default StudentPhonologicalAwarenessAssessment;
