import React, { useCallback, useEffect } from "react";
import { twMerge } from "tailwind-merge";
import { useUpdateDurationTimerSecondsRemainingMutation } from "./queries";
import cx from "classnames";
import { BellAlertIcon, ClockIcon, PlayIcon } from "@heroicons/react/24/solid";
import { Tooltip, TooltipContent, TooltipTrigger } from "../common/Tooltip";

const Icon = ({ remaining, isPaused }) => {
  if (remaining <= 0) {
    return <BellAlertIcon className="w-6 h-6 text-black" />;
  } else if (isPaused) {
    return <PlayIcon className="w-6 h-6 text-black" />;
  } else {
    return <ClockIcon className="w-6 h-6 text-black" />;
  }
};

export default function DurationTimer({ className, durationTimer }) {
  const updateSecondsRemainingMutation = useUpdateDurationTimerSecondsRemainingMutation();

  const startTimer = () => {
    durationTimer.start();
    updateSecondsRemainingMutation.mutate(durationTimer.remaining);
  };

  const preventBrowserNavigation = useCallback(
    (event) => {
      updateSecondsRemainingMutation.mutate(durationTimer.remaining);
      event.preventDefault();
      event.returnValue = "stop";

      return "stop";
    },
    [durationTimer.remaining, updateSecondsRemainingMutation]
  );
  const preventTurboNavigation = useCallback(
    (event) => {
      if (confirm("Do you really want to leave?")) {
        updateSecondsRemainingMutation.mutate(durationTimer.remaining);
      } else {
        event.preventDefault();
      }
    },
    [durationTimer.remaining, updateSecondsRemainingMutation]
  );

  useEffect(() => {
    if (durationTimer.remaining <= 0 && durationTimer.isInProgress) {
      updateSecondsRemainingMutation.mutate(durationTimer.remaining);
    }
  }, [durationTimer.remaining]);

  useEffect(() => {
    if (durationTimer.isInProgress) {
      window.addEventListener("beforeunload", preventBrowserNavigation);
      window.addEventListener("turbo:before-visit", preventTurboNavigation);
    } else {
      window.removeEventListener("beforeunload", preventBrowserNavigation);
      window.removeEventListener("turbo:before-visit", preventTurboNavigation);
    }

    return () => {
      window.removeEventListener("beforeunload", preventBrowserNavigation);
      window.removeEventListener("turbo:before-visit", preventTurboNavigation);
    };
  }, [durationTimer.isInProgress, preventBrowserNavigation, preventTurboNavigation]);

  return (
    <div className={cx("flex items-center justify-between", className)}>
      <Tooltip>
        <TooltipTrigger>
          <button
            data-testid="timer-trigger-button"
            className={twMerge(
              cx(
                "button-secondary ring-brand-600 grow max-w-[138px] min-w-[118px] relative text-center text-lg text-brand-500 font-medium h-[32px] flex items-center justify-center",
                {
                  "!bg-yellow-50 ring-gray-400": durationTimer.remaining <= 0,
                }
              )
            )}
            onClick={durationTimer.isPaused ? startTimer : null}
            disabled={
              updateSecondsRemainingMutation.isLoading ||
              !durationTimer.isPaused ||
              durationTimer.remaining <= 0
            }
            type="button"
          >
            <Icon
              className="inline-block align-middle text-zinc-400 hover:text-zinc-500"
              remaining={durationTimer.remaining}
              isPaused={durationTimer.isPaused}
            />
            <span className="inline-block align-middle px-1.5">
              {durationTimer.minutes}:{durationTimer.seconds}
            </span>
          </button>
        </TooltipTrigger>
        <TooltipContent>
          <div className="max-w-[360px] text-left min-w-min text-xs md:text-sm text-zinc-600 px-2 md:px-3 py-2">
            Start the timer to help you stay on track!
          </div>
        </TooltipContent>
      </Tooltip>
    </div>
  );
}
