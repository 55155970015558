import React from "react";
import { ArrowLeftIcon } from "@heroicons/react/20/solid";
import ChooseStopWord from "../../../assets/images/choose-stop-word.svg";

import WordList from "./WordList";
import { useStudentAssessmentContext } from "../StudentAssessment";
import { BellAlertIcon } from "@heroicons/react/24/solid";
const PickEndWordPrompt = ({ onGoBack }) => {
  const { resource } = useStudentAssessmentContext();

  return (
    <div className="flex flex-col xl:flex-row-reverse justify-between space-y-6 xl:space-y-0">
      <div className="grow-0 shrink xl:basis-[350px] xl:pl-6 xl:border-l border-zinc-200" />
      <div className="shrink grow basis-auto flex flex-col flex-1 xl:pr-8 relative">
        <div className="border border-yellow-400 bg-yellow-50 rounded-lg px-2 xl:px-6 py-4 md:py-6 space-y-2 md:space-y-3 -mx-2.5 xl:-mx-6 -my-2.5">
          <div className="flex items-center space-x-2">
            <BellAlertIcon className="w-8 h-8 flex-none text-yellow-500" />
            <h1 className="text-2xl md:text-3xl text-zinc-500 font-bold">Add Stop</h1>
          </div>
          <div className="flex items-start max-w-[34rem]">
            <div className="flex-1 text-sm space-y-1 md:space-y-1.5 xl:space-y-0 pt-1 md:pt-4">
              <p className="text-zinc-500">
                Calculate the results by marking the last {resource} the student read.
              </p>
              <p className="text-zinc-600">
                <strong>Click on the {resource} to mark stop.</strong>
              </p>
            </div>
            <ChooseStopWord className="flex-none w-20 md:w-24" />
          </div>
          <div className="shrink grow basis-auto flex flex-col flex-1 relative">
            <div className="flex flex-col">
              <WordList onGoBack={onGoBack} shouldPickEndWord />
              <div className="flex">
                <button
                  className="button-secondary bg-transparent ring-0 enabled:shadow-none enabled:active:shadow-none enabled:hover:bg-transparent hover:text-zinc-800 hover:underline px-0 flex items-center"
                  data-testid="prev-button"
                  onClick={onGoBack}
                  type="button"
                >
                  <ArrowLeftIcon className="w-4 h-4 mr-1.5" />
                  Back
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PickEndWordPrompt;
