import React from "react";

import CopyToClipboardButton from "../common/CopyToClipboardButton";
import { ClipboardDocumentIcon } from "@heroicons/react/24/outline";
import { copyWords } from "../StudentHighFrequencyWordsMasteryDrawer/utils";
import { Drawer, DrawerContent, DrawerTrigger } from "../common/Drawer";
import StudentHighFrequencyWordsMasteryDrawer from "../StudentHighFrequencyWordsMasteryDrawer";

const typeToDescriptionMap = {
  StudentLetterNamesAndSoundsCard: [
    <div key="p1">
      This card is here to help with timing, follow the script pdf for now. Embedded scripts are
      coming soon!
    </div>,
  ],
  StudentSoundAndBlendCard: [
    <div key="p1">
      This card is here to help with timing, follow the script pdf for now. Embedded scripts are
      coming soon!
    </div>,
  ],
  StudentDecodingByAnalogyCard: [
    <div key="p1">
      This card is here to help with timing, follow the script pdf for now. Embedded scripts are
      coming soon!
    </div>,
  ],
  StudentWordDecodingCard: [
    <div key="p1">
      This card is here to help with timing, follow the script pdf for now. Embedded scripts are
      coming soon!
    </div>,
  ],
  StudentTrackingDecodableTextCard: [
    <div key="p1">
      This card is here to help with timing, follow the script pdf for now. Embedded scripts are
      coming soon!
    </div>,
  ],
  StudentSpellingCard: [
    <div key="p1">
      This card is here to help with timing, follow the script pdf for now. Embedded scripts are
      coming soon!
    </div>,
  ],
  StudentHFWReviewCard: [
    <div key="p1" className="text-zinc-500 text-md/relaxed mb-6">
      <p className="font-bold">HFW Review Options (if time allows, choose one):</p>

      <ol className="text-zinc-500 list-decimal list-inside my-6 space-y-1">
        <li>
          <strong>Reading:</strong> Take turns - tutor points/student reads, or tutor says/student
          points
        </li>
        <li>
          <strong>Writing:</strong> Student spells HFW orally or writes it (word may be visible or
          hidden)
        </li>
        <li>
          <strong>Meaning:</strong> Student reads a HFW and uses it in a sentence Cycle through
          these options as more HFWs are introduced
        </li>
      </ol>

      <p>Cycle through these options as more HFWs are introduced.</p>
    </div>,
  ],
};

const CardContents = ({ type, taughtWords }) => {
  return type === "StudentHFWReviewCard" ? (
    <p className="mb-6 bg-brand-50 p-10 rounded-lg" key="paragraph-1">
      {typeToDescriptionMap[type]}
      {taughtWords.length > 0 ? (
        <>
          <CopyToClipboardButton
            className="button-secondary ring-brand-800 grow relative text-center text-brand-800 font-medium h-[32px] flex items-center justify-center mt-2"
            text={copyWords(taughtWords)}
          >
            <ClipboardDocumentIcon className="w-3.5 h-3.5 mr-1 stroke-2" strokeWidth={2} />
            <strong className="text-sm text-brand-800">Copy Taught Words To Clipboard</strong>
          </CopyToClipboardButton>
          <Drawer>
            <DrawerTrigger>
              <button
                className="text-brand-500 hover:text-brand-800 hover:underline mt-2 text-md/relaxed font-bold"
                type="button"
              >
                Current Mastery
              </button>
            </DrawerTrigger>
            <DrawerContent>
              <StudentHighFrequencyWordsMasteryDrawer />
            </DrawerContent>
          </Drawer>
        </>
      ) : (
        <div className="text-zinc-400 min-h-[200px] flex items-center justify-center text-md/relaxed">
          There are no High Frequency Words marked as taught yet
        </div>
      )}
    </p>
  ) : (
    <p className="mb-6 bg-brand-50 p-10 rounded-lg text-md/relaxed" key="paragraph-1">
      {typeToDescriptionMap[type]}
    </p>
  );
};

export default CardContents;
