import { differenceInMilliseconds, addSeconds, isValid, parseISO } from "date-fns";
import { NOW } from "./enums";

export const calculateSecondsRemaining = (secondsRemaining) => {
  if (secondsRemaining) {
    const from = parseISO(new Date().toISOString());
    // secondsRemaining can be a string or a number
    const to = addSeconds(from, secondsRemaining);

    if (isValid(to)) {
      return differenceInMilliseconds(to, from);
    } else {
      return 0;
    }
  } else {
    return 0;
  }
};

export const calculateTimeBetweenFromTo = (from, to) => {
  if (to) {
    from = from === NOW ? new Date() : new Date(from);
    to = to === NOW ? new Date() : new Date(to);

    if (isValid(to) && isValid(from)) {
      to = parseISO(to.toISOString());
      from = parseISO(from.toISOString());

      return differenceInMilliseconds(to, from);
    } else {
      return 0;
    }
  } else {
    return 0;
  }
};

export const calculateContinueFromTimestamp = (timestamp, tick) => {
  const then = parseISO(new Date(timestamp).toISOString());
  const now = parseISO(new Date().toISOString());

  const change = differenceInMilliseconds(now, then);

  return tick - change;
};
