import { format, parseISO } from "date-fns";
import React from "react";
import { BestRecord, Goal } from "@Ignite-Reading/ui-kit/icons";
import { TrophyIcon } from "@heroicons/react/24/solid";

import ReadingStatsItem from "./ReadingStatsItem";
import { readingAssessmentStatusType, SHORT_DATE } from "../../constants";
import { useUpdateGoalMutation, useStudentAssessmentQuery } from "./queries";
import { useStudentAssessmentContext } from "./index";

export default () => {
  const context = useStudentAssessmentContext();
  const studentAssessmentQuery = useStudentAssessmentQuery();
  const updateGoalMutation = useUpdateGoalMutation();
  const { assessmentRecord, goalWordsPerMinute, status, wordsPerMinute } =
    studentAssessmentQuery.data || {};

  return (
    <ul className="flex space-x-1 md:space-x-4 mb-6">
      <ReadingStatsItem
        icon={<BestRecord className="size-7" />}
        label={`Highest Record ${assessmentRecord?.createdAt ? format(parseISO(assessmentRecord.createdAt), SHORT_DATE) : ""}`}
        value={assessmentRecord?.wordsPerMinute}
      />
      <ReadingStatsItem
        icon={<Goal className="size-7" />}
        isEditable={!context?.isConditional}
        label="Goal"
        mutation={updateGoalMutation}
        value={goalWordsPerMinute}
      />
      {status === readingAssessmentStatusType.COMPLETED ? (
        <ReadingStatsItem
          emphasized
          icon={<TrophyIcon className="size-7" />}
          label="Total Correct"
          value={wordsPerMinute}
        />
      ) : null}
    </ul>
  );
};
