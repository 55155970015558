import isFunction from "lodash/isFunction";
import React from "react";

import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { readingAssessmentStatusType } from "../../constants";
import {
  useCompleteAssessmentMutation,
  useStudentAssessmentContext,
  useStudentAssessmentQuery,
} from "../StudentAssessment";

const { COMPLETED } = readingAssessmentStatusType;

export default function CompleteAssessmentForm() {
  // NOTE: These queries must be here because StudentAssessment
  // is a provider and is required to use the hooks
  const studentAssessmentQuery = useStudentAssessmentQuery();
  const { mutate: onUpdateAssessment } = useCompleteAssessmentMutation();
  const { onCompleteAssessment } = useStudentAssessmentContext();
  const { setOpen } = useStudentAssessmentContext();

  const onComplete = () => {
    if (isFunction(onCompleteAssessment)) {
      // NOTE: the method expects an event,
      // but only to prevent it. We don't need
      // to use a form to capture an event, but
      // we can send a generic event.
      onCompleteAssessment(new Event(null));
    } else {
      onUpdateAssessment(null, {
        onSuccess: () => {
          setOpen(false);
        },
      });
    }
  };

  const { status } = studentAssessmentQuery.data || {};

  if (status === COMPLETED) return false;

  return (
    <button
      className="button-primary w-full self-center flex items-center justify-center"
      data-testid="mark-as-done"
      onClick={onComplete}
      type="button"
    >
      <CheckCircleIcon className="w-5 h-5 mr-2" />
      Mark as Done
    </button>
  );
}
