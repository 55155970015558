import React, { useMemo, useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { Popover, PopoverContent, PopoverTrigger } from "../common/Popover";
import { twMerge } from "tailwind-merge";
import cx from "classnames";
import { isPast, parse } from "date-fns";
import { MERIDIEM_TIME_FORMAT } from "../../constants";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import Pill from "../common/Pill";
import FeatureToggle from "../common/FeatureToggle";

export default function StudentPicker({ students }) {
  const [open, setOpen] = useState(false);
  const { id } = useParams();
  const ulRef = useRef(null);
  const selectedRef = useRef(null);

  const selectedStudent = useMemo(() => {
    return students?.find(({ studentId }) => studentId === parseInt(id, 10));
  }, [students, id]);

  const today = useMemo(() => {
    const now = new Date();

    return new Date(now.getFullYear(), now.getMonth(), now.getDate());
  }, []);

  useEffect(() => {
    const scrollToSelectedStudent = () => {
      if (open) {
        if (selectedRef.current && ulRef.current) {
          const ulRect = ulRef.current.getBoundingClientRect();
          const studentRect = selectedRef.current.getBoundingClientRect();
          const offset = studentRect.top - ulRect.top;

          ulRef.current.scrollTop = offset;
        } else {
          // If the ref is not yet assigned, try again after a short delay
          setTimeout(scrollToSelectedStudent, 100);
        }
      }
    };

    scrollToSelectedStudent();
  }, [id, open]);

  if (!selectedStudent) return false;

  return (
    <Popover onOpenChange={setOpen} open={open} placement="bottom-start" toggle>
      <PopoverTrigger>
        <button
          type="button"
          data-testid="student-picker-button"
          className="flex items-center md:text-xl lg:text-2xl font-semibold text-gray-900 flex-grow whitespace-nowrap truncate"
        >
          {selectedStudent.displayName}
          <ChevronDownIcon
            className={cx("ml-2 w-4 h-4 transition-transform", {
              "-rotate-180": open,
            })}
          />
          {selectedStudent.hasMultipleProgramEnrollments && (
            <span className="rounded-full px-2.5 py-0.5 text-xs font-medium bg-blue-100 text-blue-800 ml-2">
              Accelerator Pack
            </span>
          )}
          {selectedStudent.isOneLessonADay && (
            <span
              data-testid="one-lesson-a-day-pill"
              className="rounded-full px-2.5 py-0.5 text-xs font-medium bg-brand-100 text-fuchsia-900 ml-2"
            >
              One Lesson a Day
            </span>
          )}
          <FeatureToggle
            featureName="one8_workbook_cohort"
            splitKey={`Student:${selectedStudent.studentUuid}`}
          >
            <FeatureToggle.On>
              <Pill
                data-testid="one8-workbook-cohort-pill"
                className="text-brand-800 bg-fuchsia-200 ml-2"
              >
                One8 Workbook Cohort
              </Pill>
            </FeatureToggle.On>
          </FeatureToggle>
        </button>
      </PopoverTrigger>
      <PopoverContent hideArrow hideCloseButton>
        <ul
          ref={ulRef}
          data-testid="student-picker-dropdown"
          className="py-1 min-w-[180px] max-w-[420px] max-h-[150px] flex flex-col overflow-auto"
          aria-labelledby="dropdownDefaultButton"
        >
          {students.map(
            ({
              displayName,
              hasMultipleProgramEnrollments,
              studentId,
              studentUuid,
              tutoringBlock,
              isOneLessonADay,
            }) => {
              return (
                <li
                  key={studentId}
                  ref={studentId === selectedStudent.studentId ? selectedRef : null}
                >
                  <Link
                    to={`/students/${studentId}`}
                    onClick={() => {
                      setOpen(false);
                    }}
                    className={twMerge(
                      cx(
                        "flex justify-between text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer w-full truncate",
                        {
                          "bg-brand-600 text-white hover:bg-brand-700 hover:text-white":
                            studentId === selectedStudent.studentId,
                        }
                      )
                    )}
                  >
                    <span
                      className={cx({
                        "text-gray-400": isPast(parse(tutoringBlock, MERIDIEM_TIME_FORMAT, today)),
                      })}
                    >
                      {displayName}
                      {hasMultipleProgramEnrollments && (
                        <span className="rounded-full px-2.5 py-0.5 text-xs font-medium bg-blue-100 text-blue-800 ml-2">
                          Accelerator Pack
                        </span>
                      )}
                      {isOneLessonADay && (
                        <Pill
                          data-testid={`one-lesson-a-day-list-${studentId}`}
                          className="bg-brand-100 text-fuchsia-900 ml-2"
                        >
                          One Lesson a Day
                        </Pill>
                      )}
                      <FeatureToggle
                        featureName="one8_workbook_cohort"
                        splitKey={`Student:${studentUuid}`}
                      >
                        <FeatureToggle.On>
                          <Pill
                            data-testid={`one8-workbook-cohort-list-${studentId}`}
                            className="text-brand-800 bg-fuchsia-200 ml-2"
                          >
                            One8 Workbook Cohort
                          </Pill>
                        </FeatureToggle.On>
                      </FeatureToggle>
                    </span>
                    <span className="text-gray-500 ml-6">{tutoringBlock}</span>
                  </Link>
                </li>
              );
            }
          )}
        </ul>
      </PopoverContent>
    </Popover>
  );
}
