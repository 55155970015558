import React, { useMemo } from "react";
import { STATUS, STATUSES_WITH_TOTAL_COUNTS } from "./status";
import cx from "classnames";
import { Tooltip, TooltipContent, TooltipTrigger } from "../../common/Tooltip";

export default function AssessmentStatusPill({
  correctCount = 0,
  status = STATUS.UNKNOWN,
  totalCount = 0,
  wpmCorrected = false,
}) {
  const label = useMemo(() => {
    let value = STATUS[status.toUpperCase()];

    if (STATUSES_WITH_TOTAL_COUNTS.includes(status)) {
      value = `${value} - ${correctCount}/${totalCount}`;
    }

    return value;
  }, [correctCount, status, totalCount]);

  return (
    <Tooltip enabled={!!wpmCorrected}>
      <TooltipTrigger>
        <span
          className={cx("text-xs rounded-full px-4 py-1 font-semibold uppercase", {
            "bg-yellow-100 text-yellow-800": status === STATUS.INCOMPLETE,
            "bg-gray-50 text-gray-800": status === STATUS.UNKNOWN,
            "bg-green-100 text-green-800": status === STATUS.COMPLETED || status === STATUS.PASSED,
            "bg-red-100 text-red-800": status === STATUS.FAILED,
            "cursor-pointer select-none": !!wpmCorrected,
          })}
        >
          {!!wpmCorrected && <span>*</span>}
          {label}
        </span>
      </TooltipTrigger>
      <TooltipContent dark>
        <span>*Score corrected by the Ignite Reading team</span>
      </TooltipContent>
    </Tooltip>
  );
}
