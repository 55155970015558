import cx from "classnames";
import React, { useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import { twMerge } from "tailwind-merge";

import LessonPlan from "../LessonPlan";
import RehearsalBanner from "../common/RehearsalBanner";
import CompleteLessonModal from "../StudentProgress/CompleteLessonModal";
import { useCurrentContentQuery } from "./queries";
import { useStartAssessmentMutation } from "../StudentProgress/queries";
import { useFlagOn } from "../../hooks/useFeatureToggle";
import AutoReteachFocusBanner from "../AutoReteachFocusBanner";
import { BellIcon } from "@heroicons/react/24/outline";
import { DocumentTextIcon } from "@heroicons/react/16/solid";
import { CheckCircleIcon } from "@heroicons/react/24/outline";

export default function CurrentContent() {
  const [isCompleteWithoutUpdate, setCompleteWithoutUpdate] = useState({});
  const { id: studentId } = useParams();
  const startLessonButton = useFlagOn("start_lesson_button");
  const currentContentQuery = useCurrentContentQuery();
  const {
    contentDisplayName,
    contentURL,
    scriptURL,
    displayReteachBanner,
    id,
    isAssessment,
    isLesson,
    linkToEditStudentAssessment,
    mostRecentAssessmentIsInProgress,
    protocolType,
    studentAssessmentableId,
    studentAssessmentableType,
    studentInstructionalPlanId,
    studentLessonId,
    studentLessonIsCompleted,
    studentProtocolId,
    showStart,
    showResume,
  } = currentContentQuery.data || {};

  const isDisabledForStudentLesson = useMemo(() => {
    return !!(studentLessonIsCompleted || isCompleteWithoutUpdate[studentLessonId]);
  }, [studentLessonIsCompleted, isCompleteWithoutUpdate, studentLessonId]);

  const isCompleteLessonDisabled = useMemo(() => {
    if (startLessonButton) {
      return isDisabledForStudentLesson || showStart || showResume;
    } else {
      return isDisabledForStudentLesson;
    }
  }, [startLessonButton, isDisabledForStudentLesson, showStart, showResume]);

  const startAssessmentMutation = useStartAssessmentMutation({
    assessmentId: id,
    studentAssessmentableId,
    studentAssessmentableType,
    studentInstructionalPlanId,
  });

  const onStartAssessment = (event) => {
    startAssessmentMutation.mutate();
    event.preventDefault();
  };

  if (!currentContentQuery.data) {
    if (currentContentQuery.isError) {
      return (
        <span className="flex items-center justify-center text-red-600 min-h-[200px]">
          Error: {currentContentQuery.error.message}
        </span>
      );
    }

    return (
      <span className="flex items-center justify-center text-gray-400 min-h-[200px]">
        Loading...
      </span>
    );
  }

  return (
    <div className="bg-zinc-50 p-4 md:p-6 xl:p-10">
      <RehearsalBanner />
      {isLesson ? (
        [
          <div className="flex items-center justify-between text-sm space-x-2" key="header">
            <div className="flex items-center space-x-2 min-w-0">
              <h3 className="truncate min-w-0 font-semibold text-lg/tight sm:text-xl/tight">
                {contentDisplayName}
              </h3>
              <a
                className="flex inline items-center pl-2 text-brand-500 hover:text-brand-800 hover:underline"
                data-testid={`lesson-${id}-content-url`}
                href={contentURL}
                rel="noreferrer"
                target="_blank"
              >
                <DocumentTextIcon className="w-4 h-4 mr-1" />
                Student Materials
              </a>
              <a
                className="flex inline items-center pl-2 text-brand-500 hover:text-brand-800 hover:underline"
                data-testid={`lesson-${id}-script-url`}
                href={scriptURL}
                rel="noreferrer"
                target="_blank"
              >
                <DocumentTextIcon className="w-4 h-4 mr-1" />
                Script
              </a>
            </div>
            <div>
              <CompleteLessonModal
                studentLessonId={studentLessonId}
                studentProtocolId={studentProtocolId}
                lessonId={id}
                isBaseline={isAssessment}
                onComplete={() => {
                  setCompleteWithoutUpdate({ ...isCompleteWithoutUpdate, [studentLessonId]: true });
                }}
              >
                <button
                  className={twMerge(
                    cx(
                      "button-secondary inline-flex items-center justify-center py-3 pl-8 md:pl-4 md:w-36 relative",
                      {
                        "disabled:bg-green-100 disabled:text-green-800 enabled:hover:bg-green-100":
                          isDisabledForStudentLesson,
                      }
                    )
                  )}
                  data-testid="complete-lesson-modal-trigger"
                  disabled={isCompleteLessonDisabled}
                  type="button"
                >
                  {isDisabledForStudentLesson
                    ? [
                        <CheckCircleIcon className="h-4 w-4 absolute left-2" key="svg" />,
                        <span key="label">Completed</span>,
                      ]
                    : "Complete Lesson"}
                </button>
              </CompleteLessonModal>
            </div>
          </div>,
          displayReteachBanner && (
            <AutoReteachFocusBanner
              studentId={studentId}
              studentLessonId={studentLessonId}
              key="reteach-banner"
            />
          ),
          <LessonPlan studentId={studentId} studentLessonId={studentLessonId} key="lesson-cards" />,
        ]
      ) : isAssessment ? (
        <div className="divide-y space-y-6">
          <div className="flex flex-row items-center justify-between text-sm space-x-2">
            <h3 className="truncate min-w-0">{contentDisplayName}</h3>
            {mostRecentAssessmentIsInProgress ? (
              <a
                className="button-primary min-w-[9rem] whitespace-nowrap shrink-0"
                data-testid="student-continue-assessment"
                href={linkToEditStudentAssessment}
              >
                Continue Assessment
              </a>
            ) : (
              <form onSubmit={onStartAssessment}>
                <button
                  className="button-primary min-w-[9rem] whitespace-nowrap shrink-0"
                  data-testid="student-start-assessment"
                  disabled={startAssessmentMutation.isLoading}
                  type="submit"
                >
                  Start Assessment
                </button>
              </form>
            )}
          </div>
          {protocolType === "Screener" && (
            <div
              className="flex items-center space-x-2 pt-7"
              data-testid="reminder-continue-lesson-banner"
            >
              <BellIcon className="w-6 h-6" />
              <p className="text-sm text-zinc-600">
                <strong>Just a reminder:</strong> After the assessment, you&apos;ll continue with
                the student&apos;s lesson.{" "}
              </p>
            </div>
          )}
        </div>
      ) : (
        <div className="text-sm text-yellow-800 border border-yellow-300 bg-yellow-50 rounded-md py-3 px-4">
          This student has completed all content.
        </div>
      )}
    </div>
  );
}
