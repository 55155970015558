import React from "react";
import PropTypes from "prop-types";
import { ArrowUturnLeftIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { AddStopWord } from "@Ignite-Reading/ui-kit/icons";

export default function Popover({
  "data-testid": dataTestId,
  disabled,
  onAddError,
  onMarkStopWord,
  onUndoError,
}) {
  return (
    <div
      data-testid={`${dataTestId}-popover`}
      className="flex flex-col -mx-3.5 -my-2.5 divide-y divide-zinc-300"
    >
      <button
        className="button-secondary rounded-b-none ring-0 flex items-center group"
        disabled={disabled}
        onClick={onAddError}
        type="button"
      >
        <XCircleIcon className="w-4 h-4 text-zinc-500 group-disabled:text-zinc-400 mr-2" />
        Add error
      </button>
      <button
        className="button-secondary rounded-none ring-0 flex items-center group"
        disabled={disabled}
        onClick={onUndoError}
        type="button"
      >
        <ArrowUturnLeftIcon className="w-4 h-4 text-zinc-500 group-disabled:text-zinc-400 mr-2" />
        Undo error
      </button>
      <button
        className="button-secondary rounded-t-none ring-0 flex items-center group"
        disabled={disabled}
        onClick={onMarkStopWord}
        type="button"
      >
        <AddStopWord className="w-4 h-4 text-zinc-500 group-disabled:text-zinc-400 mr-2" />
        Stop here
      </button>
    </div>
  );
}
Popover.propTypes = {
  "data-testid": PropTypes.string,
  disabled: PropTypes.bool.isRequired,
  onAddError: PropTypes.func.isRequired,
  onMarkStopWord: PropTypes.func.isRequired,
  onUndoError: PropTypes.func.isRequired,
};
