import React, { useMemo } from "react";
import { TrophyIcon } from "@heroicons/react/24/solid";
import StudentAssessmentReadingStatsItem from "../StudentAssessment/ReadingStatsItem";
import { useStudentAssessmentQuery } from "../StudentAssessment/queries";
import { sortTaskSequences } from "./TaskList/utils";
import { readingAssessmentStatusType as READING_ASSESSMENT_STATUS_TYPE } from "../../constants";

export default function () {
  const studentAssessmentQuery = useStudentAssessmentQuery();

  const { status, readingAssessmentTasks } = useMemo(() => {
    return studentAssessmentQuery?.data || {};
  }, [studentAssessmentQuery?.data]);

  const { correctWordsCount } = useMemo(() => {
    return (
      (readingAssessmentTasks?.length && [...sortTaskSequences(readingAssessmentTasks)].pop()) || {}
    );
  }, [readingAssessmentTasks]);

  const isCompleted = useMemo(() => {
    return status === READING_ASSESSMENT_STATUS_TYPE.COMPLETED;
  }, [status]);

  if (!isCompleted) return false;

  return (
    <ul data-testid="shared-reading-stats" className="flex space-x-1 md:space-x-4 mb-6">
      <StudentAssessmentReadingStatsItem
        icon={<TrophyIcon />}
        label="Total Correct"
        value={correctWordsCount}
        unitOfMeasurement="Word Count"
      />
    </ul>
  );
}
