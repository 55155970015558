import uniqueId from "lodash/uniqueId";
import get from "lodash/get";
import React, { useState } from "react";
import { InformationCircleIcon, SparklesIcon } from "@heroicons/react/20/solid";

import {
  TUTOR_AVAILABILITY_START_HOUR_EASTERN,
  TUTOR_AVAILABILITY_END_HOUR_EASTERN,
} from "../../constants";
import AddNewAvailabilityControls from "./AddNewAvailabilityControls";
import FormAvailabilityControls from "./FormAvailabilityControls";
import { timeToMinutesCount } from "../../utils";
import { Tooltip, TooltipContent, TooltipTrigger } from "../common/Tooltip";

export default ({ availabilities, highDemandIntervals, setAvailabilities, timeZoneOffset }) => {
  const availabilitiesCount = availabilities.filter(
    (availability) => !availability._destroy
  ).length;
  const [shouldShowAddNewAvailabilityControls, setShouldShowAddNewAvailabilityControls] =
    useState(false);
  const onOpenAddAvailabilityForm = () => {
    setShouldShowAddNewAvailabilityControls(true);
  };
  const onAddAvailability = (startTime, endTime) => {
    setAvailabilities((prevAvailabilities) => {
      return [
        ...prevAvailabilities,
        {
          uuid: uniqueId("localtime"),
          end_time: endTime,
          start_time: startTime,
        },
      ];
    });
    setShouldShowAddNewAvailabilityControls(false);
  };
  const onRemoveAvailability = (uuid) => () => {
    setAvailabilities((prevAvailabilities) =>
      prevAvailabilities.map((availability) => {
        return availability.uuid === uuid ? { ...availability, _destroy: true } : availability;
      })
    );
  };
  const onChangeStartTime =
    (uuid) =>
    ({ value }) => {
      setAvailabilities((prevAvailabilities) => {
        return prevAvailabilities.map((availability) => {
          return availability.uuid === uuid ? { ...availability, start_time: value } : availability;
        });
      });
    };
  const onChangeEndTime =
    (uuid) =>
    ({ value }) => {
      setAvailabilities((prevAvailabilities) => {
        return prevAvailabilities.map((availability) => {
          return availability.uuid === uuid ? { ...availability, end_time: value } : availability;
        });
      });
    };

  return (
    <div className="flex-1 lg:pl-20">
      <div className="border-t pt-8 lg:pt-0 lg:border lg:rounded-lg">
        <div className="lg:border-b lg:px-6 lg:py-4 flex items-center justify-between">
          <h3 className="text-sm md:text-base font-semibold text-gray-700">
            Monday-Friday Availability
          </h3>
          <div className="flex items-center lg:hidden">
            <SparklesIcon className="text-yellow-300 w-3.5 h-3.5 mr-0.5" />
            <p className="flex items-center text-zinc-500 text-xs">
              <em>- High demand times</em>
              <Tooltip>
                <TooltipTrigger>
                  <button
                    className="flex items-center justify-center w-4 h-4 ml-1 text-zinc-500/80 hover:text-zinc-500"
                    type="button"
                  >
                    <InformationCircleIcon className="w-full h-full" />
                  </button>
                </TooltipTrigger>
                <TooltipContent>
                  You have a higher chance of being scheduled during these times
                </TooltipContent>
              </Tooltip>
            </p>
          </div>
        </div>
        <div className="lg:px-6 pt-4 pb-2 md:pb-5">
          <p className="text-xs md:text-sm text-slate-600">
            Enter the times you are available to tutor consistently every day Monday-Friday. Then,
            scroll down to set the maximum number of hours you&apos;d like to work per day.
          </p>
          <ul className="pt-4">
            {availabilities.map((availability, i) => {
              const nextStartTime = get(availabilities[i + 1], "start_time");
              const prevEndTime = get(availabilities[i - 1], "end_time");
              const maxEndTimeInMinutes = nextStartTime
                ? timeToMinutesCount(nextStartTime)
                : timeToMinutesCount(`${TUTOR_AVAILABILITY_END_HOUR_EASTERN + timeZoneOffset}:00`);
              const minStartTimeInMinutes = prevEndTime
                ? timeToMinutesCount(prevEndTime)
                : timeToMinutesCount(
                    `${TUTOR_AVAILABILITY_START_HOUR_EASTERN + timeZoneOffset}:00`
                  );

              return (
                <li className="flex items-center" key={availability.uuid}>
                  <FormAvailabilityControls
                    endDate={availability.end_time}
                    highDemandIntervals={highDemandIntervals}
                    maxEndTimeInMinutes={maxEndTimeInMinutes}
                    minStartTimeInMinutes={minStartTimeInMinutes}
                    onChangeEndTime={onChangeEndTime(availability.uuid)}
                    onChangeStartTime={onChangeStartTime(availability.uuid)}
                    onOpenAddAvailabilityForm={onOpenAddAvailabilityForm}
                    onRemoveAvailability={onRemoveAvailability(availability.uuid)}
                    shouldShowAddButton={
                      !shouldShowAddNewAvailabilityControls && availabilitiesCount - 1 === i
                    }
                    startDate={availability.start_time}
                  />
                </li>
              );
            })}
            {shouldShowAddNewAvailabilityControls || availabilitiesCount === 0 ? (
              <li className="flex items-center">
                <AddNewAvailabilityControls
                  availabilities={availabilities}
                  highDemandIntervals={highDemandIntervals}
                  onAddAvailability={onAddAvailability}
                  timeZoneOffset={timeZoneOffset}
                />
              </li>
            ) : null}
          </ul>
        </div>
      </div>
    </div>
  );
};
