import React from "react";
import cx from "classnames";

export default function Pill({
  children,
  className = "bg-black text-white",
  "data-testid": testId = "xPill",
}) {
  return (
    <span
      className={cx("px-2.5 py-0.5 rounded-full font-medium text-xs", className)}
      data-testid={testId}
    >
      {children}
    </span>
  );
}
