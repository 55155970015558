import React from "react";
import { Link } from "react-router-dom";

import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";

const MissingAvailabilityPrompt = ({ missingAvailabilityName }) => (
  <div className="border-b border-t -mt-[1px] border-yellow-200 bg-yellow-50 text-zinc-700 text-xs md:text-sm px-4 md:px-8 py-3 flex items-center">
    <ExclamationTriangleIcon className="w-6 h-6 text-yellow-500 mr-2" />
    <p className="mr-1" data-testid="tutor-availability-future-banner">
      Please enter your availability for {missingAvailabilityName}.{" "}
      <Link
        className="button-as-link xl:px-0 bg-transparent xl:active:bg-transparent xl:ring-0 xl:text-sm text-brand-500 hover:text-brand-800 xl:w-auto min-h-[36px] xl:min-h-fit xl:py-0 inline-flex items-center justify-center xl:hover:underline"
        to="/tutor_availabilities/"
      >
        Configure your availability schedule →
      </Link>
    </p>
  </div>
);

export default MissingAvailabilityPrompt;
