import React, { useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";
import { PlayCircleIcon, PauseCircleIcon } from "@heroicons/react/24/solid";
import { Timer } from "@Ignite-Reading/ui-kit/icons";

import {
  useStartAssessmentMutation,
  useStudentAssessmentQuery,
  useUpdateSecondsRemainingMutation,
} from "./queries";
import { readingAssessmentStatusType } from "../../constants";
import ResetButton from "./ResetButton";

export default ({ className, countdownTimer }) => {
  const [shouldResume, setShouldResume] = useState(false);
  const [isResetConfirmationModalOpen, setIsResetConfirmationModalOpen] = useState(false);
  const startAssessmentMutation = useStartAssessmentMutation();
  const studentAssessmentQuery = useStudentAssessmentQuery();
  const updateSecondsRemainingMutation = useUpdateSecondsRemainingMutation();
  const onStartCountdownTimer = () => {
    startAssessmentMutation.mutate(null, {
      onSuccess: () => {
        countdownTimer.start();
        setShouldResume(true);
      },
    });
  };
  const onResumeCountdownTimer = () => {
    countdownTimer.start();
    setShouldResume(true);
  };
  const onPauseCountdownTimer = () => {
    updateSecondsRemainingMutation.mutate(countdownTimer.remaining);
    countdownTimer.pause();
    setShouldResume(false);
  };

  useEffect(() => {
    if (isResetConfirmationModalOpen) {
      countdownTimer.pause();
    } else if (shouldResume) {
      countdownTimer.start();
    }
    /*
     * We just need `pause` and `start` from countdownTimer, but ESLint is not satisfied only with
     * those, it wants the whole things: countdownTimer, but countdownTimer is not stable (since we're
     * getting it as a prop from parent) and causes an infinite re-render of the component. A proper
     * fix would be to make it stable, but until then, we disable the rule.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isResetConfirmationModalOpen, shouldResume]);

  return studentAssessmentQuery.data?.status === readingAssessmentStatusType.IN_PROGRESS ? (
    <div className={twMerge("flex items-center justify-between", className)}>
      <button
        className="button-secondary ring-brand-600 grow max-w-[200px] min-w-[180px] relative text-center text-3xl text-brand-500 font-medium h-[44px] flex items-center justify-center"
        onClick={countdownTimer.isPaused ? onResumeCountdownTimer : onPauseCountdownTimer}
        disabled={updateSecondsRemainingMutation.isLoading}
        type="button"
      >
        {countdownTimer.minutes}:{countdownTimer.seconds}
        <span className="absolute left-0 top-1/2 -translate-y-1/2 px-2.5 text-zinc-400 hover:text-zinc-500">
          {countdownTimer.isPaused ? (
            <PlayCircleIcon className="w-6 h-6" data-one-minute-challenge-target="playIcon" />
          ) : (
            <PauseCircleIcon className="w-6 h-6" data-one-minute-challenge-target="pauseIcon" />
          )}
        </span>
      </button>
      <ResetButton
        countdownTimer={countdownTimer}
        isConfirmationModalOpen={isResetConfirmationModalOpen}
        setIsConfirmationModalOpen={setIsResetConfirmationModalOpen}
        setShouldTimerResume={setShouldResume}
      />
    </div>
  ) : (
    <button
      className="button-primary flex items-center justify-center w-full max-w-[200px] text-sm"
      disabled={startAssessmentMutation.isLoading}
      onClick={onStartCountdownTimer}
      type="button"
    >
      <Timer className="w-6 h-6 mr-2" />
      Start timer
    </button>
  );
};
