import React from "react";
import CopyToClipboardButton from "./CopyToClipboardButton";
import { ClipboardDocumentIcon } from "@heroicons/react/24/outline";

// this component only exists because react_on_rails can't nest components on the ruby side.
// when the enclosing erb is converted, delete this.
export default ({ className, text, title, testId }) => (
  <CopyToClipboardButton className={className} text={text} title={title} testId={testId}>
    <ClipboardDocumentIcon
      stroke="black"
      className="hover:bg-zinc-100 w-10 h-10 border-2 p-1 rounded-md"
    />
  </CopyToClipboardButton>
);
