import React from "react";
import { ArrowPathIcon, ExclamationTriangleIcon } from "@heroicons/react/24/outline";

import { Modal, ModalClose, ModalContent, ModalTrigger } from "../common/Modal";
import { useResetAssessmentMutation } from "./queries";
import useStudentAssessmentContext from "./useStudentAssessmentContext";

export default ({
  countdownTimer,
  isConfirmationModalOpen,
  setIsConfirmationModalOpen,
  setShouldTimerResume,
}) => {
  const { resetModalTitle, resetModalContent } = useStudentAssessmentContext();
  const resetAssessmentMutation = useResetAssessmentMutation();
  const onSubmit = (event) => {
    resetAssessmentMutation.mutate(null, {
      onSuccess: () => {
        countdownTimer.reset();
        setIsConfirmationModalOpen(false);
        setShouldTimerResume(false);
      },
    });
    event.preventDefault();
  };

  return (
    <Modal open={isConfirmationModalOpen} onOpenChange={setIsConfirmationModalOpen}>
      <ModalTrigger>
        <button
          className="button-secondary ring-0 enabled:shadow-none flex items-center text-zinc-500 ml-2"
          type="button"
        >
          <ArrowPathIcon className="w-5 h-5 mr-2" />
          Reset
        </button>
      </ModalTrigger>
      <ModalContent>
        <form onSubmit={onSubmit}>
          <div className="sm:flex sm:items-start">
            <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
              <ExclamationTriangleIcon className="h-6 w-6 text-red-600" />
            </div>
            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <h3 className="text-base font-medium leading-6 text-gray-900 py-2 mb-2">
                {resetModalTitle}
              </h3>
              <div>
                <p className="text-sm text-gray-500 font-normal">{resetModalContent}</p>
              </div>
            </div>
          </div>
          <div className="mt-5 space-y-2 sm:space-y-0 sm:mt-4 sm:flex sm:flex-row-reverse">
            <button
              className="button-primary sm:ml-3 w-full sm:w-auto"
              disabled={resetAssessmentMutation.isLoading}
              type="submit"
            >
              Submit
            </button>
            <ModalClose>Cancel</ModalClose>
          </div>
        </form>
      </ModalContent>
    </Modal>
  );
};
