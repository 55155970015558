import React from "react";
import { OneLessonADayBadge } from "@Ignite-Reading/ui-kit/icons";
import Badge from "./Badge";
import cx from "classnames";

export default function oneLessonADayBadge({
  className,
  "data-testid": testId = "one-lesson-a-day-badge",
}) {
  return (
    <Badge data-testid={testId} tooltip="One Lesson a Day">
      <OneLessonADayBadge className={cx("text-brand-700", className)} />
    </Badge>
  );
}
