import React, { useState } from "react";

import { Modal, ModalClose, ModalContent } from "../common/Modal";
import { useDeleteNoteMutation } from "./queries";
import { XMarkIcon, XCircleIcon, TrashIcon } from "@heroicons/react/24/outline";

const DeleteNote = ({ noteId }) => {
  const [open, setOpen] = useState(false);
  const mutation = useDeleteNoteMutation(noteId);
  const onSubmit = (event) => {
    mutation.mutate(null, {
      onSuccess: () => {
        setOpen(false);
      },
    });
    event.preventDefault();
  };
  const openModal = () => {
    setOpen(true);
  };

  return (
    <div>
      <button className="absolute top-50 right-0 translate-y-50" onClick={openModal}>
        <TrashIcon className="w-6 h-6" />
      </button>
      <Modal open={open} onOpenChange={setOpen}>
        <ModalContent data-testid="delete-note">
          <ModalClose
            className="ring-0 enabled:shadow-none absolute right-2 top-2 p-1"
            data-testid="celebratory-modal-close-button"
          >
            <XMarkIcon className="w-6 h-6" />
          </ModalClose>
          <div className="sm:flex sm:items-start">
            <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
              <XCircleIcon className="h-6 w-6 text-red-600" />
            </div>
            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <h3 className="text-base font-medium leading-6 text-gray-900" id="modal-title">
                Remove
              </h3>
              <div>
                <p className="text-sm text-gray-500 font-normal">
                  Are you sure you want to remove this note?
                </p>
              </div>
            </div>
          </div>
          <div className="mt-5 flex flex-col md:flex-row-reverse items-center">
            <form onSubmit={onSubmit}>
              <div className="mt-5 space-y-2 space-x-2 sm:space-y-0 sm:mt-4">
                <ModalClose>Cancel</ModalClose>
                <button
                  className="button-primary ring-red-700 bg-red-500 text-white enabled:hover:bg-red-700"
                  disabled={mutation.isLoading}
                  type="submit"
                >
                  Remove
                </button>
              </div>
            </form>
          </div>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default DeleteNote;
