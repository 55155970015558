import React from "react";

import {
  StudentAssessment,
  StudentAssessmentContentCollapsed,
  StudentAssessmentContentExpanded,
  StudentAssessmentHeader,
} from "../StudentAssessment";
import CompleteAssessmentForm from "./CompleteAssessmentForm";
import { LetterNameDrill2 } from "@Ignite-Reading/ui-kit/icons";
import CardContents from "./CardContents";

const typeToTitleMap = {
  StudentLetterNamesAndSoundsCard: "Letter Names and Sounds",
  StudentSoundAndBlendCard: "Sound and Blend",
  StudentDecodingByAnalogyCard: "Decoding by Analogy",
  StudentWordDecodingCard: "Word Decoding",
  StudentTrackingDecodableTextCard: "Tracking Decodable Text",
  StudentSpellingCard: "Spelling",
  StudentHFWReviewCard: "High Frequency Word Review",
};
const typeToIdMap = {
  StudentLetterNamesAndSoundsCard: "student-letter-names-and-sounds-card",
  StudentSoundAndBlendCard: "student-sound-and-blend-card",
  StudentDecodingByAnalogyCard: "student-decoding-by-analogy-card",
  StudentWordDecodingCard: "student-word-decoding-card",
  StudentTrackingDecodableTextCard: "student-tracking-decodable-text-card",
  StudentSpellingCard: "student-spelling-card",
  StudentHFWReviewCard: "student-hfw-review-card",
};

const StudentPlaceholderLessonCard = (props) => {
  return (
    <StudentAssessment
      {...props}
      data-testid={typeToIdMap[props.type]}
      queryKey={[typeToIdMap[props.type], props.assessmentId]}
    >
      <StudentAssessmentHeader
        timerSecondsRemaining={props.timerSecondsRemaining}
        displayTimer={props.displayTimer}
        showStickyTimer={props.showStickyTimer}
        assessmentDuration={props.durationSeconds}
        assessmentName={typeToTitleMap[props.type]}
        icon={<LetterNameDrill2 className="size-10" />}
      />
      <StudentAssessmentContentExpanded>
        <div className="flex flex-col xl:flex-row justify-between space-y-6 xl:space-y-0">
          <div className="shrink grow basis-auto flex flex-col flex-1 xl:pr-8 max-w-[80rem] relative">
            <div className="flex flex-col">
              <div>
                <CardContents type={props.type} />
              </div>
            </div>
          </div>
          <div className="grow-0 shrink xl:basis-[350px] xl:pl-6 border-zinc-200 sticky">
            <div className="xl:px-5 pt-6 xl:pb-8 flex-col">
              <CompleteAssessmentForm />
            </div>
          </div>
        </div>
      </StudentAssessmentContentExpanded>
      <StudentAssessmentContentCollapsed></StudentAssessmentContentCollapsed>
    </StudentAssessment>
  );
};
export default StudentPlaceholderLessonCard;
