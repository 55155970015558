import React, { useMemo } from "react";
import { formatHours, formatMinutes, formatSeconds } from "./formatters";

export default function CountdownTime({
  className,
  tick = 0,
  "data-testid": dataTestId = "countdown-time",
}) {
  const formattedTime = useMemo(() => {
    const hours = formatHours(tick);
    const minutes = formatMinutes(tick);
    const seconds = formatSeconds(tick);

    const total = [];

    if (hours.length > 0) {
      total.push(hours);
    }

    total.push(minutes);
    total.push(seconds);

    return total.join(":");
  }, [tick]);

  return (
    <span data-testid={dataTestId} className={className}>
      {formattedTime}
    </span>
  );
}
