import React from "react";
import { BookOpenIcon, XCircleIcon } from "@heroicons/react/20/solid";
import { TrophyIcon } from "@heroicons/react/24/outline";

import {
  StudentAssessmentResults,
  StudentAssessmentResultsItem,
  useStudentAssessmentQuery,
} from "../StudentAssessment";

export default () => {
  const studentAssessmentQuery = useStudentAssessmentQuery();
  const { attemptedWordsCount, correctWordsCount, incorrectWordsCount } =
    studentAssessmentQuery.data || {};

  return (
    <StudentAssessmentResults>
      <StudentAssessmentResultsItem
        icon={<TrophyIcon />}
        isPrimary
        label="Total Correct"
        value={correctWordsCount}
      />
      <StudentAssessmentResultsItem
        icon={<BookOpenIcon />}
        label="Total Attempted"
        value={attemptedWordsCount}
      />
      <StudentAssessmentResultsItem
        icon={<XCircleIcon />}
        label="Errors"
        value={incorrectWordsCount}
      />
    </StudentAssessmentResults>
  );
};
