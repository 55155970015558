import { format } from "date-fns";
import React from "react";
import { CalendarDaysIcon } from "@heroicons/react/20/solid";
import { ClockIcon } from "@heroicons/react/24/outline";

import { MERIDIEM_TIME_FORMAT_ZERO_PADDED, MONTH_DAY_YEAR_DATE_FORMAT } from "../../constants";
import SubSessionRequestClaim from "./SubSessionRequestClaim";

const SubSessionRequestsList = ({ sessions }) => (
  <div className="pt-0.5 w-full md:w-[320px] max-h-[calc(100vh-40px)] overflow-y-auto">
    <ul className="divide-y divide-zinc-200">
      {sessions.map((session) => (
        <li className="pt-3.5 pb-5 px-5" key={session.id}>
          <div className="flex items-center mb-2">
            <h4
              className="text-sm font-medium leading-none text-zinc-700 truncate mt-0.5"
              data-heap-redact-text
            >
              {session.displayName}
            </h4>
          </div>
          <p className="flex items-center text-xs text-zinc-500 mb-1.5">
            <CalendarDaysIcon className="w-3.5 h-3.5 mr-1" />
            <span>Date: {format(session.startTime, MONTH_DAY_YEAR_DATE_FORMAT)}</span>
          </p>
          <p className="flex items-center text-xs text-zinc-500 mb-1.5">
            <ClockIcon className="w-3.5 h-3.5 mr-1" />
            <span>
              {format(session.startTime, MERIDIEM_TIME_FORMAT_ZERO_PADDED)} -{" "}
              {format(session.endTime, MERIDIEM_TIME_FORMAT_ZERO_PADDED)}
            </span>
          </p>
          <div className="flex items-center mt-3.5 space-x-2">
            <SubSessionRequestClaim
              programEnrollmentId={session.programEnrollmentId}
              startTime={session.startTime}
            />
          </div>
        </li>
      ))}
    </ul>
  </div>
);

export default SubSessionRequestsList;
