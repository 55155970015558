import React, { useState } from "react";
import ClappingHands from "../../../assets/images/clapping-hands.svg";
import { ArrowRightIcon } from "@heroicons/react/20/solid";

import WordList from "./WordList";
import PickEndWordPrompt from "./PickEndWordPrompt";
import {
  StudentAssessmentContentExpanded,
  StudentAssessmentContinueLessonButton,
  useCompleteAssessmentMutation,
  useStudentAssessmentContext,
  useStudentAssessmentQuery,
} from "../StudentAssessment";
import Results from "./Results";
import { readingAssessmentStatusType, studentOneMinuteChallengeType } from "../../constants";

const { COMPLETED, IN_PROGRESS } = readingAssessmentStatusType;

const {
  InitialSoundSorting,
  SayItAndMoveIt,
  OralBlending,
  OralSegmenting,
  TeachingLetterPatterns,
  LetterPatternMatching,
} = studentOneMinuteChallengeType;
const typeToInstructionsMap = {
  [InitialSoundSorting]: [
    <li key="paragraph-1">Introduce pictures and initial sounds.</li>,
    <li key="paragraph-2">
      Say a word, have the student listen for the first sound and tell you which picture has the
      same first sound.
    </li>,
    <li key="paragraph-3">Click on the word to mark incorrect sound matching.</li>,
    <li key="paragraph-4">
      When done, click <strong>Next</strong>.
    </li>,
  ],
  [SayItAndMoveIt]: [
    <li key="paragraph-1">Model: Say word, segment sounds, say word. Have student repeat.</li>,
    <li key="paragraph-2">Click on the word to mark an error.</li>,
    <li key="paragraph-3">
      When done, click <strong>Next</strong>.
    </li>,
  ],
  [OralBlending]: [
    <li key="paragraph-1">
      Say the sounds in the word. Have the student blend the sounds together.
    </li>,
    <li key="paragraph-2">Click on the word to mark an error.</li>,
    <li key="paragraph-3">
      When done, click <strong>Next</strong>.
    </li>,
  ],
  [OralSegmenting]: [
    <li key="paragraph-1">Say the word and ask student to segment the sounds</li>,
    <li key="paragraph-2">Click on the word to mark an error.</li>,
    <li key="paragraph-3">
      When done, click <strong>Next</strong>.
    </li>,
  ],
  [TeachingLetterPatterns]: [
    <li key="paragraph-1">Introduce the letter patterns.</li>,
    <li key="paragraph-2">
      Say a word, have student listen for the ending pattern and tell you which picture has the same
      ending pattern.
    </li>,
    <li key="paragraph-3">Click on the word to mark an error.</li>,
    <li key="paragraph-4">
      When done, click <strong>Next</strong>.
    </li>,
  ],
  [LetterPatternMatching]: [
    <li key="paragraph-1">Introduce the vowel sounds.</li>,
    <li key="paragraph-2">
      Say a word. Have the student listen for the vowel sound and tell you which picture or word has
      the same vowel sound.
    </li>,
    <li key="paragraph-2">Click on the word to mark an error.</li>,
    <li key="paragraph-3">
      When done, click <strong>Next</strong>.
    </li>,
  ],
};

const ContentExpanded = () => {
  const { setOpen } = useStudentAssessmentContext();
  const studentAssessmentQuery = useStudentAssessmentQuery();
  const completeAssessmentMutation = useCompleteAssessmentMutation();
  const { hasEndWord, paDrillType, status } = studentAssessmentQuery.data || {};
  const [isPickingEndWord, setIsPickingEndWord] = useState(false);
  const onClickNext = () => {
    setIsPickingEndWord(true);
  };
  const onGoBack = () => {
    setIsPickingEndWord(false);
  };
  const onCompleteAssessment = (event) => {
    completeAssessmentMutation.mutate(null, {
      onSuccess: () => {
        setOpen(false);
      },
    });
    event.preventDefault();
  };

  if (!studentAssessmentQuery.data) {
    if (studentAssessmentQuery.isError) {
      return (
        <span className="flex items-center justify-center text-red-600 min-h-[200px]">
          Error: {studentAssessmentQuery.error.message}
        </span>
      );
    }

    return (
      <span className="flex items-center justify-center text-gray-400 min-h-[200px]">
        Loading...
      </span>
    );
  }

  return (
    <StudentAssessmentContentExpanded>
      <ol className="text-zinc-500 text-sm/relaxed list-decimal list-inside mb-6">
        {typeToInstructionsMap[paDrillType]}
      </ol>
      <div>
        {isPickingEndWord ? (
          <PickEndWordPrompt onGoBack={onGoBack} />
        ) : (
          <div className="flex flex-col xl:flex-row-reverse justify-between space-y-6 xl:space-y-0">
            <div className="grow-0 shrink xl:basis-[350px] xl:pl-6 xl:border-l border-zinc-200 sticky">
              {status === COMPLETED ? (
                <div className="xl:px-5 pt-6 xl:pb-8 flex-col">
                  <Results />
                </div>
              ) : hasEndWord ? (
                <div className="bg-green-50 border border-green-500 rounded-lg p-4 pb-8 flex-col relative">
                  <Results />
                  <form className="mt-20" onSubmit={onCompleteAssessment}>
                    <StudentAssessmentContinueLessonButton />
                  </form>
                  <ClappingHands className="w-16 h-16 absolute right-5 top-32" />
                </div>
              ) : null}
            </div>
            <div className="shrink grow basis-auto flex flex-col flex-1 xl:pr-8 max-w-[36rem] relative">
              <div className="flex flex-col">
                <WordList
                  shouldPickEndWord={hasEndWord && status === IN_PROGRESS}
                  disabled={status === COMPLETED}
                />
                {!hasEndWord ? (
                  <div className="flex justify-end">
                    <button
                      className="button-primary flex items-center justify-center w-full md:w-auto md:pl-8 md:pr-6 text-sm"
                      data-testid="next-button"
                      onClick={onClickNext}
                      type="button"
                    >
                      Next
                      <ArrowRightIcon className="w-4 h-4 ml-1.5" />
                    </button>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        )}
      </div>
    </StudentAssessmentContentExpanded>
  );
};

export default ContentExpanded;
