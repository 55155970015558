import kebabCase from "lodash/kebabCase";
import noop from "lodash/noop";
import React, { useState } from "react";
import { TrophyIcon } from "@heroicons/react/24/outline";
import { LetterNameDrillLines, LetterSoundDrill2 } from "@Ignite-Reading/ui-kit/icons";

import {
  resourceType,
  StudentAssessment,
  StudentAssessmentContentCollapsed,
  StudentAssessmentContentExpanded,
  StudentAssessmentReadingStats,
  StudentAssessmentTimedAssessmentContent,
} from "../StudentAssessment";
import Results from "./Results";
import UtteranceList from "./UtteranceList";
import { studentOneMinuteChallengeType } from "../../constants";
import VisibilityInfoBanner from "./VisibilityInfoBanner";
import Header from "./Header";
import CompleteConditionalAssessmentForm from "./CompleteConditionalAssessmentForm";
import CelebratoryModal from "../common/CelebratoryModal";

export const VISIBILITY_BANNER_COOKIE_KEY = "lesson_card_visibility_info_banner_dismissed";
const {
  StudentLetterNameDrill,
  StudentConditionalLetterNameDrill,
  StudentNonsenseOneMinuteChallenge,
  StudentLetterSoundDrill,
  StudentOneMinuteChallenge,
  StudentLetterNameFluency,
  StudentNonsenseWordFluency,
  StudentNonsenseWordFluencyDEAR,
} = studentOneMinuteChallengeType;

const typeToNameMap = {
  [StudentOneMinuteChallenge]: "1 Minute Challenge",
  [StudentLetterNameFluency]: "Letter Name Fluency",
  [StudentNonsenseWordFluency]: "Nonsense Word Fluency",
  [StudentNonsenseOneMinuteChallenge]: "Nonsense 1 Minute Challenge",
  [StudentLetterNameDrill]: "Letter Name Drill",
  [StudentConditionalLetterNameDrill]: "Conditional Letter Name Drill",
  [StudentLetterSoundDrill]: "Letter Sounds Drill",
  [StudentNonsenseWordFluencyDEAR]: "DEAR",
};

const typeToTooltipContentMap = {
  [StudentOneMinuteChallenge]: [
    <h5 className="font-medium mb-1.5" key="title-1">
      Overview
    </h5>,
    <p className="mb-6" key="paragraph-1">
      The 1 minute challenge provides an opportunity for our students to independently practice what
      we modeled. Student success is measured by the number of words correct per minute (WCPM).
    </p>,
    <h5 className="font-medium mb-1.5" key="title-2">
      Script
    </h5>,
    <p className="mb-1.5" key="paragraph-2">
      “Your turn to read the words on your own! Start here and go across. If you know the word, just
      say it! If you don’t know, sound and blend. See how many you can read in 1 minute. If you get
      to the end, go back to the top and keep reading until I say “Time!”
    </p>,
    <p className="mb-1.5" key="paragraph-3">
      Ready? Begin!”
    </p>,
  ],
  [StudentNonsenseOneMinuteChallenge]: [
    <h5 className="font-medium mb-1.5" key="title-1">
      Overview
    </h5>,
    <p className="mb-6" key="paragraph-1">
      The Nonsense Word 1 minute challenge provides an opportunity for our students to apply their
      newly developed decoding skills by independently reading nonsense words. Student success is
      measured by the number of words read per minute (WRPM).
    </p>,
    <h5 className="font-medium mb-1.5" key="title-2">
      Script
    </h5>,
    <p className="mb-1.5" key="paragraph-2">
      “Time for another 1 minute challenge! Your turn to read a list of nonsense words on your own!
      Start here and go across. If you know the word, just say it! If you don’t know, sound and
      blend. See how many you can read in 1 minute. If you get to the end, go back to the top and
      keep reading until I say “Time!”
    </p>,
    <p className="mb-1.5" key="paragraph-3">
      Ready? Begin!”
    </p>,
  ],
  [StudentNonsenseWordFluency]: [
    <h5 className="font-medium mb-1.5" key="title-1">
      Overview
    </h5>,
    <p className="mb-6" key="paragraph-1">
      The Nonsense Word Fluency (NWF) assessment measures a student&apos;s ability to decode
      individual phonemes and blend them together to read whole words. It uses made-up words to
      assess a student&apos;s understanding of phonics and their ability to apply decoding skills to
      unfamiliar words, thereby gauging early reading proficiency.
    </p>,
    <p className="mb-1.5" key="paragraph-2">
      Student success is measured by how many whole nonsense words a student can read in 1 minute.
    </p>,
  ],
  [StudentLetterNameDrill]: [
    <h5 className="font-medium mb-1.5" key="title">
      Overview
    </h5>,
    <p className="mb-6" key="paragraph-1">
      The letter name drill provides an opportunity for students to test their automatic recall of
      letter names. Students are given upper and lower case letters in a random order and asked to
      name as many letters as possible in 1 minute.
    </p>,
    <p className="mb-2" key="paragraph-2">
      Student success is measured by the number of letters named correctly per minute (LCPM)
    </p>,
  ],
  [StudentLetterNameFluency]: [
    <h5 className="font-medium mb-1.5" key="title">
      Overview
    </h5>,
    <p className="mb-6" key="paragraph-1">
      The Letter Name Fluency (LNF) assessment provides an opportunity for students to test their
      automatic recall of letter names. Students are given upper and lower case letters in a random
      order and asked to name as many as possible in 1 minute.
    </p>,
    <p className="mb-2" key="paragraph-2">
      Student success is measured by the number of letters named correctly in one minute.
    </p>,
  ],
  [StudentLetterSoundDrill]: [
    <h5 className="font-medium mb-1.5" key="title">
      Overview
    </h5>,
    <p className="mb-6" key="paragraph-1">
      The letter sounds drill provides an opportunity for students to test their automatic recall of
      letter sounds. Students are given upper and lower case letters in a random order and asked to
      name as many letter sounds as possible in 1 minute.
    </p>,
    <p className="mb-2" key="paragraph-2">
      Student success is measured by the number of letters named correctly per minute (LCPM)
    </p>,
  ],
  [StudentConditionalLetterNameDrill]: [
    <h5 className="font-medium mb-1.5" key="title">
      Overview
    </h5>,
    <p className="mb-6" key="paragraph-1">
      The letter name drill provides an opportunity for students to test their automatic recall of
      letter names. Students are given upper and lower case letters in a random order and asked to
      name as many letters as possible in 1 minute.
    </p>,
    <p className="mb-2" key="paragraph-2">
      Student success is measured by the number of letters named correctly per minute (LCPM)
    </p>,
  ],
  [StudentNonsenseWordFluencyDEAR]: [
    <h5 className="font-medium mb-1.5" key="title-1">
      DEAR Overview
    </h5>,
    <p className="mb-6" key="paragraph-1">
      DEAR means Drop Everything And Read. At Ignite, DEAR Day is an opportunity for us to take 3-5
      minutes with students to assess their reading fluency.
    </p>,
    <p className="mb-1.5" key="paragraph-2">
      Fluency is a student’s ability to read accurately, with expression at a rate appropriate for
      the text.
    </p>,
  ],
};
const typeToIconMap = {
  [StudentOneMinuteChallenge]: <TrophyIcon />,
  [StudentNonsenseOneMinuteChallenge]: <TrophyIcon />,
  [StudentNonsenseWordFluency]: <TrophyIcon />,
  [StudentLetterNameDrill]: <LetterNameDrillLines className="size-10" />,
  [StudentLetterNameFluency]: <LetterNameDrillLines className="size-10" />,
  [StudentNonsenseWordFluencyDEAR]: <TrophyIcon />,
  [StudentConditionalLetterNameDrill]: <LetterNameDrillLines className="size-10" />,
  [StudentLetterSoundDrill]: <LetterSoundDrill2 className="size-10" />,
};

const getInstructions = (type) => {
  switch (type) {
    case StudentOneMinuteChallenge:
    case StudentNonsenseOneMinuteChallenge:
    case StudentNonsenseWordFluency:
    case StudentLetterNameFluency:
      return [
        "Start the timer",
        "If student reads the word incorrectly, click on the word",
        "Advance to next round if student repeats the list",
        "Once time's up, click on the last word attempted",
      ];
    case StudentNonsenseWordFluencyDEAR:
      return [
        "Review goal",
        "Start the timer",
        "If student reads the word incorrectly, click on the word",
        "Advance to next round if student repeats the list",
        "Once time's up, click on the last word attempted",
      ];
    default:
      return [
        "Review goal",
        "Start the timer",
        "If student reads letter incorrectly, click on the letter",
        "Advance to next round if student repeats the list",
        "Once time’s up, click on the last letter attempted",
      ];
  }
};

const StudentOneMinuteChallengeCard = (props) => {
  const isOneMinuteChallengeType = [
    StudentOneMinuteChallenge,
    StudentNonsenseOneMinuteChallenge,
    StudentNonsenseWordFluency,
    StudentNonsenseWordFluencyDEAR,
  ].includes(props.type);
  const [isCelebratoryModalOpen, setIsCelebratoryModalOpen] = useState(false);
  const openCelebratoryModal = () => {
    setIsCelebratoryModalOpen(true);
  };

  return [
    <StudentAssessment
      {...props}
      components={{
        BeforeContent: props.isConditional ? VisibilityInfoBanner : noop,
      }}
      data-testid={`${kebabCase(props.type)}-lesson-card`}
      key="assessment"
      queryKey={["student-one-minute-challenge", props.assessmentId]}
      resetModalTitle={isOneMinuteChallengeType ? "Reset 1 Minute Challenge" : "Reset Letter Drill"}
      resetModalContent={
        isOneMinuteChallengeType
          ? "All the 1 Minute Challenge data you marked will be erased and reset for this lesson. Are you sure you want to reset?"
          : "All Letter Drill data you marked will be erased and reset for this lesson. Are you sure you want to reset?"
      }
      resource={isOneMinuteChallengeType ? resourceType.WORD : resourceType.LETTER}
      openCelebratoryModal={openCelebratoryModal}
    >
      <Header
        durationSeconds={props.durationSeconds}
        timerSecondsRemaining={props.timerSecondsRemaining}
        displayTimer={props.displayTimer}
        assessmentName={`${typeToNameMap[props.type]} ${props.isConditional ? `(Set  ${props.sequenceNumber})` : ""}`}
        assessmentIsConditional={props.isConditional}
        icon={typeToIconMap[props.type]}
        tooltipContent={typeToTooltipContentMap[props.type]}
      />
      <StudentAssessmentContentExpanded>
        <StudentAssessmentTimedAssessmentContent
          components={{
            Results,
            UtteranceList,
            ...(props.isConditional
              ? {
                  CompleteAssessmentForm: CompleteConditionalAssessmentForm,
                }
              : {}),
          }}
          hideReadingStats={props.hideReadingStats}
          instructions={getInstructions(props.type)}
          submitResultsButtonLabel={props.submitResultsButtonLabel}
          showStickyTimer={props.showStickyTimer}
        />
      </StudentAssessmentContentExpanded>
      <StudentAssessmentContentCollapsed>
        <StudentAssessmentReadingStats />
      </StudentAssessmentContentCollapsed>
    </StudentAssessment>,
    props.isConditional ? (
      <CelebratoryModal
        data-testid="conditional-letter-drill-celebratory-modal"
        key="modal"
        onOpenChange={setIsCelebratoryModalOpen}
        open={isCelebratoryModalOpen}
        text={
          <>
            Hooray! You and your student hit the goal of <strong>{props.targetGoal}</strong> LCPM!
            <br />
            You’re all done with letter drills. Great job!
          </>
        }
        title="Conditional Letter Drill Completed!"
      />
    ) : null,
  ];
};

export default StudentOneMinuteChallengeCard;
