import cx from "classnames";
import React, { useMemo } from "react";
import { components } from "react-select";
import { SparklesIcon } from "@heroicons/react/20/solid";

import { timeToMinutesCount } from "../../utils";

export default ({ children, className, ...props }) => {
  const { highDemandIntervals } = props.selectProps;
  const isWithinHighDemandInterval = useMemo(() => {
    return highDemandIntervals.some(({ endTime, startTime }) => {
      const endTimeInMinutes = timeToMinutesCount(endTime);
      const startTimeInMinutes = timeToMinutesCount(startTime);
      const timeInMinutes = timeToMinutesCount(props.value);

      return timeInMinutes >= startTimeInMinutes && timeInMinutes < endTimeInMinutes;
    });
  }, [highDemandIntervals, props.value]);
  const isStartOfHighDemandInterval = useMemo(() => {
    return highDemandIntervals
      .map((interval) => interval.startTime)
      .some((time) => props.value === time);
  }, [highDemandIntervals, props.value]);

  return (
    <components.Option
      className={cx(
        "relative",
        {
          "!bg-yellow-300/10 hover:!bg-yellow-400/20":
            isWithinHighDemandInterval && !props.isSelected,
        },
        className
      )}
      {...props}
    >
      {children}
      {isStartOfHighDemandInterval ? (
        <SparklesIcon className="absolute right-1.5 top-1.5 text-yellow-300 w-3.5 h-3.5 mr-0.5" />
      ) : null}
    </components.Option>
  );
};
