import React from "react";
import Badge from "./Badge";
import { Workbook } from "@Ignite-Reading/ui-kit/icons";
import cx from "classnames";

export default function oneLessonADayBadge({
  className,
  "data-testid": testId = "one8-workbook-cohort-badge",
}) {
  return (
    <Badge data-testid={testId} tooltip="One8 Workbook Cohort">
      <Workbook height="24" width="24" className={cx("text-brand-700", className)} />
    </Badge>
  );
}
