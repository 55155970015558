import * as Turbo from "@hotwired/turbo";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

import { get, post, put } from "../../api";

export const useProtocolListQuery = () => {
  const { id: studentId } = useParams();

  return useQuery({
    queryKey: ["protocol-list", studentId],
    queryFn: async ({ signal }) => {
      const response = await get(`/students/${studentId}/student_protocols`, {
        signal,
      });

      return response.data;
    },
  });
};

export const useStudentLessonQuery = ({ isBaseline, isOpen, studentProtocolId }) => {
  const { id: studentId } = useParams();

  return useQuery({
    queryKey: ["student-lesson-list", studentId, studentProtocolId, isBaseline, isOpen],
    queryFn: async ({ signal }) => {
      const response = await get(`/students/${studentId}/protocol_contents`, {
        params: {
          student_protocol_id: studentProtocolId,
          is_baseline: isBaseline,
        },
        signal,
      });

      return response.data;
    },
    refetchOnWindowFocus: false,
    staleTime: 0,
  });
};

export const useSubmitLessonMutation = ({
  studentLessonId,
  studentProtocolId,
  lessonId,
  isBaseline,
}) => {
  const queryClient = useQueryClient();
  const { id: studentId } = useParams();

  return useMutation({
    mutationFn: (status) => {
      const params = {
        lesson_id: lessonId,
        student_protocol_id: studentProtocolId,
        status,
      };
      if (studentLessonId) {
        return put(`/students/${studentId}/student_lessons/${studentLessonId}`, params);
      } else {
        return post(`/students/${studentId}/student_lessons`, params);
      }
    },
    onSuccess: ({ data }) => {
      queryClient.setQueryData(
        ["student-lesson-list", studentId, studentProtocolId, isBaseline, true],
        (prevData = []) => {
          return prevData.map((lesson) => {
            return lesson.id === lessonId
              ? {
                  ...lesson,
                  status: data.status,
                  studentModelId: data.studentModelId,
                  completions: data.completions,
                }
              : lesson;
          });
        }
      );
      queryClient.invalidateQueries({ queryKey: ["current-content", studentId] });
      queryClient.invalidateQueries({ queryKey: ["student", studentId] });
      queryClient.invalidateQueries({ queryKey: ["lesson-plan", studentId] });
      queryClient.invalidateQueries({
        queryKey: ["student-lesson-list", studentId, studentProtocolId, isBaseline],
      });
      queryClient.invalidateQueries({ queryKey: ["student-hfw-drill", studentId] });
      queryClient.invalidateQueries({ queryKey: ["protocol-list", studentId] });
    },
  });
};

export const useStartAssessmentMutation = ({
  assessmentId,
  studentAssessmentableId,
  studentAssessmentableType,
  studentInstructionalPlanId,
}) => {
  const { id: studentId } = useParams();

  return useMutation({
    mutationFn: async () => {
      const response = await post(`/students/${studentId}/student_assessments`, {
        student_assessment: {
          assessment_id: assessmentId,
          student_assessmentable_id: studentAssessmentableId,
          student_assessmentable_type: studentAssessmentableType,
          student_instructional_plan_id: studentInstructionalPlanId,
        },
      });

      return response.data;
    },
    onSuccess: ({ studentAssessmentId }) => {
      Turbo.visit(`/students/${studentId}/student_assessments/${studentAssessmentId}/edit`);
    },
  });
};
