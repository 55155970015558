import cx from "classnames";
import React from "react";
import { SparklesIcon } from "@heroicons/react/20/solid";

export default ({ endY, startY }) => {
  return (
    <div
      className={cx(
        "flex justify-end py-[0.5px] px-[3px]",
        "bg-yellow-300/10 text-yellow-300 border-y border-yellow-400/30",
        "absolute -left-[4px] -right-[24px]"
      )}
      style={{ height: endY - startY, top: startY }}
    >
      <SparklesIcon className="w-3.5 h-3.5" />
    </div>
  );
};
