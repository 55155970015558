import { millisecondsToHours, millisecondsToMinutes, millisecondsToSeconds } from "date-fns";

export const formatHours = (milliseconds) => {
  const hours = new String(Math.floor(millisecondsToHours(milliseconds) % 60)).padStart(2, "0");

  if (hours === "00") {
    return "";
  } else {
    return hours;
  }
};

export const formatMinutes = (milliseconds) => {
  return new String(Math.floor(millisecondsToMinutes(milliseconds) % 60)).padStart(2, "0");
};

export const formatSeconds = (milliseconds) => {
  return new String(Math.floor(millisecondsToSeconds(milliseconds) % 60)).padStart(2, "0");
};
