import isFunction from "lodash/isFunction";
import React, { useEffect, useState } from "react";
import { Goal } from "@Ignite-Reading/ui-kit/icons";

import {
  StudentAssessmentContinueLessonButton,
  useCompleteAssessmentMutation,
  useStudentAssessmentContext,
} from "../StudentAssessment";
import { useStudentAssessmentWordCountQuery } from "./queries";

const CompleteAssessmentForm = ({ hideReadingStats, submitButtonLabel }) => {
  const completeAssessmentMutation = useCompleteAssessmentMutation();
  const studentAssessmentWordCountQuery = useStudentAssessmentWordCountQuery();
  const { onCompleteAssessment } = useStudentAssessmentContext();
  const [nextGoalValue, setNextGoalValue] = useState(
    studentAssessmentWordCountQuery.data?.correct ?? ""
  );
  const { setOpen } = useStudentAssessmentContext();
  const onSubmit = (event) => {
    if (isFunction(onCompleteAssessment)) {
      onCompleteAssessment(event);
    } else {
      completeAssessmentMutation.mutate(nextGoalValue, {
        onSuccess: () => {
          setOpen(false);
        },
      });
    }
    event.preventDefault();
  };
  const onChangeNextGoalValue = (event) => {
    setNextGoalValue(event.target.value);
  };

  useEffect(() => {
    if (studentAssessmentWordCountQuery.data?.correct !== undefined) {
      setNextGoalValue(studentAssessmentWordCountQuery.data?.correct);
    }
  }, [studentAssessmentWordCountQuery.data?.correct]);

  return (
    <form onSubmit={onSubmit}>
      {!hideReadingStats ? (
        <div className="bg-white border border-gray-200 rounded-lg p-4 bg-6 space-y-3 mb-8">
          <h5 className="flex items-center text-lg font-medium text-zinc-700">
            <Goal className="w-7 h-7 mr-2" />
            <label htmlFor="nextGoal">Set Next Session Goal</label>
          </h5>
          <div className="flex items-end space-x-1.5 pl-9">
            <input
              className="rounded-md border border-zinc-300 text-sm w-20"
              onChange={onChangeNextGoalValue}
              type="number"
              value={nextGoalValue}
            />
            <abbr className="uppercase text-[10px]" title="Words Correct Per Minute">
              wcpm
            </abbr>
          </div>
        </div>
      ) : null}
      <StudentAssessmentContinueLessonButton
        disabled={completeAssessmentMutation.isLoading}
        submitButtonLabel={submitButtonLabel}
      />
    </form>
  );
};

export default CompleteAssessmentForm;
