import cx from "classnames";
import React, { useMemo, useState } from "react";
import { AddStopWord } from "@Ignite-Reading/ui-kit/icons";

import {
  useStudentAssessmentQuery,
  useSetStudentAssessmentQueryOnSuccess,
} from "../StudentAssessment";
import { useUpdateWordMutation } from "./queries";
import { Tooltip, TooltipContent, TooltipTrigger } from "../common/Tooltip";
import WordListItemPopover from "./WordListItemPopover";
import { readingAssessmentStatusType } from "../../constants";

export default ({ disabled, id, label }) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const studentAssessmentQuery = useStudentAssessmentQuery();
  const updateWordMutation = useUpdateWordMutation();
  const setStudentAssessmentOnSuccess = useSetStudentAssessmentQueryOnSuccess();
  const incorrect =
    studentAssessmentQuery.data?.studentIncorrectReadingAssessmentItemIds.includes(id);
  const isEndWord = studentAssessmentQuery.data?.endItemId === id;
  const onClick = () => {
    const payload =
      studentAssessmentQuery?.data?.secondsRemaining === 0
        ? {
            correct: !incorrect,
            ended: true,
          }
        : {
            correct: incorrect,
          };
    updateWordMutation.mutate(
      { id, ...payload },
      {
        onSuccess: setStudentAssessmentOnSuccess,
      }
    );
  };
  const onMarkStopWord = () => {
    updateWordMutation.mutate(
      { correct: !incorrect, ended: true, id },
      {
        onSuccess: setStudentAssessmentOnSuccess,
      }
    );
    setIsTooltipOpen(false);
  };
  const onAddError = () => {
    updateWordMutation.mutate(
      { correct: false, id },
      {
        onSuccess: setStudentAssessmentOnSuccess,
      }
    );
    setIsTooltipOpen(false);
  };
  const onUndoError = () => {
    updateWordMutation.mutate(
      { correct: true, id },
      {
        onSuccess: setStudentAssessmentOnSuccess,
      }
    );
    setIsTooltipOpen(false);
  };
  const enableTooltip = useMemo(() => {
    return (
      studentAssessmentQuery?.data?.status === readingAssessmentStatusType.IN_PROGRESS &&
      studentAssessmentQuery?.data?.secondsRemaining === 0 &&
      studentAssessmentQuery?.data?.endItemId !== null
    );
  }, [
    studentAssessmentQuery?.data?.endItemId,
    studentAssessmentQuery?.data?.secondsRemaining,
    studentAssessmentQuery?.data?.status,
  ]);

  return (
    <Tooltip
      closeDelay={120}
      enabled={enableTooltip}
      open={isTooltipOpen}
      openDelay={320}
      onOpenChange={setIsTooltipOpen}
    >
      <TooltipTrigger>
        <button
          className={cx(
            "button-secondary ring-zinc-200 py-1.5 px-1.5 text-base mb-2 mr-1.5 relative min-w-[44px] [font-weight:inherit] group",
            {
              "bg-fuchsia-100 enabled:hover:bg-fuchsia-200 disabled:bg-fuchsia-50 ring-zinc-400 disabled:ring-zinc-300":
                incorrect,
              "bg-orange-100 enabled:hover:bg-orange-200 disabled:bg-orange-50 ring-zinc-400 disabled:ring-zinc-300":
                isEndWord,
            }
          )}
          disabled={disabled || updateWordMutation.isLoading}
          onClick={onClick}
          type="button"
        >
          {label}
          {incorrect ? (
            <span className="absolute top-0 -right-1 -translate-y-1/2 rounded-full text-white bg-rose-500 text-xs px-1 min-w-[18px] h-[18px] flex items-center justify-center group-disabled:opacity-50">
              1
            </span>
          ) : null}
          {isEndWord ? (
            <AddStopWord className="absolute top-0 -left-1 -translate-y-1/2 w-5 h-5 text-zinc-500" />
          ) : null}
        </button>
      </TooltipTrigger>
      <TooltipContent>
        <WordListItemPopover
          id={id}
          disabled={updateWordMutation.isLoading}
          onAddError={onAddError}
          onUndoError={onUndoError}
          onMarkStopWord={onMarkStopWord}
          setIsTooltipOpen={setIsTooltipOpen}
        />
      </TooltipContent>
    </Tooltip>
  );
};
