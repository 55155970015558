import React, { useEffect, useState } from "react";

import { Modal, ModalClose, ModalContent } from "../common/Modal";
import { useDrawerContext } from "../common/Drawer";
import ConfettiCannon from "../common/ConfettiCannon";
import { XMarkIcon } from "@heroicons/react/24/outline";
import GoodJob from "../../../assets/images/good-job.svg";

const StudentAssessmentCompletionModal = ({ studentAssessmentIdParamKey, id }) => {
  const { setOpen: setDrawerOpen } = useDrawerContext();
  const [modalOpen, setModalOpen] = useState(false);

  const onClick = () => {
    setModalOpen(false);
    setDrawerOpen(true);
  };

  useEffect(() => {
    const url = new URL(window.location);
    const params = new URLSearchParams(window.location.search);
    const assessmentId = parseInt(params.get(studentAssessmentIdParamKey), 10);

    if (assessmentId === id) {
      setModalOpen(true);

      url.searchParams.delete(studentAssessmentIdParamKey);
      window.history.pushState({}, "", url);
    }
  }, [id, studentAssessmentIdParamKey]);

  return (
    <Modal open={modalOpen} onOpenChange={setModalOpen}>
      <ModalContent data-testid="student-assessment-completion-modal">
        <div className="px-32 py-6 text-center">
          <ModalClose
            className="ring-0 p-1 enabled:shadow-none absolute top-2 right-2"
            data-testid="student-assessment-completion-modal-close-button"
          >
            <XMarkIcon className="w-6 h-6" />
          </ModalClose>
          <div className="flex justify-center mb-6 relative">
            <ConfettiCannon
              className="absolute -top-28"
              height={340}
              particleCount={60}
              width={400}
            />
            <GoodJob className="relative" />
          </div>
          <h2 className="text-gray-600 font-semibold mb-10">You submitted the assessment!</h2>
          <button
            className="button-primary px-20 text-sm"
            data-testid="student-assessment-completion-modal-submit-button"
            onClick={onClick}
            type="button"
          >
            See Results
          </button>
        </div>
      </ModalContent>
    </Modal>
  );
};

export default StudentAssessmentCompletionModal;
