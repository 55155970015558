import React from "react";
import cx from "classnames";
import { InformationCircleIcon } from "@heroicons/react/20/solid";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { BriefcaseClock } from "@Ignite-Reading/ui-kit/icons";

import { tutorTimeOffStatus } from "../../constants";
import { Tooltip, TooltipContent, TooltipTrigger } from "../common/Tooltip";
import { useCalendarViewMode } from "../../hooks/useCalendarViewMode";
import { useTutorTimeOffs } from "../../hooks/useTutorTimeOff";

const { APPROVED } = tutorTimeOffStatus;

export default ({ date }) => {
  const { isCalendarMode, isSubMode } = useCalendarViewMode();
  const { getTimeOffStatus } = useTutorTimeOffs();

  return getTimeOffStatus(date) ? (
    <div
      className={cx("absolute top-0 right-[2px] sm:right-[4px] flex items-center h-full", {
        "text-zinc-500/80": isCalendarMode,
        "text-white/80": isSubMode,
      })}
    >
      <Tooltip>
        <TooltipTrigger>
          <button
            className={cx("flex items-center justify-center w-6 sm:w-10 ml-1 align-middle h-8", {
              "text-zinc-500/80 hover:text-zinc-500": isCalendarMode,
              "text-white/80 hover:text-white": isSubMode,
            })}
            type="button"
          >
            {getTimeOffStatus(date) === APPROVED ? (
              <CheckCircleIcon className="w-6 h-6 block" data-testid="tto-approved-icon" />
            ) : (
              <BriefcaseClock
                className="w-6 h-6 fill-current block"
                data-testid="tto-pending-icon"
              />
            )}
            <InformationCircleIcon className="w-4 h-full hidden sm:block" />
          </button>
        </TooltipTrigger>
        <TooltipContent>Your time off request is {getTimeOffStatus(date)}.</TooltipContent>
      </Tooltip>
    </div>
  ) : null;
};
