import cx from "classnames";
import React, { useState } from "react";
import { twMerge } from "tailwind-merge";
import { ChevronDownIcon } from "@heroicons/react/24/outline";

import { Popover, PopoverContent, PopoverTrigger } from "./Popover";

const DynamicDropdown = ({
  className,
  hideSelectedOption,
  isDarkMode,
  onChange,
  options,
  placement,
  value,
}) => {
  const [open, setOpen] = useState(false);
  const { label } = options.find((option) => option.value === value);
  const onSelect = (value) => () => {
    onChange(value);
    setOpen(false);
  };

  return (
    <Popover onOpenChange={setOpen} open={open} placement={placement} toggle>
      <PopoverTrigger>
        <button
          type="button"
          className={twMerge(
            cx(
              "md:text-xs lg:text-sm leading-5 py-1.5 pr-2.5 pl-3.5 mr-3 flex items-center whitespace-nowrap button-secondary",
              {
                "bg-zinc-700 text-white ring-zinc-600 hover:enabled:bg-zinc-700/70": isDarkMode,
              },
              className
            )
          )}
        >
          {label}
          <ChevronDownIcon
            className={cx("ml-1 w-4 h-4 transition-transform text-gray-400", {
              "-rotate-180": open,
            })}
          />
        </button>
      </PopoverTrigger>
      <PopoverContent hideArrow hideCloseButton isDarkMode={isDarkMode}>
        <ul
          className="py-1 min-w-[180px] max-w-[420px] flex flex-col"
          aria-labelledby="dropdownDefaultButton"
        >
          {options.map((option) => {
            if (hideSelectedOption && option.value === value) {
              return null;
            }

            return (
              <li key={option.value}>
                <button
                  className={twMerge(
                    cx(
                      "text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer w-full truncate",
                      {
                        "text-white hover:text-white hover:bg-zinc-700/30": isDarkMode,
                        "bg-brand-600 text-white hover:bg-brand-700 hover:text-white":
                          option.value === value,
                      }
                    )
                  )}
                  onClick={onSelect(option.value)}
                >
                  {option.label}
                </button>
              </li>
            );
          })}
        </ul>
      </PopoverContent>
    </Popover>
  );
};

DynamicDropdown.defaultProps = {
  placement: "bottom-start",
};

export default DynamicDropdown;
