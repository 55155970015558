import React from "react";
import { BookOpenIcon, XCircleIcon } from "@heroicons/react/20/solid";
import { TrophyIcon } from "@heroicons/react/24/outline";

import { StudentAssessmentResults, StudentAssessmentResultsItem } from "../StudentAssessment";
import cx from "classnames";
import PropTypes from "prop-types";

export default function Results({ className, attempted = 0, correct = 0, errored = 0 }) {
  return (
    <StudentAssessmentResults className={cx("w-full", className)} title="">
      <StudentAssessmentResultsItem
        icon={<TrophyIcon />}
        isPrimary
        label="Total Correct"
        value={correct}
      />
      <StudentAssessmentResultsItem
        icon={<BookOpenIcon />}
        label="Total Attempted"
        value={attempted}
      />
      <StudentAssessmentResultsItem icon={<XCircleIcon />} label="Errors" value={errored} />
    </StudentAssessmentResults>
  );
}

Results.propTypes = {
  attempted: PropTypes.number.isRequired,
  correct: PropTypes.number.isRequired,
  errored: PropTypes.number.isRequired,
};
