import React, { useMemo } from "react";
import StudentAssessmentHeader from "../StudentAssessment/Header";
import { useStudentAssessmentQuery } from "../StudentAssessment/queries";
import { ModelReading } from "@Ignite-Reading/ui-kit/icons";

export default function ({ displayTimer, showStickyTimer, timerSecondsRemaining }) {
  const studentAssessmentQuery = useStudentAssessmentQuery();

  const { cardTitle, durationSeconds } = useMemo(
    () => studentAssessmentQuery?.data || {},
    [studentAssessmentQuery]
  );

  return (
    <StudentAssessmentHeader
      assessmentDuration={durationSeconds}
      assessmentName={cardTitle}
      displayTimer={displayTimer}
      showStickyTimer={showStickyTimer}
      icon={<ModelReading className="w-10 h-10" />}
      timerSecondsRemaining={timerSecondsRemaining}
      tooltipContent={
        <>
          <h5 className="font-medium mb-1.5">Shared Reading</h5>
          <p className="mb-1.5">
            Shared Reading is where students get to incorporate all the skills they have learned
            from the lesson into practice.
          </p>
          <p>
            We want students to become accurate and automatic with the phonics skills they are
            learning. Each day, students practice these skills at the word level. In Shared Reading,
            students are provided the opportunity to practice the phonics skills in a text that has
            been controlled for current and previously learned phonics skills.
          </p>
        </>
      }
    />
  );
}
