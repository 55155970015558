import cx from "classnames";
import { format } from "date-fns";
import React from "react";
import { CalendarIcon } from "@heroicons/react/20/solid";
import { ClockIcon } from "@heroicons/react/24/outline";

import {
  MERIDIEM_TIME_FORMAT_ZERO_PADDED,
  SHORT_DAY_OF_WEEK_MONTH_AND_DAY,
  tutorStudentStatus,
} from "../../constants";
import CancellationPill from "./CancellationPill";
import InvitedTimeBlockForm from "./InvitedTimeBlockForm";
import AssignmentBadge from "./AssignmentBadge";
import SubSessionRequestCancel from "./SubSessionRequestCancel";

const { CANCELLED, INVITED, SUB_REQUESTED } = tutorStudentStatus;

export default ({
  assignmentType,
  endTime,
  gradeLevel,
  cancellationReason,
  school,
  startTime,
  status,
  title,
  tutorStudentUuid,
}) => (
  <div
    className="min-w-[320px] w-full max-w-full md:max-w-[360px]"
    data-testid="time-block-details"
  >
    <div className="px-5 pt-3 pb-5">
      <div className="border-b pb-4">
        <div className="flex items-center mb-2">
          <h4
            className={cx("font-medium text-zinc-700 truncate", {
              "line-through": status === CANCELLED,
            })}
            data-heap-redact-text
          >
            {title}
          </h4>
          <AssignmentBadge assignmentType={assignmentType} />
        </div>
        <ul className="flex items-center text-xs text-zinc-500">
          <li className="flex items-center mr-5">
            <ClockIcon className="w-3.5 h-3.5 mr-1" />
            <span>
              {format(startTime, MERIDIEM_TIME_FORMAT_ZERO_PADDED)} -{" "}
              {format(endTime, MERIDIEM_TIME_FORMAT_ZERO_PADDED)}
            </span>
          </li>
          <li className="flex items-center">
            <CalendarIcon className="w-3.5 h-3.5 mr-1" />
            <span>{format(startTime, SHORT_DAY_OF_WEEK_MONTH_AND_DAY)}</span>
          </li>
        </ul>
      </div>
      {status === CANCELLED ? (
        <div className="flex pt-4">
          <CancellationPill reason={cancellationReason} status={status} />
        </div>
      ) : (
        <dl className="text-xs text-zinc-500 pt-4">
          <dt className="font-semibold mb-1">School</dt>
          <dd className="mb-3" data-heap-redact-text>
            {school}
          </dd>
          <dt className="font-semibold mb-1">Grade level</dt>
          <dd className="mb-1" data-heap-redact-text>
            {gradeLevel}
          </dd>
        </dl>
      )}
    </div>
    {status === INVITED ? (
      <div className="rounded-b-lg bg-zinc-50 border-t p-3 space-x-2">
        <InvitedTimeBlockForm studentName={title} tutorStudentUuid={tutorStudentUuid} />
      </div>
    ) : null}
    {status === SUB_REQUESTED ? (
      <div className="rounded-b-lg bg-zinc-50 border-t p-3 space-x-2">
        <SubSessionRequestCancel startTime={startTime} tutorStudentUuid={tutorStudentUuid} />
      </div>
    ) : null}
  </div>
);
