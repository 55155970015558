import cx from "classnames";
import noop from "lodash/noop";
import React, { useState } from "react";
import { AddStopWord } from "@Ignite-Reading/ui-kit/icons";

import { Tooltip, TooltipContent, TooltipTrigger } from "../common/Tooltip";
import { UtteranceItemPopover } from "../StudentAssessment";
import {
  useDecrementWordIncorrectMutation,
  useIncrementWordIncorrectMutation,
  useUpdateWordCorrectMutation,
  useUpdateWordEndMutation,
} from "../StudentOneMinuteChallenge/queries";

const WordListItem = ({
  disabled: disabledFromProps,
  incorrectCount,
  isEndWord,
  label,
  onGoBack,
  readingChallengeWordId,
  shouldPickEndWord,
}) => {
  const updateWordCorrectMutation = useUpdateWordCorrectMutation();
  const updateWordEndMutation = useUpdateWordEndMutation();
  const incrementWordIncorrectMutation = useIncrementWordIncorrectMutation();
  const decrementWordIncorrectMutation = useDecrementWordIncorrectMutation();
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const disabled =
    disabledFromProps ||
    updateWordCorrectMutation.isLoading ||
    updateWordEndMutation.isLoading ||
    incrementWordIncorrectMutation.isLoading ||
    decrementWordIncorrectMutation.isLoading;
  const onClick = () => {
    if (shouldPickEndWord) {
      updateWordEndMutation.mutate(
        {
          correct: incorrectCount === 0,
          ended: true,
          readingChallengeWordId,
          round: 1,
        },
        {
          onSuccess: onGoBack,
        }
      );
    } else {
      updateWordCorrectMutation.mutate({
        correct: incorrectCount > 0,
        readingChallengeWordId,
        round: 1,
      });
    }
  };
  const onMarkStopWord = () => {
    updateWordEndMutation.mutate(
      {
        correct: incorrectCount === 0,
        ended: true,
        readingChallengeWordId,
        round: 1,
      },
      {
        onSuccess: onGoBack,
      }
    );
    setIsTooltipOpen(false);
  };
  const onAddError = () => {
    incrementWordIncorrectMutation.mutate(readingChallengeWordId);
    setIsTooltipOpen(false);
  };
  const onUndoError = () => {
    decrementWordIncorrectMutation.mutate(readingChallengeWordId);
    setIsTooltipOpen(false);
  };

  return (
    <Tooltip
      closeDelay={120}
      enabled={shouldPickEndWord}
      open={isTooltipOpen}
      openDelay={320}
      onOpenChange={setIsTooltipOpen}
    >
      <TooltipTrigger>
        <button
          className={cx(
            "button-secondary text-sm text-center w-full py-4 relative group break-words hyphens-auto whitespace-nowrap",
            {
              "bg-fuchsia-100 enabled:hover:bg-fuchsia-200 disabled:bg-fuchsia-50 ring-zinc-400 disabled:ring-zinc-300":
                incorrectCount > 0,
              "bg-orange-100 enabled:hover:bg-orange-200 disabled:bg-orange-50 ring-zinc-400 disabled:ring-zinc-300":
                isEndWord,
            }
          )}
          data-testid={`word-button-${label}`}
          disabled={disabled}
          onClick={onClick}
          type="button"
        >
          {label}
          {incorrectCount > 0 ? (
            <span className="absolute top-1 right-1 rounded-full text-white bg-rose-500 text-xs px-1 min-w-[18px] h-[18px] flex items-center justify-center group-disabled:opacity-50">
              {incorrectCount}
            </span>
          ) : null}
          {isEndWord ? (
            <AddStopWord className="w-5 h-5 text-zinc-500 absolute top-1 left-1 z-10" />
          ) : null}
        </button>
      </TooltipTrigger>
      <TooltipContent>
        <UtteranceItemPopover
          disabled={disabled}
          onAddError={onAddError}
          onUndoError={onUndoError}
          onMarkStopWord={onMarkStopWord}
          setIsTooltipOpen={setIsTooltipOpen}
        />
      </TooltipContent>
    </Tooltip>
  );
};

WordListItem.defaultProps = {
  onGoBack: noop,
};

export default WordListItem;
