import cx from "classnames";
import React from "react";
import { twMerge } from "tailwind-merge";
import { ChevronDownIcon } from "@heroicons/react/24/outline";

import CircularProgressBar from "../common/CircularProgressBar";
import { Expandable, ExpandableContent, ExpandableTrigger } from "../common/Expandable";

const SkillBreakdownItem = ({ componentName, correctCount, concepts, totalCount }) => (
  <li className="rounded-xl border border-gray-200">
    <Expandable>
      <ExpandableTrigger>
        {({ expanded }) => (
          <button className="flex items-center justify-between p-4 w-full" type="button">
            <div className="flex items-center space-x-4">
              <div className="w-[46px] h-[46px] relative flex items-center justify-center">
                <CircularProgressBar
                  className={twMerge(
                    cx("text-cyan-400 absolute top-0 left-0", {
                      "text-cyan-400": correctCount / totalCount > 0.8,
                      "text-orange-400":
                        correctCount / totalCount > 0.6 && correctCount / totalCount <= 0.8,
                      "text-red-500": correctCount / totalCount <= 0.6,
                    })
                  )}
                  barWidth={7}
                  maxValue={totalCount}
                  value={correctCount}
                />
                <span className="text-[11px] text-gray-500">
                  {correctCount}/{totalCount}
                </span>
              </div>
              <h3 className="text-base text-gray-600 font-semibold">{componentName}</h3>
            </div>
            <div
              className={twMerge(
                cx(
                  "text-zinc-400 disabled:opacity-50 enabled:hover:text-zinc-500 transition-transform rotate-0",
                  {
                    "rotate-180": expanded,
                  }
                )
              )}
            >
              <ChevronDownIcon className="w-6 h-6" />
            </div>
          </button>
        )}
      </ExpandableTrigger>
      <ExpandableContent>
        <div className="pr-4 pl-[calc(46px+2rem)] pb-10">
          <ul className="space-y-2">
            {concepts.map((concept) => (
              <li className="flex items-center space-x-3" key={concept.name}>
                {concept.totalCount && concept.incorrectCount ? (
                  <span className="text-sm text-gray-600 rounded-full bg-brand-100 font-semibold px-3 py-0.5">
                    {concept.totalCount - concept.incorrectCount}/{concept.totalCount}
                  </span>
                ) : null}
                <p className="text-xs">{concept.name}</p>
              </li>
            ))}
          </ul>
        </div>
      </ExpandableContent>
    </Expandable>
  </li>
);

export default SkillBreakdownItem;
