import React from "react";
import { BookOpenIcon } from "@heroicons/react/24/outline";

import { StudentAssessmentHeader, useStudentAssessmentQuery } from "../StudentAssessment";
import { readNumberToTitleMap } from "./";

export default ({ durationSeconds, timerSecondsRemaining, displayTimer }) => {
  const studentAssessmentQuery = useStudentAssessmentQuery();
  const { readNumber } = studentAssessmentQuery.data || {};

  return (
    <StudentAssessmentHeader
      timerSecondsRemaining={timerSecondsRemaining}
      displayTimer={displayTimer}
      assessmentName={"Fluency"}
      assessmentDuration={durationSeconds}
      assessmentSubtitle={`Read #${readNumber}: ${readNumberToTitleMap[readNumber]}`}
      icon={<BookOpenIcon />}
      tooltipContent={
        <>
          <h5 className="font-medium mb-1.5">Fluency Overview</h5>
          <p>
            Research indicates that fluency is one of the critical building blocks of reading,
            because fluency development is directly related to comprehension. In order for students
            to fluently recognize and read words, they must be able to effortlessly connect sounds
            to their corresponding letter(s). Carefully designed opportunities for practice leads to
            students becoming increasingly automatic with words, leading to fluent reading.
          </p>
        </>
      }
    />
  );
};
