import React from "react";
import { ClipboardDocumentCheckIcon } from "@heroicons/react/24/outline";

import TaskListItem from "./TaskListItem";
import { useStudentAssessmentQuery } from "../StudentAssessment";
import { readNumberToTitleMap } from "./";

export default () => {
  const studentAssessmentQuery = useStudentAssessmentQuery();
  const { readingAssessmentTasks, readNumber } = studentAssessmentQuery.data || {
    readingAssessmentTasks: [],
  };

  return (
    <div className="border border-gray-200 rounded-lg divide-y divide-gray-200 mb-6">
      <div className="bg-yellow-50 rounded-t-lg flex items-center space-x-3 p-4 text-gray-700">
        <ClipboardDocumentCheckIcon className="w-8 h-8" />
        <h2 className="font-medium text-lg">
          Read #{readNumber} {readNumberToTitleMap[readNumber]} Tasks
        </h2>
      </div>
      <ul className="bg-white rounded-b-lg px-6 py-3 divide-y divide-gray-200">
        {readingAssessmentTasks?.map((task) => (
          <li className="py-3" key={task.id}>
            <TaskListItem readNumber={readNumber} {...task} />
          </li>
        ))}
      </ul>
    </div>
  );
};
