import React from "react";
import { twMerge } from "tailwind-merge";

import { InformationCircleIcon } from "@heroicons/react/24/solid";
import { useStudentQuery } from "../../Student/queries";

const PlacedOutsideBanner = ({ className }) => {
  const studentQuery = useStudentQuery();
  const { testedOutOfProgram } = studentQuery.data || {};

  return testedOutOfProgram ? (
    <div
      className={twMerge("rounded-md bg-blue-50 p-4 mt-6 flex space-x-2 mx-4 md:mx-0", className)}
      data-testid="placed-out-banner"
    >
      <InformationCircleIcon className="w-5 h-5 text-blue-400" />
      <p className="text-sm text-blue-700">
        This student placed out of the program. We have notified the support team for next steps. In
        the meantime, continue with <strong>MSD 15-18</strong> lessons for extra practice with the
        student.
      </p>
    </div>
  ) : null;
};

export default PlacedOutsideBanner;
