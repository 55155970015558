import { useSplitTreatments } from "@splitsoftware/splitio-react";
import { useMemo } from "react";

export function useFeatureToggle({ featureName, splitKey }) {
  const {
    factory,
    treatments: { [featureName]: value },
  } = useSplitTreatments({ names: [featureName], splitKey });

  const treatment = useMemo(() => {
    if (!factory) return TREATMENT.ON;
    return value.treatment;
  }, [factory, value]);

  return treatment;
}

export function useFlagOn(featureName) {
  return useFeatureToggle({ featureName: featureName }) === TREATMENT.ON;
}

export const TREATMENT = {
  ON: "on",
  OFF: "off",
};
