import React from "react";
import { twMerge } from "tailwind-merge";
import { DocumentTextIcon } from "@heroicons/react/16/solid";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { useCurrentContentQuery } from "../CurrentContent/queries";

export default function GraduationBanner({ className }) {
  const currentContentQuery = useCurrentContentQuery();

  const { studentGraduated, postMSDMaterialsURL } = currentContentQuery.data || {};

  if (!studentGraduated) return null;

  return (
    <div
      className={twMerge(
        "border border-blue-500 rounded-lg bg-blue-50 p-4 mt-6 flex space-x-2 mx-4 md:mx-0",
        className
      )}
      data-testid="graduation-banner"
    >
      <div className="grow-0">
        <div className="rounded-full bg-blue-200 text-blue-700 p-1.25">
          <InformationCircleIcon width="32px" height="32px" className="p-1 " />
        </div>
      </div>
      <div>
        <p className="font-semibold text-gray-500 pb-[15px]">Student Program Completion</p>
        <p className="font-medium text-gray-500 pb-[15px]">
          Congratulations! This student has successfully completed Ignite Programming! If there is
          enough time left in the program, we will transition the student out. Please continue to
          join your sessions utilizing Post-MSD curricular materials with your Student.
        </p>
        <p>
          <a
            data-testid="post-msd-materials-link"
            className="flex items-center text-brand-600 text-sm font-semibold"
            href={postMSDMaterialsURL}
            rel="noreferrer"
            target="_blank"
          >
            <DocumentTextIcon width="20" height="20" className="mr-1" />
            Post-MSD Materials
          </a>
        </p>
      </div>
    </div>
  );
}
