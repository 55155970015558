import React from "react";
import { ArrowUturnLeftIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { AddStopWord } from "@Ignite-Reading/ui-kit/icons";

export default ({ disabled, onAddError, onMarkStopWord, onUndoError }) => (
  <div className="flex flex-col -mx-3.5 -my-2.5 divide-y divide-zinc-300">
    <button
      className="button-secondary rounded-b-none ring-0 flex items-center group"
      disabled={disabled}
      onClick={onAddError}
      type="button"
    >
      <XCircleIcon className="w-4 h-4 text-zinc-500 group-disabled:text-zinc-400 mr-2" />
      Add error
    </button>
    <button
      className="button-secondary rounded-none ring-0 flex items-center group"
      disabled={disabled}
      onClick={onUndoError}
      type="button"
    >
      <ArrowUturnLeftIcon className="w-4 h-4 text-zinc-500 group-disabled:text-zinc-400 mr-2" />
      Undo error
    </button>
    <button
      className="button-secondary rounded-t-none ring-0 flex items-center group"
      disabled={disabled}
      onClick={onMarkStopWord}
      type="button"
    >
      <AddStopWord className="w-4 h-4 text-zinc-500 group-disabled:text-zinc-400 mr-2" />
      Stop here
    </button>
  </div>
);
