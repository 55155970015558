import cx from "classnames";
import React, { useState } from "react";
import { twMerge } from "tailwind-merge";

import { Popover, PopoverContent, PopoverTrigger } from "../common/Popover";
import { useDeleteStudentAssessment } from "./queries";
import ResetButton from "./ResetButton";
import AssessmentResetCompletedModal from "./AssessmentResetCompletedModal";
import { EllipsisVerticalIcon } from "@heroicons/react/16/solid";

const StudentAssessmentResetPopover = ({ differentiator, assessmentPaths, studentName }) => {
  const [open, setOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const deleteMutation = useDeleteStudentAssessment();

  const onClick = (formData) => {
    setOpen(false);
    assessmentPaths.forEach((path) => {
      deleteMutation.mutate(
        { path: path.assessmentResults, formData: formData },
        {
          onSuccess: () => {
            setIsConfirmationModalOpen(true);
          },
        }
      );
    });
  };
  const onCancel = () => {
    setOpen(false);
  };

  return (
    <Popover onOpenChange={setOpen} open={open} placement="top-end" toggle>
      <PopoverTrigger>
        <button
          className={twMerge(
            cx("rounded p-1 hover:bg-black/5", {
              "bg-black/5 shadow-inner": open,
            })
          )}
          data-testid={`button-student-assessment-reset-trigger-${differentiator}`}
          type="button"
        >
          <EllipsisVerticalIcon className="w-5 h-5" />
        </button>
      </PopoverTrigger>
      <PopoverContent hideArrow hideCloseButton className="z-0">
        <ResetButton
          onClick={onClick}
          onCancel={onCancel}
          differentiator={differentiator}
          studentName={studentName}
        />
      </PopoverContent>
      <AssessmentResetCompletedModal
        isConfirmationModalOpen={isConfirmationModalOpen}
        setIsConfirmationModalOpen={setIsConfirmationModalOpen}
      />
    </Popover>
  );
};

export default StudentAssessmentResetPopover;
