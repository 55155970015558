import React from "react";
import { twMerge } from "tailwind-merge";
import { InformationCircleIcon } from "@heroicons/react/24/outline";

import { Tooltip, TooltipContent, TooltipTrigger } from "./Tooltip";

const InfoTooltip = ({ children, className }) => (
  <Tooltip>
    <TooltipTrigger>
      <button
        className={twMerge("text-zinc-500 hover:text-zinc-700 w-5 sm:w-6 h-5 sm:h-6", className)}
        type="button"
      >
        <InformationCircleIcon />
      </button>
    </TooltipTrigger>
    <TooltipContent>
      <div className="max-w-[360px] text-left min-w-min text-xs md:text-sm text-zinc-600 px-2 md:px-3 py-2">
        {children}
      </div>
    </TooltipContent>
  </Tooltip>
);

export default InfoTooltip;
