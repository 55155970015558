import React, { useCallback, useContext, useMemo, useRef, useState } from "react";
import { differenceInBusinessDays, format, isSameDay, subBusinessDays } from "date-fns";
import { toast } from "react-toastify";
import pluralize from "pluralize";
import { XCircleIcon } from "@heroicons/react/24/outline";

import { SubBoardSelectionsContext } from "../../contexts/TutorCalendar";
import { useSubBoardSelector } from "../../hooks/useSubBoardSelector";
import { useCalendarViewMode } from "../../hooks/useCalendarViewMode";
import { useTutorTimeOffs } from "../../hooks/useTutorTimeOff";
import { post } from "../../api";
import { DEFAULT_DATE_FORMAT, HOUR_MINUTE_ZERO_PADDED } from "../../constants";
import { useTimeBlocksQuery } from "./queries";
import { APPROVED } from "../../constants/TutorTimeOffStatus";

const BLOCK_HOUR_LENGTH = 0.25;

export default ({ approvalThreshold }) => {
  const timeBlocksQuery = useTimeBlocksQuery();
  const { selectedTimeBlocks, selectedDates } = useContext(SubBoardSelectionsContext);
  const { setCalendarMode } = useCalendarViewMode();
  const { getTimeOffStatus } = useTutorTimeOffs();
  const { deselectAll } = useSubBoardSelector();
  const [reasonText, setReasonText] = useState("");
  const selectedSessionsCount = useMemo(() => selectedTimeBlocks.size, [selectedTimeBlocks]);
  const selectedTimeText = useMemo(() => {
    const decimalHours = selectedSessionsCount * BLOCK_HOUR_LENGTH;
    const hours = Math.trunc(decimalHours);
    const minutes = (decimalHours % 1) * 60;
    return `${hours ? pluralize("hour", hours, true) : ""} ${minutes ? `${minutes} minutes` : ""}`.trim();
  }, [selectedSessionsCount]);
  const textAreaCb = useCallback((node) => {
    if (node === null) {
      setReasonText("");
    }
  }, []);
  const reasonCb = useCallback((event) => {
    setReasonText(event.target.value ? event.target.value : "");
  }, []);
  const toastIdRef = useRef();
  const filteredSelectedTimeBlocks = () => {
    return Array.from(selectedTimeBlocks.values()).filter((timeBlock) => {
      const date = new Date(timeBlock);
      return (
        selectedDates.size === 0 ||
        !Array.from(selectedDates.values()).find((selectedDate) =>
          isSameDay(new Date(selectedDate), date)
        )
      );
    });
  };
  const createTutorTimeOff = (data) => {
    const url = "/calendar/create_tutor_time_off_sub_request";
    const headers = {
      "Content-Type": "application/json",
    };
    post(url, data, { headers })
      .then((response) => {
        if (response.data.errors.length) {
          toast.update(toastIdRef.current, {
            autoClose: 3000,
            isLoading: false,
            render: `Time off not created: ${response.data.errors.join(", ")}`,
            type: "error",
          });
        } else {
          toast.update(toastIdRef.current, {
            autoClose: 1800,
            isLoading: false,
            render: "Time off successfully submitted",
            type: "success",
          });
          timeBlocksQuery.refetch();
          deselectAll();
          setCalendarMode();
        }
      })
      .catch((error) => {
        toast.update(toastIdRef.current, {
          autoClose: 1800,
          isLoading: false,
          render: `Error: ${error}`,
          type: "error",
        });
        console.error("Error:", error);
      });
  };
  const findAdjacentShortTermSubs = useCallback(
    (date) => {
      const stsDates = [];
      // Change multiplier to check both forward and backwards
      [1, -1].forEach((multiplier) => {
        const tempDates = [];
        for (let i = 1; i <= approvalThreshold; i++) {
          const adjacentDate = new Date(subBusinessDays(date, i * multiplier));
          if (getTimeOffStatus(adjacentDate) === APPROVED) {
            tempDates.push(adjacentDate);
          } else {
            break;
          }
        }
        // Don't include dates if they are part of an approved LTS
        // This will likely be updated in the future
        if (tempDates.length < approvalThreshold) {
          stsDates.push(...tempDates);
        }
      });
      return stsDates;
    },
    [approvalThreshold, getTimeOffStatus]
  );
  const calculateRanges = useCallback((sortedDates) => {
    const ranges = [];
    let rangeStart = sortedDates[0];
    sortedDates.forEach((date, index) => {
      if (index > 0) {
        const difference = differenceInBusinessDays(date, sortedDates[index - 1]);
        // Previous date was the end of a range, calculate the result and set a new rangeStart
        if (difference !== 1 && rangeStart !== null) {
          ranges.push({
            startDate: rangeStart,
            endDate: sortedDates[index - 1],
          });
          rangeStart = date;
        } else if (difference !== 1 && rangeStart === null) {
          rangeStart = date;
        }
      }
      if (index === sortedDates.length - 1) {
        ranges.push({
          startDate: rangeStart,
          endDate: date,
        });
      }
    });
    return ranges;
  }, []);
  const timeOffRanges = useMemo(() => {
    if (selectedDates.size) {
      // Convert selectedDates to an array of Date objects
      const selectedDatesArray = Array.from(selectedDates).map((d) => new Date(d));
      // Arrays to accumulate relevant existing TTO request dates either before or after the new requests
      const adjacentStsDates = [];
      selectedDatesArray.forEach((date) => {
        findAdjacentShortTermSubs(date).forEach((newDate) => {
          if (!adjacentStsDates.some((d) => d.getTime() === newDate.getTime())) {
            adjacentStsDates.push(newDate);
          }
        });
      });

      // Combine our selectedDates with adjacentDates to get all affected dates in one sorted array
      const sortedDates = selectedDatesArray.concat(adjacentStsDates).sort((a, b) => a - b);
      return calculateRanges(sortedDates);
    }
    return [];
  }, [selectedDates, findAdjacentShortTermSubs, calculateRanges]);
  const reasonRequired = useMemo(() => {
    const rangesOverThreshold =
      timeOffRanges.find(
        (dateRange) =>
          differenceInBusinessDays(dateRange.endDate, dateRange.startDate) + 1 >= approvalThreshold
      ) !== undefined;
    if (reasonText) {
      return rangesOverThreshold && reasonText.length === 0;
    }
    return rangesOverThreshold;
  }, [timeOffRanges, reasonText, approvalThreshold]);
  const getSubSessions = () => {
    const timeBlockDates = Array.from(filteredSelectedTimeBlocks()).map((d) => new Date(d));
    const daysAndTimesMap = new Map();
    timeBlockDates.forEach((timeBlock) => {
      const formattedDay = format(timeBlock, DEFAULT_DATE_FORMAT);
      const formattedTime = format(timeBlock, HOUR_MINUTE_ZERO_PADDED);
      if (daysAndTimesMap.has(formattedDay)) {
        daysAndTimesMap.set(formattedDay, [...daysAndTimesMap.get(formattedDay), formattedTime]);
      } else {
        daysAndTimesMap.set(formattedDay, [formattedTime]);
      }
    });
    return daysAndTimesMap;
  };
  const onSubmit = () => {
    const subSessions = getSubSessions();

    toastIdRef.current = toast.loading("Submitting request...");

    if (reasonRequired && reasonText.length === 0) {
      toast.update(toastIdRef.current, {
        autoClose: 3000,
        isLoading: false,
        render: `Error: Reason is required for time off requests of ${approvalThreshold} or more days.`,
        type: "error",
      });
      return;
    } else {
      timeOffRanges.forEach((dateRange) => {
        const data = {
          start_date: format(dateRange.startDate, DEFAULT_DATE_FORMAT),
          end_date: format(dateRange.endDate, DEFAULT_DATE_FORMAT),
          reason: reasonText,
        };
        createTutorTimeOff(data);
      });
    }
    if (subSessions.size) {
      const data = {
        days_and_times: Object.fromEntries(subSessions.entries()),
      };
      createTutorTimeOff(data);
    }
  };

  return (
    <div
      className="w-full bg-zinc-900 sticky inset-x-0 bottom-0 flex flex-col align-middle z-10"
      key="sub-board-banner"
      data-testid="sub-board-banner"
    >
      <div className="flex md:hidden">
        <div className="text-white leading-4 p-4 text-lg">
          {selectedSessionsCount > 0
            ? `${selectedSessionsCount} session${selectedSessionsCount > 1 ? "s" : ""} selected`
            : "Select the session(s) or day(s) that you would like to request a sub for."}
        </div>
        {selectedSessionsCount > 0 && (
          <div
            className="flex bg-zinc-800 hover:bg-zinc-800/80 hover:cursor-pointer text-white text-sm rounded align-middle p-1 px-2 m-2"
            onClick={deselectAll}
            data-testid="clear-selection"
          >
            <XCircleIcon className="w-6 h-6 pr-2" />
            <div className="leading-6 select-none">Clear selection</div>
          </div>
        )}
      </div>
      <div className="flex flex-row align-middle">
        <div className="flex flex-col justify-center flex-1">
          <div className="hidden md:flex">
            <div className="text-white leading-4 p-4 text-lg">
              {selectedSessionsCount > 0
                ? `${selectedSessionsCount} session${selectedSessionsCount > 1 ? "s" : ""} selected`
                : "Select the session(s) or day(s) that you would like to request a sub for."}
            </div>
            {selectedSessionsCount > 0 && (
              <div
                className="flex bg-zinc-800 hover:bg-zinc-800/80 hover:cursor-pointer text-white text-sm rounded align-middle p-1 px-2 m-2"
                onClick={deselectAll}
                data-testid="clear-selection-md"
              >
                <XCircleIcon className="w-6 h-6 pr-2" />
                <div className="leading-6 select-none">Clear selection</div>
              </div>
            )}
          </div>
          {selectedDates.size > 0 && (
            <div className="flex flex-col w-full md:w-3/4 flex-1 px-4 pb-4">
              <p className="text-xs md:text-sm text-zinc-400 pb-2">
                Time off requests of 3+ days will require additional review by the Tutor Ops team.
              </p>
              <textarea
                className="bg-zinc-800 text-white placeholder-zinc-400 border-zinc-700 rounded-md text-sm md:text-base min-h-[48px] max-h-[144px]"
                placeholder="Request notes"
                ref={textAreaCb}
                onChange={reasonCb}
                data-testid="sub-request-notes"
              />
            </div>
          )}
        </div>
        <div className="flex flex-col justify-center items-center text-base md:text-lg md:p-4 pl-0">
          {selectedSessionsCount > 0 && (
            <div className="text-sm md:text-base text-white text-center p-4 pb-0">
              <p className="font-bold">Total Time Off Requested</p>
              <p>{selectedTimeText}</p>
            </div>
          )}
          <button
            className="button-secondary text-sm bg-zinc-800 ring-zinc-700 text-white disabled:bg-zinc-800/80 hover:enabled:bg-zinc-800/80 m-4 py-1.5 px-4 md:px-6"
            disabled={selectedSessionsCount === 0 || reasonRequired}
            onClick={onSubmit}
            data-testid="submit-sub-request"
          >
            Submit Requests
          </button>
        </div>
      </div>
    </div>
  );
};
