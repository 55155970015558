import React, { useMemo } from "react";

import { DrawerHeader } from "../common/Drawer";
import { Tab, TabList, TabPanel, Tabs } from "../common/Tabs";
import { useStudentHighFrequencyWordsMasteryDrawerQuery } from "./queries";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { EllipsisHorizontalCircleIcon, XCircleIcon } from "@heroicons/react/16/solid";
import { ClipboardDocumentIcon } from "@heroicons/react/24/outline";
import CopyToClipboardButton from "../common/CopyToClipboardButton";
import { copyWords, percentage } from "./utils";

const StudentHighFrequencyWordsMasteryDrawer = () => {
  const studentHfwMasterDrawerQuery = useStudentHighFrequencyWordsMasteryDrawerQuery();
  const {
    allWords = [],
    masteredWords = [],
    notYetTaughtWords = [],
    studentFullName,
    taughtWords = [],
  } = studentHfwMasterDrawerQuery.data || {};

  const masteredWordsPercentage = useMemo(
    () => percentage(masteredWords.length, allWords.length),
    [masteredWords, allWords]
  );

  const taughtWordsPercentage = useMemo(
    () => percentage(taughtWords.length, allWords.length),
    [taughtWords, allWords]
  );

  if (!studentHfwMasterDrawerQuery.data) {
    if (studentHfwMasterDrawerQuery.isError) {
      return (
        <span className="flex items-center justify-center text-red-600 min-h-[200px]">
          Error: {studentHfwMasterDrawerQuery.error.message}
        </span>
      );
    }

    return (
      <span className="flex items-center justify-center text-gray-400 min-h-[200px]">
        Loading...
      </span>
    );
  }

  return [
    <DrawerHeader closeButtonDataTestid="student-hfw-mastery-drawer-close-button" key="header">
      <div className="flex flex-col space-y-1">
        <h1 className="text-base font-medium">Current High Frequency Words Mastery</h1>
        <p className="text-zinc-500 text-xs">
          Student:{" "}
          <span className="text-zinc-700 font-medium" data-heap-redact-text>
            {studentFullName}
          </span>
        </p>
      </div>
    </DrawerHeader>,
    <div className="px-4 md:px-6 lg:px-12 pt-4 lg:pt-10" key="content">
      <div className="flex items-center justify-between">
        <div className="inline-flex flex-col space-y-1">
          <div className="bg-zinc-200 rounded-full h-1 relative w-full mb-1">
            <span
              className="h-full rounded-full bg-brand-300 absolute"
              style={{ width: `${masteredWordsPercentage + taughtWordsPercentage}%` }}
            />
            <span
              className="h-full rounded-full bg-brand-600 w-1/3 absolute"
              style={{ width: `${masteredWordsPercentage}%` }}
            />
          </div>
          <p className="text-xs text-zinc-500">
            Mastered: {masteredWords.length} / Taught: {taughtWords.length} / Not Yet:{" "}
            {notYetTaughtWords.length}
          </p>
        </div>
      </div>
      <Tabs className="pt-4 lg:pt-8">
        <TabList
          className="border-b border-zinc-200 -mb-px flex space-x-1 md:space-x-8"
          data-tabs-target="tabs"
        >
          <Tab
            data-testid="student-hfw-mastery-tab-not-yet"
            className="text-zinc-500 hover:text-gray-700 flex items-center py-2 md:py-4 px-1"
          >
            <XCircleIcon className="w-4 md:w-5 h-4 md:h-5 mr-1 text-zinc-500" />
            Not yet
            <span className="rounded-full bg-zinc-100 text-zinc-500 text-xs leading-none px-1.5 py-1 ml-1.5">
              {notYetTaughtWords.length}
            </span>
          </Tab>
          <Tab
            data-testid="student-hfw-mastery-tab-taught"
            className="text-zinc-500 hover:text-gray-700 flex items-center py-2 md:py-4 px-1"
          >
            <EllipsisHorizontalCircleIcon className="w-4 md:w-5 h-4 md:h-5 mr-1 text-brand-300" />
            Taught
            <span className="rounded-full bg-zinc-100 text-zinc-500 text-xs leading-none px-1.5 py-1 ml-1.5">
              {taughtWords.length}
            </span>
          </Tab>
          <Tab
            data-testid="student-hfw-mastery-tab-mastered"
            className="text-zinc-500 hover:text-gray-700 flex items-center py-2 md:py-4 px-1"
          >
            <CheckCircleIcon className="w-4 md:w-5 h-4 md:h-5 mr-1 text-brand-600" />
            Mastered
            <span className="rounded-full bg-zinc-100 text-zinc-500 text-xs leading-none px-1.5 py-1 ml-1.5">
              {masteredWords.length}
            </span>
          </Tab>
          <Tab
            data-testid="student-hfw-mastery-tab-all"
            className="text-zinc-500 hover:text-gray-700 flex items-center py-2 md:py-4 px-1"
          >
            All
            <span className="rounded-full bg-zinc-100 text-zinc-500 text-xs leading-none px-1.5 py-1 ml-1.5">
              {allWords.length}
            </span>
          </Tab>
        </TabList>
        <TabPanel
          className="p-5 text-sm"
          data-testid="student-hfw-mastery-drawer-not-yet-taught-words"
        >
          {notYetTaughtWords.length > 0 ? (
            <div className="flex">
              <ul className="flex-grow grid grid-cols-3 md:grid-cols-5 gap-5">
                {notYetTaughtWords.map((word, index) => (
                  <li className="font-medium" key={word.id}>
                    {index + 1}. {word.content}
                  </li>
                ))}
              </ul>
              <CopyToClipboardButton
                className="p-1 text-zinc-400 hover:text-zinc-600 flex flex-col"
                text={copyWords(notYetTaughtWords)}
                title="Copy list to paste into LessonSpace"
                testId="copy-to-clipboard-not-yet"
              >
                <ClipboardDocumentIcon
                  stroke="black"
                  className="hover:bg-zinc-100 w-10 h-10 border-2 p-1 rounded-md"
                />
              </CopyToClipboardButton>
            </div>
          ) : (
            <div className="text-zinc-400 min-h-[200px] flex items-center justify-center">
              All High Frequency Words are marked as taught or mastered
            </div>
          )}
        </TabPanel>
        <TabPanel className="p-5 text-sm" data-testid="student-hfw-mastery-drawer-taught-words">
          {taughtWords.length > 0 ? (
            <div className="flex">
              <ul className="flex-grow grid grid-cols-3 md:grid-cols-5 gap-5">
                {taughtWords.map((word, index) => (
                  <li className="font-medium" key={word.id}>
                    {index + 1}. {word.content}
                  </li>
                ))}
              </ul>
              <CopyToClipboardButton
                className="p-1 text-zinc-400 hover:text-zinc-600 flex flex-col"
                text={copyWords(taughtWords)}
                title="Copy list to paste into LessonSpace"
                testId="copy-to-clipboard-taught"
              >
                <ClipboardDocumentIcon
                  stroke="black"
                  className="hover:bg-zinc-100 w-10 h-10 border-2 p-1 rounded-md"
                />
              </CopyToClipboardButton>
            </div>
          ) : (
            <div className="text-zinc-400 min-h-[200px] flex items-center justify-center">
              There are no High Frequency Words marked as taught yet
            </div>
          )}
        </TabPanel>
        <TabPanel className="p-5 text-sm" data-testid="student-hfw-mastery-drawer-mastered-words">
          {masteredWords.length > 0 ? (
            <div className="flex">
              <ul className="flex-grow grid grid-cols-3 md:grid-cols-5 gap-5">
                {masteredWords.map((word, index) => (
                  <li className="font-medium" key={word.id}>
                    {index + 1}. {word.content}
                  </li>
                ))}
              </ul>
              <CopyToClipboardButton
                className="p-1 text-zinc-400 hover:text-zinc-600 flex flex-col"
                text={copyWords(masteredWords)}
                title="Copy list to paste into LessonSpace"
                testId="copy-to-clipboard-mastered"
              >
                <ClipboardDocumentIcon
                  stroke="black"
                  className="hover:bg-zinc-100 w-10 h-10 border-2 p-1 rounded-md"
                />
              </CopyToClipboardButton>
            </div>
          ) : (
            <div className="text-zinc-400 min-h-[200px] flex items-center justify-center">
              There are no High Frequency Words marked as mastered yet
            </div>
          )}
        </TabPanel>
        <TabPanel className="p-5 text-sm" data-testid="student-hfw-mastery-drawer-all-words">
          <div className="flex">
            <ul className="flex-grow grid grid-cols-3 md:grid-cols-5 gap-5">
              {allWords.map((word, index) => (
                <li className="flex items-center space-x-1 font-medium" key={word.id}>
                  {word.isCorrect ? (
                    <CheckCircleIcon className="w-5 h-5 mr-1 text-brand-600 opacity-60" />
                  ) : (
                    <XCircleIcon className="w-5 h-5 mr-1 text-zinc-500 opacity-60" />
                  )}
                  {index + 1}. {word.content}
                </li>
              ))}
            </ul>
            <CopyToClipboardButton
              className="p-1 text-zinc-400 hover:text-zinc-600 flex flex-col"
              text={copyWords(allWords)}
              title="Copy list to paste into LessonSpace"
              testId="copy-to-clipboard-all"
            >
              <ClipboardDocumentIcon
                stroke="black"
                className="hover:bg-zinc-100 w-10 h-10 border-2 p-1 rounded-md"
              />
            </CopyToClipboardButton>
          </div>
        </TabPanel>
      </Tabs>
    </div>,
  ];
};

export default StudentHighFrequencyWordsMasteryDrawer;
