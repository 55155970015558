import React from "react";
import { STATUS } from "./status";
import { CheckCircleIcon, ExclamationCircleIcon, XCircleIcon } from "@heroicons/react/24/outline";

export default function AssessmentStatusIcon({ status }) {
  if (status === STATUS.PASSED || status === STATUS.COMPLETED) {
    return <CheckCircleIcon className="size-6 text-green-500 fill-green-100" />;
  } else if (status === STATUS.FAILED) {
    return <XCircleIcon className="size-6 text-red-500 fill-red-100" />;
  } else if (status === STATUS.INCOMPLETE) {
    return <ExclamationCircleIcon className="size-6 text-yellow-500 fill-yellow-100" />;
  }
}
