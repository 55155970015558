import React from "react";
import cx from "classnames";
import { InformationCircleIcon } from "@heroicons/react/20/solid";

import { Tooltip, TooltipContent, TooltipTrigger } from "../common/Tooltip";
import { useCalendarViewMode } from "../../hooks/useCalendarViewMode";

export default ({ timeZoneName }) => {
  const { isCalendarMode, isSubMode } = useCalendarViewMode();

  return (
    <div className={`flex`}>
      <em className="align-middle leading-8">All times are {timeZoneName}</em>
      <Tooltip>
        <TooltipTrigger>
          <button
            className={cx("flex items-center justify-center w-4 ml-1 align-middle h-8", {
              "text-zinc-500/80 hover:text-zinc-500": isCalendarMode,
              "text-zinc-400/80 hover:text-zinc-400": isSubMode,
            })}
            type="button"
          >
            <InformationCircleIcon className="w-full h-full" />
          </button>
        </TooltipTrigger>
        <TooltipContent>
          You can change the time zone
          <br />
          from{" "}
          <a className="text-brand-500 hover:underline" href="/profile/edit">
            your profile page
          </a>
        </TooltipContent>
      </Tooltip>
    </div>
  );
};
