import { useEffect, useCallback, useState, useMemo, useRef } from "react";
import { calculateSecondsRemaining, calculateTimeBetweenFromTo } from "./utils";
import { NOW, TICK, INTERVAL } from "./enums";

const CONFIG = {
  from: NOW,
  to: null,
  onPause: false,
  onStart: false,
  onStop: false,
};

export default function (opts = {}) {
  const [isActive, setIsActive] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [tick, setTick] = useState(TICK);
  const timerRef = useRef(null);

  const { from, to, onPause, onStart, onStop } = useMemo(() => {
    return {
      from: opts.from || CONFIG.from,
      to: opts.to || CONFIG.to,
      onPause: opts.onPause || CONFIG.onPause,
      onStart: opts.onStart || CONFIG.onStart,
      onStop: opts.onStop || CONFIG.onStop,
    };
  }, [opts]);

  const stopInterval = useCallback(() => {
    clearInterval(timerRef.current);

    timerRef.current = null;
  }, []);

  const handleStop = useCallback(() => {
    stopInterval();
    setTick(TICK);
    setIsActive(false);

    if (onStop) {
      onStop();
    }
  }, [onStop, setTick, stopInterval]);

  const handlePause = useCallback(() => {
    setIsPaused(true);
    stopInterval();

    if (onPause) {
      onPause();
    }
  }, [onPause, stopInterval]);

  const startInterval = useCallback(
    (currentTick) => {
      stopInterval();
      setTick(currentTick);

      timerRef.current = setInterval(() => {
        currentTick = currentTick - INTERVAL;
        setTick(currentTick);

        if (currentTick <= 0) {
          handleStop();
        }
      }, INTERVAL);
    },
    [setTick, stopInterval, handleStop]
  );

  const handleStart = useCallback(
    (secondsRemaining) => {
      if (isPaused) {
        secondsRemaining = tick / INTERVAL;
      }

      setIsPaused(false);

      let initialTick = tick;

      if (tick === 0) {
        setIsActive(true);

        if (secondsRemaining) {
          initialTick = calculateSecondsRemaining(secondsRemaining);
        } else {
          initialTick = calculateTimeBetweenFromTo(from, to);
        }
      }

      if (initialTick > 0) {
        startInterval(initialTick);

        if (onStart) {
          onStart();
        }
      } else {
        handleStop();
      }
    },
    [from, to, onStart, tick, isPaused, handleStop, startInterval]
  );

  // stop the interval on unmount or it will live
  // as a zombie in the browser
  useEffect(() => {
    return () => stopInterval();
  }, [stopInterval]);

  return {
    handleStart,
    handleStop,
    handlePause,
    isPaused,
    isActive,
    tick, // tick is in MS
  };
}
