import React, { useState, useMemo, useEffect } from "react";
import Overlay from "./Overlay";
import FeatureToggle from "../common/FeatureToggle";

export default function LessonCardWrapper({
  children,
  error = false,
  isCurrentLesson = false,
  isLoading = false,
  onResetError,
  onResumeLesson,
  onStartLesson,
  sessionStartsAt,
  showResume = false,
  showStart = false,
  success = false,
}) {
  const [isQueryComplete, setIsQueryComplete] = useState(!(showStart || showResume));

  const showOverlay = useMemo(() => {
    if (isCurrentLesson) {
      return !isQueryComplete || showStart || showResume;
    } else {
      return false;
    }
  }, [isCurrentLesson, isQueryComplete, showStart, showResume]);

  const handleSubmit = (type) => {
    onResetError();

    if (type === "start") {
      onStartLesson();
    }

    if (type === "resume") {
      onResumeLesson();
    }
  };

  useEffect(() => {
    if (error?.message) {
      setIsQueryComplete(false);
    }
  }, [error]);

  useEffect(() => {
    if (success) {
      setIsQueryComplete(true);
    }
  }, [success]);

  return (
    <FeatureToggle featureName="start_lesson_button">
      <FeatureToggle.On>
        <div className="relative">
          {showOverlay && (
            <Overlay
              error={error}
              isLoading={isLoading}
              isQueryComplete={isQueryComplete}
              onResetError={onResetError}
              onSubmit={handleSubmit}
              sessionStartsAt={sessionStartsAt}
              showStart={showStart}
              success={success}
            />
          )}
          <div className="mt-4 space-y-4">{children}</div>
        </div>
      </FeatureToggle.On>
      <FeatureToggle.Off>
        <div className="mt-4 space-y-4">{children}</div>
      </FeatureToggle.Off>
    </FeatureToggle>
  );
}
