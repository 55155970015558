import startCase from "lodash/startCase";
import React, { useMemo } from "react";
import { InformationCircleIcon, RocketLaunchIcon, TrophyIcon } from "@heroicons/react/24/outline";
import { BookOpenIcon, XCircleIcon } from "@heroicons/react/20/solid";

import { useStudentAssessmentWordCountQuery } from "./queries";
import { Tooltip, TooltipContent, TooltipTrigger } from "../common/Tooltip";
import { useStudentAssessmentQuery } from "../StudentAssessment";

export default ({ hideReadingStats }) => {
  const { data } = useStudentAssessmentWordCountQuery();
  const studentAssessmentQuery = useStudentAssessmentQuery();
  const goals = studentAssessmentQuery?.data?.goals;
  const recordWpm = studentAssessmentQuery?.data?.assessmentRecord?.wordsPerMinute;
  const highestWpm = useMemo(
    () => (data?.correct > recordWpm ? data?.correct : recordWpm),
    [data?.correct, recordWpm]
  );
  const goalWidth = useMemo(() => {
    let goalPercent = (highestWpm / goals?.studentEoyGoal) * 100;

    if (!data || !goals?.studentEoyGoal) {
      goalPercent = 0;
    } else if (goalPercent > 100) {
      goalPercent = 100;
    }

    return `${goalPercent}%`;
  }, [data, highestWpm, goals?.studentEoyGoal]);

  return (
    <div className="space-y-2 mb-8">
      <h5 className="uppercase font-medium text-zinc-500">Challenge results</h5>
      <div className="space-y-12">
        <ul className="space-y-2.5 text-sm">
          <li className="flex items-center text-lg font-medium border-b border-zinc-300 text-zinc-700 py-4 px-2 space-x-2">
            <TrophyIcon className="w-7 h-7" />
            <span>Total Correct: {data?.correct ?? "--"}</span>
          </li>
          <li className="flex items-center text-zinc-500 px-2 space-x-2">
            <BookOpenIcon className="w-5 h-5" />
            <span>Total Attempted: {data?.attempted ?? "--"}</span>
          </li>
          <li className="flex items-center text-zinc-500 px-2 space-x-2">
            <XCircleIcon className="w-5 h-5" />
            <span>Errors: {data?.errored ?? "--"}</span>
          </li>
        </ul>

        {!hideReadingStats ? (
          <div className="items-center space-y-2.5">
            <div className="flex items-center text-lg font-medium text-zinc-700 pt-4 px-2 space-x-2">
              <RocketLaunchIcon className="w-7 h-7 shrink-0" />
              <span className="whitespace-nowrap">
                End of Year Goal: {goals?.studentEoyGoal}{" "}
                <abbr className="uppercase text-[10px]" title="Words Correct Per Minute">
                  wcpm
                </abbr>
              </span>
              <Tooltip>
                <TooltipTrigger>
                  <button className="text-zinc-400 hover:text-zinc-600" type="button">
                    <InformationCircleIcon className="w-5 h-5" />
                  </button>
                </TooltipTrigger>
                <TooltipContent>
                  <div className="max-w-[360px] text-left min-w-min text-xs md:text-sm text-zinc-600 px-2 md:px-3 py-2">
                    <h5 className="font-medium mb-1.5">DEAR End of Year Goals</h5>
                    <p className="mb-4">
                      The expected goal rate should be the student’s actual grade level, NOT their
                      protocol-aligned grade level. In other words, if your 4th grader is on B+D,
                      their expected rate is 125 wcpm.
                    </p>
                    <ul>
                      {Object.entries(goals ? goals?.dearGoalsMap : {}).map(([key, value]) => (
                        <li key={key}>
                          {startCase(key)}: {value}
                        </li>
                      ))}
                    </ul>
                  </div>
                </TooltipContent>
              </Tooltip>
            </div>

            <div className="text-zinc-500 px-2">
              <div className="items-center flex">
                <div className="items-center relative max-w-[360px] grow">
                  <span className="items-center min-w-full h-2 bg-gray-100 rounded-full absolute top-1/2 mt-[-.25rem]"></span>
                  <span
                    className="items-center h-2 bg-brand-700 rounded-full absolute top-1/2 mt-[-.25rem]"
                    style={{ width: goalWidth }}
                  ></span>
                </div>
                <div className="items-center flex p-1 px-2 text-xs">
                  EOY Goal: {goals?.studentEoyGoal ?? "--"}
                </div>
              </div>
              <div className="py-2 text-xs">
                Highest Record: {highestWpm ?? "-"}{" "}
                <abbr className="uppercase" title="Words Correct Per Minute">
                  wcpm
                </abbr>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};
