import cx from "classnames";
import React, { useEffect, useState } from "react";

import StatusBadge from "./StatusBadge";
import ContentExpanded from "./ContentExpanded";
import { ChevronDownIcon, LockClosedIcon } from "@heroicons/react/24/outline";

const ContentRow = ({ content }) => {
  const [open, setOpen] = useState(content.isOpen);
  const onClick = () => {
    if (!content.cannotViewProtocol || content.isBaseline) {
      setOpen(!open);
    }
  };

  useEffect(() => {
    setOpen(content.isOpen);
  }, [content.isOpen]);

  return [
    <tr
      className={cx("cursor-pointer text-zinc-400", {
        "sticky-below-header z-20": open,
        "bg-brand-100 hover:bg-brand-100":
          content.protocolType === "Screener" && content.status !== "completed",
        "hover:bg-gray-100 bg-gray-50":
          content.protocolType !== "Screener" || content.status === "completed",
      })}
      key="header"
      onClick={onClick}
    >
      <td className="py-4 px-6 text-zinc-900 font-medium text-sm md:text-base whitespace-nowrap hidden sm:table-cell">
        {content.displayName}
      </td>
      <td className="py-4 px-6 text-zinc-600 text-xs md:text-sm hidden sm:table-cell">
        {content.contentDescription}
      </td>
      <td className="py-4 px-6 hidden sm:table-cell">
        {!content.cannotViewProtocol && (
          <StatusBadge status={content.status} isBaseline={content.isBaseline} />
        )}
      </td>
      <td className="py-4 pr-4 sm:pr-6 h-full w-full sm:w-12 sm:table-cell">
        <div className="flex items-center justify-between">
          <div className="sm:hidden px-4">
            <div className="text-zinc-900 font-medium text-sm flex items-center space-x-1.5">
              <div className="truncate">{content.displayName}</div>
              <StatusBadge status={content.status} isBaseline={content.isBaseline} />
            </div>
            <div className="text-zinc-600 text-xs">{content.contentDescription}</div>
          </div>
          {content.cannotViewProtocol && !content.isBaseline ? (
            <div>
              <LockClosedIcon
                className="w-6 h-6"
                data-testid="locked-closed-icon"
                strokeWidth={2}
              />
            </div>
          ) : (
            <div
              className={cx(
                "text-zinc-400 disabled:opacity-50 enabled:hover:text-zinc-500 transition-transform flex items-center justify-between",
                {
                  "rotate-180": open,
                }
              )}
            >
              <ChevronDownIcon
                className="rotate-0 h-6 w-6 transition-transform"
                data-testid="chevron-down-icon"
                title="foo"
                titleId="foo"
                desc="bar"
                descId="bar"
              />
            </div>
          )}
        </div>
      </td>
    </tr>,
    open && (
      <tr className="grid grid-cols-1 md:table-row" key="content">
        <ContentExpanded assessmentable={content} />
      </tr>
    ),
  ];
};

export default ContentRow;
