import cx from "classnames";
import React from "react";
import { twMerge } from "tailwind-merge";
import { ChevronUpIcon } from "@heroicons/react/20/solid";

import { useTable } from "./";

const TableHeadCell = ({ className, colSpan, id, isSortable, label }) => {
  const { setSortBy, setSortDirection, sortBy, sortDirection } = useTable();
  const onSort = () => {
    setSortBy(id);
    setSortDirection((prevState) => {
      if (sortBy === id) {
        return prevState === "asc" ? "desc" : "asc";
      }

      return "asc";
    });
  };

  return (
    <th
      className={twMerge(
        "bg-gray-50 py-3.5 px-4 text-left text-sm font-semibold text-gray-700 whitespace-nowrap sm:pl-6 first:rounded-tl-lg last:rounded-tr-lg",
        className
      )}
      colSpan={colSpan}
      key={id}
      scope="col"
    >
      {isSortable ? (
        <button className="flex items-center space-x-1" onClick={onSort} type="button">
          <span>{label}</span>
          <ChevronUpIcon
            className={twMerge(
              cx("text-gray-400 w-5 h-5 transition-transform invisible", {
                "rotate-180": sortDirection === "desc",
                visible: sortBy === id,
              })
            )}
          />
        </button>
      ) : (
        label
      )}
    </th>
  );
};

TableHeadCell.defaultProps = {
  isSortable: false,
};

export default TableHeadCell;
