import cx from "classnames";
import { format } from "date-fns";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { twMerge } from "tailwind-merge";
import { InformationCircleIcon } from "@heroicons/react/16/solid";
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";

import { RIPPLING_URL, SHORT_MONTH_AND_DAY } from "../../constants";
import { Modal, ModalClose, ModalContent, ModalTrigger } from "../common/Modal";
import { Tooltip, TooltipContent, TooltipTrigger } from "../common/Tooltip";
import TimeOffItemModal from "./TimeOffItemModal";
import { APPROVED, CANCELLED, DENIED, PENDING } from "../../constants/TutorTimeOffStatus";
import { useCancelTimeOffMutation } from "./queries";

const statusToLabelMap = {
  [APPROVED]: "Approved",
  [DENIED]: "Denied",
  [PENDING]: "Pending",
  [CANCELLED]: "Cancelled",
};
const TimeOffItem = ({
  cancelUri,
  createdAt,
  currentDayCountForTimeOff,
  currentYearLabel,
  endDate,
  firstName,
  lastName,
  maxAllowedDayCountForTimeOff,
  reason,
  startDate,
  status,
  updateUri,
  uuid,
}) => {
  const mutation = useCancelTimeOffMutation({ uri: cancelUri });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const onCancel = (event) => {
    mutation.mutate(null, {
      onSuccess: () => {
        toast("Time off request cancelled.", {
          autoClose: 3200,
          isLoading: false,
        });
      },
      onError: () => {
        toast("There was a problem denying time off. Please try again.", {
          autoClose: 3200,
          isLoading: false,
          type: "error",
        });
      },
    });
    event.preventDefault();
  };

  return (
    <tr className="group">
      <td
        className={twMerge(
          cx(
            "bg-white whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6 group-last:rounded-b-lg",
            {
              "bg-gray-50": status === CANCELLED,
            }
          )
        )}
      >
        <Modal open={isModalOpen} onOpenChange={setIsModalOpen}>
          <Tooltip hide={isModalOpen}>
            <TooltipTrigger>
              <ModalTrigger>
                <button
                  className={twMerge(
                    cx("button-as-link flex items-center space-x-1.5", {
                      "opacity-80": status === CANCELLED,
                    })
                  )}
                  data-testid={`time-off-request-${uuid}-reason-tooltip`}
                >
                  <span>
                    {format(startDate, SHORT_MONTH_AND_DAY)} -{" "}
                    {format(endDate, SHORT_MONTH_AND_DAY)}
                  </span>
                  <InformationCircleIcon className="w-4 h-4" />
                </button>
              </ModalTrigger>
            </TooltipTrigger>
            {reason ? (
              <TooltipContent className="max-w-[280px] text-left">
                <div className="space-y-1.5">
                  <h6 className="font-semibold">
                    {firstName} {lastName} Time Off Notes
                  </h6>
                  <p>{reason}</p>
                </div>
              </TooltipContent>
            ) : null}
          </Tooltip>
          <ModalContent className="w-full md:max-w-[700px] flex items-center justify-center">
            <TimeOffItemModal
              createdAt={createdAt}
              currentDayCountForTimeOff={currentDayCountForTimeOff}
              currentYearLabel={currentYearLabel}
              endDate={endDate}
              firstName={firstName}
              lastName={lastName}
              maxAllowedDayCountForTimeOff={maxAllowedDayCountForTimeOff}
              reason={reason}
              startDate={startDate}
              status={status}
              uri={updateUri}
              uuid={uuid}
            />
          </ModalContent>
        </Modal>
      </td>
      <td
        className={twMerge(
          cx(
            "bg-white whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6 text-center",
            {
              "bg-gray-50 text-gray-500": status === CANCELLED,
            }
          )
        )}
      >
        <span
          className={twMerge(
            cx(
              "text-gray-500/80 font-medium text-xs bg-white border border-gray-200 rounded-full py-1 px-2",
              {
                "bg-gray-200/70 border-gray-300": status === CANCELLED,
                "bg-green-100 border-green-200 text-green-600": status === APPROVED,
                "bg-red-100 border-red-200 text-red-600": status === DENIED,
              }
            )
          )}
        >
          {statusToLabelMap[status]}
        </span>
      </td>
      <td
        className={twMerge(
          cx("py-0 pl-3 pr-4 space-x-4 text-right min-w-[100px]", {
            "bg-gray-50 text-gray-500": status === CANCELLED,
          })
        )}
      >
        {status === PENDING ? (
          <Modal>
            <ModalTrigger>
              <button
                className="button-secondary"
                data-testid={`time-off-request-${uuid}-cancel-button`}
                type="button"
              >
                Cancel
              </button>
            </ModalTrigger>
            <ModalContent data-testid={`time-off-request-${uuid}-cancel-confirmation-modal`}>
              <form className="divide-y divide-gray-200" onSubmit={onCancel}>
                <div className="pb-5 flex items-center">
                  <ExclamationTriangleIcon className="w-7 h-7 text-yellow-500 mr-4" />
                  <h3 className="font-semibold text-lg text-zinc-700">Cancel time off request</h3>
                </div>
                <div className="text-sm text-zinc-700 py-5">
                  <p>
                    Are you sure you want to cancel the time off request for{" "}
                    <strong>
                      {format(startDate, SHORT_MONTH_AND_DAY)} -{" "}
                      {format(endDate, SHORT_MONTH_AND_DAY)}
                    </strong>
                    ?
                  </p>
                </div>
                <div className="flex justify-end pt-5 space-x-3">
                  <ModalClose>Go back</ModalClose>
                  <button
                    className="button-primary"
                    data-testid={`time-off-request-${uuid}-cancel-confirmation-modal-confirmation-button`}
                    type="submit"
                  >
                    Cancel time off request
                  </button>
                </div>
              </form>
            </ModalContent>
          </Modal>
        ) : status === APPROVED ? (
          <a
            className="button-primary inline-block whitespace-nowrap"
            data-testid={`time-off-request-${uuid}-rippling-button`}
            href={RIPPLING_URL}
            target="_blank"
            rel="noreferrer"
          >
            Update In Rippling
          </a>
        ) : null}
      </td>
    </tr>
  );
};

export default TimeOffItem;
