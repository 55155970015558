import cx from "classnames";
import React, { useContext, useMemo } from "react";
import { Link } from "react-router-dom";
import { Cog6ToothIcon } from "@heroicons/react/24/outline";

import {
  CALENDAR_DAILY_VIEW,
  CALENDAR_NORMAL_MODE,
  CALENDAR_SUB_MODE,
  CALENDAR_WEEKLY_VIEW,
} from "../../constants";
import { CurrentViewContext } from "../../contexts/Calendar";
import SessionSuggestions from "./SessionSuggestions";
import { ViewSwitcher } from "../common/Calendar";
import { useCalendarViewMode } from "../../hooks/useCalendarViewMode";
import DynamicDropdown from "../common/DynamicDropdown";

const SHOW_SUB_OPPORTUNITIES_VALUE = "show-sub-opportunities";

export default ({ subBoardEnabled }) => {
  const { showSubSessionRequests, setCurrentView, setShowSubSessionRequests } =
    useContext(CurrentViewContext);
  const { isCalendarMode, isSubMode, setCalendarMode, setSubMode } = useCalendarViewMode();
  const onChangeViewMode = (value) => {
    if (value === CALENDAR_NORMAL_MODE) {
      setCalendarMode();
      setShowSubSessionRequests(false);
    }
    if (value === CALENDAR_SUB_MODE) {
      setSubMode();
      setCurrentView(CALENDAR_WEEKLY_VIEW);
      setShowSubSessionRequests(false);
    }
    if (value === SHOW_SUB_OPPORTUNITIES_VALUE) {
      setCalendarMode();
      setShowSubSessionRequests(true);
    }
  };
  const viewModeValue = useMemo(() => {
    if (isSubMode) {
      return CALENDAR_SUB_MODE;
    } else {
      if (showSubSessionRequests) {
        return SHOW_SUB_OPPORTUNITIES_VALUE;
      }

      return CALENDAR_NORMAL_MODE;
    }
  }, [isSubMode, showSubSessionRequests]);

  return (
    <div className="flex items-center h-[32px] w-full justify-between">
      {subBoardEnabled ? (
        <DynamicDropdown
          hideSelectedOption
          isDarkMode={isSubMode}
          onChange={onChangeViewMode}
          options={[
            {
              label: "Calendar View",
              value: CALENDAR_NORMAL_MODE,
            },
            {
              label: "Time Off / Sub Requests",
              value: CALENDAR_SUB_MODE,
            },
            {
              label: "Open Sub Opportunities",
              value: SHOW_SUB_OPPORTUNITIES_VALUE,
            },
          ]}
          placement="bottom-end"
          value={viewModeValue}
        />
      ) : null}
      <div
        className={cx("flex items-center h-[32px] w-auto justify-between", {
          "w-full sm:w-auto": !subBoardEnabled,
        })}
      >
        <ViewSwitcher
          views={[CALENDAR_WEEKLY_VIEW, CALENDAR_DAILY_VIEW]}
          onChange={() => {
            if (isSubMode) {
              setCalendarMode();
            }
          }}
        />
        <div className="flex items-center h-[32px] justify-between">
          <SessionSuggestions />
          <Link
            className={cx(
              "flex items-center rounded-md bg-white px-2 text-sm font-medium  ml-1 lg:ml-2 h-full",
              {
                "bg-white text-gray-700 hover:bg-gray-100": isCalendarMode,
                "bg-zinc-800 text-white hover:bg-zinc-700/30": isSubMode,
              }
            )}
            to="/tutor_availabilities"
            title="Schedule preferences"
          >
            <Cog6ToothIcon
              className="w-5 lg:w-6 h-5 lg:h-6"
              data-testid="tutor-availabilities-icon"
            />
          </Link>
        </div>
      </div>
    </div>
  );
};
