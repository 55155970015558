import React, { useMemo, useState } from "react";
import { AddStopWord } from "@Ignite-Reading/ui-kit/icons";

import Popover from "./Popover";
import { Tooltip, TooltipContent, TooltipTrigger } from "../../common/Tooltip";
import { SUBMIT_KEY } from "./utils";
import cx from "classnames";
import PropTypes from "prop-types";

export default function Item({
  "data-testid": dataTestId,
  disabled = false,
  id,
  incorrectCount = 0,
  isCallout = false,
  isEndWord = false,
  isAfterEndWord = false,
  isLoading = false,
  isTooltipEnabled = false,
  onUpdateWord,
  studentReadingAssessmentId,
  submitKey = SUBMIT_KEY.CORRECT,
  text,
}) {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const correct = useMemo(() => incorrectCount === 0, [incorrectCount]);

  const onWordEvent = (options) => {
    if (!options.ended && isAfterEndWord) {
      return;
    }

    onUpdateWord(
      { ...options, id, studentReadingAssessmentId },
      {
        onSuccess: () => setIsTooltipOpen(false),
      }
    );
  };

  return (
    <Tooltip
      closeDelay={120}
      enabled={isTooltipEnabled}
      open={isTooltipOpen}
      openDelay={320}
      onOpenChange={setIsTooltipOpen}
    >
      <TooltipTrigger>
        <button
          data-testid={`${dataTestId}-word-${id}`}
          className={cx(
            "button-secondary py-1.5 px-1.5 text-base mb-2 mr-1.5 relative min-w-[44px] [font-weight:inherit] group",
            {
              underline: isCallout,
              "ring-zinc-200 disabled:ring-zinc-300": !isEndWord,
              "bg-orange-100 enabled:hover:bg-orange-200 disabled:bg-orange-50 ring-around-500":
                isEndWord,
              "bg-fuchsia-100 enabled:hover:bg-fuchsia-200 disabled:bg-fuchsia-50 ring-zinc-400 disabled:ring-zinc-300":
                !correct,
            }
          )}
          disabled={disabled || isLoading}
          onClick={() => {
            let wordEvent;

            if (submitKey === SUBMIT_KEY.END) {
              wordEvent = { correct, ended: true };
            } else {
              wordEvent = { correct: !correct };
            }

            onWordEvent(wordEvent);
          }}
          type="button"
        >
          {text}
          {!correct && (
            <span className="absolute top-0 -right-1 -translate-y-1/2 rounded-full text-white bg-rose-500 text-xs px-1 min-w-[18px] h-[18px] flex items-center justify-center group-disabled:opacity-50">
              {incorrectCount}
            </span>
          )}
          {isEndWord && (
            <AddStopWord className="absolute top-0 -left-1 -translate-y-1/2 w-5 h-5 text-zinc-500" />
          )}
        </button>
      </TooltipTrigger>
      <TooltipContent>
        <Popover
          data-testid={dataTestId}
          disabled={disabled || isLoading}
          onAddError={() => onWordEvent({ correct: false })}
          onUndoError={() => onWordEvent({ correct: true })}
          onMarkStopWord={() => onWordEvent({ correct, ended: true })}
          setIsTooltipOpen={setIsTooltipOpen}
        />
      </TooltipContent>
    </Tooltip>
  );
}

Item.propTypes = {
  "data-testid": PropTypes.string,
  disabled: PropTypes.bool.isRequired,
  id: PropTypes.number.isRequired,
  incorrectCount: PropTypes.number.isRequired,
  isCallout: PropTypes.bool.isRequired,
  isEndWord: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isTooltipEnabled: PropTypes.bool.isRequired,
  onUpdateWord: PropTypes.func.isRequired,
  studentReadingAssessmentId: PropTypes.number.isRequired,
  submitKey: PropTypes.oneOf([SUBMIT_KEY.CORRECT, SUBMIT_KEY.END]),
  text: PropTypes.string.isRequired,
};
