import React from "react";

import {
  StudentAssessment,
  StudentAssessmentContentCollapsed,
  StudentAssessmentContentExpanded,
  StudentAssessmentHeader,
} from "../StudentAssessment";
import CompleteAssessmentButton from "./CompleteAssessmentButton";
import { HighFrequencyWordsSolid } from "@Ignite-Reading/ui-kit/icons";
import CardContents from "../StudentPlaceholderLessonCard/CardContents";
import { useStudentHighFrequencyWordsMasteryDrawerQuery } from "../StudentHighFrequencyWordsMasteryDrawer/queries";

export default function HFWReviewCard(props) {
  const studentHfwQuery = useStudentHighFrequencyWordsMasteryDrawerQuery();
  const { taughtWords = [] } = studentHfwQuery.data || {};

  return (
    <StudentAssessment
      {...props}
      data-testid="student-hfw-review-card"
      queryKey={["student-hfw-review-card", props.assessmentId]}
    >
      <StudentAssessmentHeader
        timerSecondsRemaining={props.timerSecondsRemaining}
        displayTimer={false}
        showStickyTimer={false}
        assessmentName="Got Extra Time? Review HFWs!"
        icon={<HighFrequencyWordsSolid className="size-10" />}
        tooltipContent={
          <div className="space-y-4">
            <h5 className="font-bold mb-1.5">Why Review HFWs?</h5>
            <p>
              <em className="font-bold">
                You&apos;ve already taught the specific letter-sound connections
              </em>{" "}
              but HFWs require practice beyond initial instruction. When students actively engage
              with these words through reading, writing, and understanding their usage in context,
              they develop the neural pathways necessary for instant word recognition through
              orthographic mapping!
            </p>
            <p>
              <strong>Did you know?</strong> HFWs make up around 60% of the Oral Reading Fluency
              benchmark assessments? Our data confirms: students who master HFWs consistently meet
              ORF benchmarks!
            </p>
          </div>
        }
      />
      <StudentAssessmentContentExpanded>
        <div className="flex flex-col xl:flex-row justify-between space-y-6 xl:space-y-0">
          <div className="shrink grow basis-auto flex flex-col flex-1 xl:pr-8 max-w-[80rem] relative">
            <div className="flex flex-col">
              <div>
                <CardContents type={props.type} taughtWords={taughtWords} />
              </div>
            </div>
          </div>
          <div className="grow-0 shrink xl:basis-[350px] xl:ml-6 border-zinc-200 sticky">
            <div className="xl:mx-5 mt-6 xl:mt-0 xl:mb-8 flex-col">
              <CompleteAssessmentButton />
            </div>
          </div>
        </div>
      </StudentAssessmentContentExpanded>
      <StudentAssessmentContentCollapsed />
    </StudentAssessment>
  );
}
