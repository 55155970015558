import cx from "classnames";
import React, { useEffect, useRef, useState } from "react";

import { Tooltip, TooltipContent, TooltipTrigger } from "./Tooltip";
import { CheckCircleIcon } from "@heroicons/react/20/solid";

const RESET_COPIED_STATE_TIMEOUT = 1400;

export default ({
  children,
  className,
  text,
  title = "Copy to clipboard",
  testId = "copy-to-clipboard-button",
}) => {
  const timerRef = useRef(null);
  const [isCopied, setIsCopied] = useState(false);
  const onClick = async () => {
    try {
      await navigator.clipboard.writeText(text);

      setIsCopied(true);
      timerRef.current = setTimeout(() => {
        setIsCopied(false);
      }, RESET_COPIED_STATE_TIMEOUT);
    } catch (e) {
      alert(
        "Permission to use the clipboard is turned off. Please turn it back on in your browser's settings."
      );
    }
  };

  useEffect(() => {
    return () => {
      clearTimeout(timerRef.current);
    };
  }, []);

  return (
    <Tooltip>
      <TooltipTrigger>
        <button className={cx(className)} onClick={onClick} type="button" data-testid={testId}>
          {children}
        </button>
      </TooltipTrigger>
      <TooltipContent className="py-1.5 px-2" dark>
        <div className="text-xs h-3.5 leading-none flex items-center">
          {isCopied ? (
            <span className="flex items-center gap-1">
              <CheckCircleIcon className="text-[#80AD1F] w-3.5 h-3.5" />
              Copied
            </span>
          ) : (
            <span>{title}</span>
          )}
        </div>
      </TooltipContent>
    </Tooltip>
  );
};
