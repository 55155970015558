import React, { useMemo } from "react";
import StudentFluencyAssessment from "../StudentFluencyAssessment";
import StudentDearAssessment from "../StudentDearAssessment";
import StudentPhonologicalAwarenessDrill from "../StudentPhonologicalAwarenessDrill";
import StudentOneMinuteChallenge from "../StudentOneMinuteChallenge";
import StudentHighFrequencyWordsDrill from "../StudentHighFrequencyWordsDrill";
import StudentSharedReadingAssessment from "../StudentSharedReadingAssessment";
import StudentPlaceholderLessonCard from "../StudentPlaceholderLessonCard";
import StudentGreetingCard from "../StudentGreetingCard";
import HFWReviewCard from "../HFWReviewCard";
import { useFlagOn } from "../../hooks/useFeatureToggle";

const LessonCardRenderer = ({
  gradeLevels,
  id,
  isConditional,
  open,
  paDrillType,
  sequenceNumber,
  studentId,
  targetGoal,
  type,
  durationSeconds,
  timerSecondsRemaining,
  showStart,
  showResume,
}) => {
  const cardDurationTimers = useFlagOn("card_duration_timers");
  const durationTimersOnTimedCards = useFlagOn("duration_timers_on_timed_cards");
  const startLessonButton = useFlagOn("start_lesson_button");

  const showStickyTimer = useMemo(() => {
    if (startLessonButton) {
      return !(showStart || showResume);
    } else {
      return true;
    }
  }, [showStart, showResume, startLessonButton]);

  if (type === "StudentDearAssessment") {
    return (
      <StudentDearAssessment
        assessmentId={id}
        open={open}
        paths={{
          completeAssessment: `/students/${studentId}/student_dear_assessment/${id}/complete_assessment`,
          index: `/students/${studentId}/student_dear_assessment/${id}`,
          resetAssessment: `/students/${studentId}/student_dear_assessment/${id}/reset_assessment`,
          startAssessment: `/students/${studentId}/student_dear_assessment/${id}/start_assessment`,
          updateGoal: `/students/${studentId}/student_dear_assessment/${id}/update_goal`,
          updateSecondsRemaining: `/students/${studentId}/student_dear_assessment/${id}/update_seconds_remaining`,
          updateWord: `/students/${studentId}/student_dear_assessment/${id}/update_word`,
          updateTimerSecondsRemaining: `/students/${studentId}/student_dear_assessment/${id}/update_timer_seconds_remaining`,
        }}
        type={type}
        durationSeconds={durationSeconds}
        timerSecondsRemaining={timerSecondsRemaining}
        displayTimer={cardDurationTimers && durationTimersOnTimedCards}
        showStickyTimer={showStickyTimer}
      />
    );
  }

  if (
    [
      "StudentLetterNameDrill",
      "StudentLetterSoundDrill",
      "StudentOneMinuteChallenge",
      "StudentNonsenseOneMinuteChallenge",
      "StudentConditionalLetterNameDrill",
    ].includes(type)
  ) {
    return (
      <StudentOneMinuteChallenge
        assessmentId={id}
        isConditional={isConditional}
        open={open}
        paths={{
          completeAssessment: `/students/${studentId}/student_reading_challenge/${id}/complete`,
          index: `/students/${studentId}/student_reading_challenge/${id}`,
          resetAssessment: `/students/${studentId}/student_reading_challenge/${id}/reset`,
          startAssessment: `/students/${studentId}/student_reading_challenge/${id}/start`,
          updateGoal: `/students/${studentId}/student_reading_challenge/${id}/update_goal`,
          updateSecondsRemaining: `/students/${studentId}/student_reading_challenge/${id}/update_seconds_remaining`,
          updateWordCorrect: `/students/${studentId}/student_reading_challenge/${id}/update_word_correct`,
          updateCurrentRound: `/students/${studentId}/student_reading_challenge/${id}/update_current_round`,
          updateWordEnd: `/students/${studentId}/student_reading_challenge/${id}/update_word_end`,
          incrementWordIncorrect: `/students/${studentId}/student_reading_challenge/${id}/increment_word_incorrect`,
          decrementWordIncorrect: `/students/${studentId}/student_reading_challenge/${id}/decrement_word_incorrect`,
          updateTimerSecondsRemaining: `/students/${studentId}/student_reading_challenge/${id}/update_timer_seconds_remaining`,
        }}
        sequenceNumber={sequenceNumber}
        type={type}
        targetGoal={targetGoal}
        durationSeconds={durationSeconds}
        timerSecondsRemaining={timerSecondsRemaining}
        displayTimer={cardDurationTimers && durationTimersOnTimedCards}
        showStickyTimer={showStickyTimer}
      />
    );
  }

  if (
    [
      "StudentLetterNamesAndSoundsCard",
      "StudentSoundAndBlendCard",
      "StudentDecodingByAnalogyCard",
      "StudentWordDecodingCard",
      "StudentTrackingDecodableTextCard",
      "StudentSpellingCard",
    ].includes(type)
  ) {
    return (
      <StudentPlaceholderLessonCard
        assessmentId={id}
        open={open}
        paths={{
          completeAssessment: `/students/${studentId}/student_reading_assessment/${id}/complete_assessment`,
          index: `/students/${studentId}/student_reading_assessment/${id}`,
          updateTimerSecondsRemaining: `/students/${studentId}/student_reading_assessment/${id}/update_timer_seconds_remaining`,
        }}
        sequenceNumber={sequenceNumber}
        type={type}
        targetGoal={targetGoal}
        durationSeconds={durationSeconds}
        timerSecondsRemaining={timerSecondsRemaining}
        displayTimer={cardDurationTimers}
        showStickyTimer={showStickyTimer}
      />
    );
  }

  if (type === "StudentHFWReviewCard") {
    return (
      <HFWReviewCard
        assessmentId={id}
        open={open}
        paths={{
          completeAssessment: `/students/${studentId}/student_reading_assessment/${id}/complete_assessment`,
          index: `/students/${studentId}/student_reading_assessment/${id}`,
          updateTimerSecondsRemaining: `/students/${studentId}/student_reading_assessment/${id}/update_timer_seconds_remaining`,
        }}
        sequenceNumber={sequenceNumber}
        type={type}
        targetGoal={targetGoal}
        durationSeconds={durationSeconds}
        timerSecondsRemaining={timerSecondsRemaining}
        displayTimer={cardDurationTimers}
        showStickyTimer={showStickyTimer}
      />
    );
  }

  if (type === "StudentGreetingCard") {
    return (
      <StudentGreetingCard
        assessmentId={id}
        open={open}
        paths={{
          updateTimerSecondsRemaining: `/students/${studentId}/student_greeting_card/${id}/update_timer_seconds_remaining`,
        }}
        timerSecondsRemaining={timerSecondsRemaining}
        durationSeconds={durationSeconds}
        displayTimer={cardDurationTimers}
        showStickyTimer={showStickyTimer}
      />
    );
  }

  if (type === "StudentNonsenseWordFluency") {
    return (
      <StudentOneMinuteChallenge
        assessmentId={id}
        isConditional={isConditional}
        open={open}
        paths={{
          completeAssessment: `/students/${studentId}/student_nonsense_word_fluency/${id}/complete`,
          index: `/students/${studentId}/student_nonsense_word_fluency/${id}`,
          resetAssessment: `/students/${studentId}/student_nonsense_word_fluency/${id}/reset`,
          startAssessment: `/students/${studentId}/student_nonsense_word_fluency/${id}/start`,
          updateGoal: `/students/${studentId}/student_nonsense_word_fluency/${id}/update_goal`,
          updateSecondsRemaining: `/students/${studentId}/student_nonsense_word_fluency/${id}/update_seconds_remaining`,
          updateWordCorrect: `/students/${studentId}/student_nonsense_word_fluency/${id}/update_word_correct`,
          updateCurrentRound: `/students/${studentId}/student_nonsense_word_fluency/${id}/update_current_round`,
          updateWordEnd: `/students/${studentId}/student_nonsense_word_fluency/${id}/update_word_end`,
          incrementWordIncorrect: `/students/${studentId}/student_nonsense_word_fluency/${id}/increment_word_incorrect`,
          decrementWordIncorrect: `/students/${studentId}/student_nonsense_word_fluency/${id}/decrement_word_incorrect`,
          updateTimerSecondsRemaining: `/students/${studentId}/student_nonsense_word_fluency/${id}/update_timer_seconds_remaining`,
        }}
        sequenceNumber={sequenceNumber}
        type={type + "DEAR"}
        targetGoal={targetGoal}
        durationSeconds={durationSeconds}
        timerSecondsRemaining={timerSecondsRemaining}
        displayTimer={cardDurationTimers && durationTimersOnTimedCards}
        showStickyTimer={showStickyTimer}
      />
    );
  }

  if (type === "StudentPhonologicalAwarenessDrill") {
    return (
      <StudentPhonologicalAwarenessDrill
        assessmentId={id}
        open={open}
        paths={{
          completeAssessment: `/students/${studentId}/student_reading_challenge/${id}/complete`,
          decrementWordIncorrect: `/students/${studentId}/student_reading_challenge/${id}/decrement_word_incorrect`,
          incrementWordIncorrect: `/students/${studentId}/student_reading_challenge/${id}/increment_word_incorrect`,
          index: `/students/${studentId}/student_reading_challenge/${id}`,
          resetAssessment: `/students/${studentId}/student_reading_challenge/${id}/reset`,
          updateWordCorrect: `/students/${studentId}/student_reading_challenge/${id}/update_word_correct`,
          updateWordEnd: `/students/${studentId}/student_reading_challenge/${id}/update_word_end`,
          updateTimerSecondsRemaining: `/students/${studentId}/student_reading_challenge/${id}/update_timer_seconds_remaining`,
        }}
        type={paDrillType}
        durationSeconds={durationSeconds}
        timerSecondsRemaining={timerSecondsRemaining}
        displayTimer={cardDurationTimers}
        showStickyTimer={showStickyTimer}
      />
    );
  }

  if (type === "StudentFluencyAssessment") {
    return (
      <StudentFluencyAssessment
        assessmentId={id}
        gradeLevels={gradeLevels}
        open={open}
        paths={{
          completeAssessment: `/students/${studentId}/student_fluency_assessment/${id}/complete_assessment`,
          completeQuestion: `/students/${studentId}/student_fluency_assessment/${id}/complete_question`,
          completeTask: `/students/${studentId}/student_fluency_assessment/${id}/complete_task`,
          index: `/students/${studentId}/student_fluency_assessment/${id}`,
          resetAssessment: `/students/${studentId}/student_fluency_assessment/${id}/reset_assessment`,
          startAssessment: `/students/${studentId}/student_fluency_assessment/${id}/start_assessment`,
          updateGoal: `/students/${studentId}/student_fluency_assessment/${id}/update_goal`,
          updateNextGoal: `/students/${studentId}/student_fluency_assessment/${id}/update_next_goal`,
          updateSecondsRemaining: `/students/${studentId}/student_fluency_assessment/${id}/update_seconds_remaining`,
          updateWord: `/students/${studentId}/student_fluency_assessment/${id}/update_word`,
          updateTimerSecondsRemaining: `/students/${studentId}/student_fluency_assessment/${id}/update_timer_seconds_remaining`,
        }}
        durationSeconds={durationSeconds}
        timerSecondsRemaining={timerSecondsRemaining}
        displayTimer={cardDurationTimers && durationTimersOnTimedCards}
        showStickyTimer={showStickyTimer}
      />
    );
  }

  if (type === "StudentHighFrequencyWordsDrill") {
    return (
      <StudentHighFrequencyWordsDrill
        paths={{
          updateTimerSecondsRemaining: `/students/${studentId}/student_high_frequency_words/${id}/update_timer_seconds_remaining`,
        }}
        timerSecondsRemaining={timerSecondsRemaining}
        durationSeconds={durationSeconds}
        displayTimer={cardDurationTimers}
        showStickyTimer={showStickyTimer}
      />
    );
  }

  if (type === "StudentSharedReadingAssessment") {
    return (
      <StudentSharedReadingAssessment
        assessmentId={id}
        open={open}
        paths={{
          index: `/students/${studentId}/student_shared_reading_assessments/${id}`,
          updateAssessment: `/students/${studentId}/student_shared_reading_assessments/${id}`,
          updateTask: `/students/${studentId}/student_shared_reading_assessments/:studentReadingAssessmentId/tasks/:id`,
          updateWord: `/students/${studentId}/student_shared_reading_assessments/:studentReadingAssessmentId/words/:id`,
          updateTimerSecondsRemaining: `/students/${studentId}/student_shared_reading_assessments/${id}/update_timer_seconds_remaining`,
        }}
        durationSeconds={durationSeconds}
        timerSecondsRemaining={timerSecondsRemaining}
        displayTimer={cardDurationTimers}
        showStickyTimer={showStickyTimer}
      />
    );
  }

  return null;
};

export default LessonCardRenderer;
