import React from "react";
import { Goal } from "@Ignite-Reading/ui-kit/icons";

import { resourceType, useStudentAssessmentContext } from "./";

export default ({ onChange, value }) => {
  const { resource } = useStudentAssessmentContext();

  return (
    <div className="bg-white border border-gray-200 rounded-lg p-4 pb-6 space-y-3 mb-8">
      <h5 className="flex items-center text-lg font-medium text-zinc-700 space-x-2">
        <Goal className="w-7 h-7" />
        <label htmlFor="nextGoal">Set Next Session Goal</label>
      </h5>
      <div className="flex items-end space-x-1.5 pl-9">
        <input
          className="rounded-md border border-zinc-300 text-sm w-20"
          onChange={onChange}
          type="number"
          value={value}
        />
        <abbr
          className="uppercase text-[10px]"
          title={`${resource === resourceType.WORD ? "Words" : "Letters"} Correct Per Minute`}
        >
          {resource === resourceType.WORD ? "WCPM" : "LCPM"}
        </abbr>
      </div>
    </div>
  );
};
