import cx from "classnames";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import kebabCase from "lodash/kebabCase";
import noop from "lodash/noop";
import words from "lodash/words";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";
import { CheckIcon, PencilSquareIcon } from "@heroicons/react/20/solid";

import useStudentAssessmentContext from "./useStudentAssessmentContext";
import { readingAssessmentStatusType } from "../../constants";
import { useStudentAssessmentQuery } from "./queries";
import { resourceType } from "./";

const { IN_PROGRESS } = readingAssessmentStatusType;

const ReadingStatsItem = ({
  emphasized,
  icon,
  isEditable,
  label,
  mutation,
  unitOfMeasurement: unitOfMeasurementFromProps,
  value: valueFromProps,
}) => {
  const studentAssessmentQuery = useStudentAssessmentQuery();
  const { status } = studentAssessmentQuery.data || {};
  const { resource } = useStudentAssessmentContext();
  const inputRef = useRef();
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(valueFromProps ?? "");
  const unitOfMeasurement =
    unitOfMeasurementFromProps ??
    `${resource === resourceType.WORD ? "Words" : "Letters"} Correct Per Minute`;
  const save = () => {
    mutation.mutate(value, {
      onSuccess: () => {
        setIsEditing(false);
      },
    });
  };
  const onClickEdit = () => {
    setIsEditing(true);
  };
  const onChangeValue = (event) => {
    setValue(event.target.value);
  };
  const onBlurInput = () => {
    if (isEmpty(value)) {
      inputRef.current.focus();
    } else {
      save();
    }
  };
  const onSubmitValue = (event) => {
    if (isEmpty(value)) {
      inputRef.current.focus();
    } else {
      save();
    }
    event.preventDefault();
  };
  const onDocumentKeyUp = useCallback((event) => {
    if (event.key === "Escape") {
      setIsEditing(false);
    }
  }, []);

  useEffect(() => {
    if (isEditing) {
      inputRef.current.focus();
      inputRef.current.select();
      document.addEventListener("keyup", onDocumentKeyUp);
    } else {
      document.removeEventListener("keyup", onDocumentKeyUp);
    }
  }, [isEditing, onDocumentKeyUp]);
  useEffect(() => {
    setValue(isNil(valueFromProps) ? "" : valueFromProps.toString());
  }, [valueFromProps]);

  return (
    <li
      className={twMerge(
        cx(
          "flex-1 bg-gray-200 rounded-lg px-2 md:px-4 pt-2.5 md:pt-3.5 pb-2 md:pb-2.5 max-w-[180px] space-y-1 flex flex-col justify-between relative",
          {
            "bg-brand-100": status === IN_PROGRESS,
            "border border-gray-500": emphasized,
          }
        )
      )}
    >
      <div className="text-[10px] md:text-xs text-zinc-500 pr-6">{label}</div>
      {isEditing ? (
        <form
          className="flex items-center w-full relative -left-[5px] -top-[3px]"
          onSubmit={onSubmitValue}
        >
          <input
            className="rounded-md text-base/none md:text-2xl/none bg-white/30 border-white/50 shrink grow-0 basis-full w-0 p-1"
            min={0}
            onBlur={onBlurInput}
            onChange={onChangeValue}
            ref={inputRef}
            type="number"
            value={value}
          />
          {/* The submit happens when the input above is blurred, so the button below has more of a visual purpose. */}
          <button className="button-secondary py-1.5 px-2.5 ml-4" type="submit">
            <CheckIcon className="w-4 h-4" />
          </button>
        </form>
      ) : (
        <div className="flex items-center space-x-1.5">
          <div
            className="text-base md:text-2xl"
            data-testid={`stats-item-${kebabCase(label)}-value`}
          >
            {isEmpty(value) ? "-" : value}{" "}
            <abbr className="uppercase text-[10px]" title={unitOfMeasurement}>
              {words(unitOfMeasurement)
                .map((word) => word.charAt(0))
                .join("")
                .toUpperCase()}
            </abbr>
          </div>
          {isEditable ? (
            <button
              className="text-zinc-500 hover:text-zinc-600"
              onClick={onClickEdit}
              type="button"
            >
              <PencilSquareIcon className="w-4 h-4" />
            </button>
          ) : null}
        </div>
      )}
      <div className="absolute top-1 right-2.5 w-6 md:w-7 h-6 md:h-7 text-zinc-500 flex items-center justify-center">
        {icon}
      </div>
    </li>
  );
};

ReadingStatsItem.defaultProps = {
  mutation: {
    mutate: noop,
  },
  unitOfMeasurement: null,
};

export default ReadingStatsItem;
