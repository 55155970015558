import cx from "classnames";
import React, {
  cloneElement,
  createContext,
  forwardRef,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  arrow,
  autoUpdate,
  flip,
  FloatingArrow,
  FloatingPortal,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useMergeRefs,
  useRole,
} from "@floating-ui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { twMerge } from "tailwind-merge";

const DEFAULT_Y_AXIS_OFFSET = 10;
const FlIP_PADDING = 20;

const PopoverContext = createContext(null);

const usePopoverContext = () => {
  const context = useContext(PopoverContext);

  if (context === null) {
    throw new Error("Popover components must be wrapped in <Popover />");
  }

  return context;
};

export const PopoverTrigger = forwardRef(
  ({ children, discardPositionReference, ...props }, propRef) => {
    const context = usePopoverContext();
    const ref = useMergeRefs([
      ...(discardPositionReference ? [] : [context.refs.setReference]),
      propRef,
      children?.ref,
    ]);

    return cloneElement(
      children,
      context.getReferenceProps({
        ref,
        ...props,
        ...children.props,
      })
    );
  }
);

/*
 * When using <PopoverPositionReference /> make sure to pass `discardPositionReference`
 * to <PopoverTrigger /> or else the popover will show up in reference to it instead of
 * <PopoverPositionReference />.
 * Ideally we would automatically detect if <PopoverPositionReference /> was used within
 * <PopoverTrigger /> and adjust the reference refs accordingly, but that's a lot of
 * work for so few benefits (and also feels hacky).
 */
export const PopoverPositionReference = forwardRef(({ children, ...props }, propRef) => {
  const context = usePopoverContext();
  const ref = useMergeRefs([context.refs.setPositionReference, propRef, children?.ref]);

  return cloneElement(
    children,
    context.getReferenceProps({
      ref,
      ...props,
      ...children.props,
    })
  );
});

export const PopoverContent = forwardRef(
  ({ children, hideArrow, hideCloseButton, isDarkMode, className, ...props }, propRef) => {
    const { arrowRef, context: floatingContext, ...context } = usePopoverContext();
    const ref = useMergeRefs([context.refs.setFloating, propRef]);
    const onClose = () => {
      floatingContext.onOpenChange(false);
    };

    if (!floatingContext.open) return null;

    return (
      <FloatingPortal>
        <div
          className={twMerge(
            cx(
              "bg-white rounded-md shadow-lg z-[900] ring-1 ring-black ring-opacity-5 w-[calc(100%-40px)] md:w-max",
              {
                "bg-zinc-800 ring-white ring-opacity-10": isDarkMode,
              },
              className
            )
          )}
          ref={ref}
          style={{
            ...context.floatingStyles,
            ...props.style,
          }}
          {...context.getFloatingProps(props)}
        >
          {children}
          {hideCloseButton ? null : (
            <button
              className="absolute top-1.5 right-1.5 text-zinc-400/70 hover:text-zinc-400"
              onClick={onClose}
            >
              {/* Heroicon name: outline/x-mark */}
              <XMarkIcon className="w-5 h-5" />
            </button>
          )}
          {hideArrow ? null : (
            <FloatingArrow
              className="stroke-zinc-200"
              context={floatingContext}
              fill="white"
              ref={arrowRef}
              strokeWidth={1}
            />
          )}
        </div>
      </FloatingPortal>
    );
  }
);

export const Popover = ({
  children,
  offset: offsetConfig,
  onOpenChange: controlledOnOpenChange,
  open: controlledOpen,
  placement,
  toggle = false,
}) => {
  const arrowRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const floating = useFloating({
    middleware: [
      offset({ mainAxis: DEFAULT_Y_AXIS_OFFSET, ...offsetConfig }),
      flip(),
      shift({ padding: FlIP_PADDING }),
      arrow({ element: arrowRef }),
    ],
    open: controlledOpen || isOpen,
    placement,
    onOpenChange: controlledOnOpenChange || setIsOpen,
    whileElementsMounted: autoUpdate,
  });
  const click = useClick(floating.context, { toggle });
  const dismiss = useDismiss(floating.context);
  const role = useRole(floating.context);
  const interactions = useInteractions([click, dismiss, role]);
  const value = useMemo(
    () => ({
      ...floating,
      ...interactions,
      arrowRef,
    }),
    [floating, interactions]
  );

  return <PopoverContext.Provider value={value}>{children}</PopoverContext.Provider>;
};
