import cx from "classnames";
import React, { useMemo, useState } from "react";
import { AddStopWord } from "@Ignite-Reading/ui-kit/icons";

import { Tooltip, TooltipContent, TooltipTrigger } from "../common/Tooltip";
import { readingAssessmentStatusType, studentOneMinuteChallengeType } from "../../constants";
import {
  useDecrementWordIncorrectMutation,
  useIncrementWordIncorrectMutation,
  useUpdateWordCorrectMutation,
  useUpdateWordEndMutation,
} from "./queries";
import {
  useStudentAssessmentContext,
  useStudentAssessmentQuery,
  UtteranceItemPopover,
} from "../StudentAssessment";

const { StudentLetterNameDrill, StudentLetterSoundDrill, StudentConditionalLetterNameDrill } =
  studentOneMinuteChallengeType;

export default ({
  correct,
  disabled: disabledFromProps,
  incorrectCount,
  isEndWord,
  label,
  readingChallengeWordId,
  round,
}) => {
  const { type } = useStudentAssessmentContext();
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const studentAssessmentQuery = useStudentAssessmentQuery();
  const updateWordCorrectMutation = useUpdateWordCorrectMutation();
  const updateWordEndMutation = useUpdateWordEndMutation();
  const incrementWordIncorrectMutation = useIncrementWordIncorrectMutation();
  const decrementWordIncorrectMutation = useDecrementWordIncorrectMutation();
  const { secondsRemaining } = studentAssessmentQuery.data || {};
  const onClick = () => {
    if (secondsRemaining > 0) {
      updateWordCorrectMutation.mutate({
        correct: correct === false,
        readingChallengeWordId,
        round,
      });
    } else {
      updateWordEndMutation.mutate({ correct, ended: true, round, readingChallengeWordId });
    }
  };
  const onMarkStopWord = () => {
    updateWordEndMutation.mutate({ correct, ended: true, round, readingChallengeWordId });
    setIsTooltipOpen(false);
  };
  const onAddError = () => {
    incrementWordIncorrectMutation.mutate(readingChallengeWordId);
    setIsTooltipOpen(false);
  };
  const onUndoError = () => {
    decrementWordIncorrectMutation.mutate(readingChallengeWordId);
    setIsTooltipOpen(false);
  };
  const enableTooltip = useMemo(() => {
    return (
      studentAssessmentQuery?.data?.status === readingAssessmentStatusType.IN_PROGRESS &&
      studentAssessmentQuery?.data?.secondsRemaining === 0 &&
      studentAssessmentQuery?.data?.endItemId !== null
    );
  }, [
    studentAssessmentQuery?.data?.endItemId,
    studentAssessmentQuery?.data?.secondsRemaining,
    studentAssessmentQuery?.data?.status,
  ]);
  const isLoading =
    updateWordCorrectMutation.isLoading ||
    updateWordEndMutation.isLoading ||
    incrementWordIncorrectMutation.isLoading ||
    decrementWordIncorrectMutation.isLoading;
  const disabled = disabledFromProps || isLoading;

  return (
    <Tooltip
      closeDelay={120}
      enabled={enableTooltip}
      open={isTooltipOpen}
      openDelay={320}
      onOpenChange={setIsTooltipOpen}
    >
      <TooltipTrigger>
        <button
          className={cx(
            "button-secondary text-sm text-center w-full py-4 relative group break-words hyphens-auto",
            {
              "bg-fuchsia-100 enabled:hover:bg-fuchsia-200 disabled:bg-fuchsia-50 ring-zinc-400 disabled:ring-zinc-300":
                incorrectCount > 0,
              "bg-orange-100 enabled:hover:bg-orange-200 disabled:bg-orange-50 ring-zinc-400 disabled:ring-zinc-300":
                isEndWord,
            }
          )}
          disabled={disabled}
          onClick={onClick}
          type="button"
        >
          <span
            className={cx({
              "font-['Roboto_Serif']":
                type === StudentLetterNameDrill ||
                type === StudentLetterSoundDrill ||
                type === StudentConditionalLetterNameDrill,
            })}
          >
            {label}
          </span>
          {incorrectCount > 0 ? (
            <span className="absolute top-1 right-1 rounded-full text-white bg-rose-500 text-xs px-1 min-w-[18px] h-[18px] flex items-center justify-center group-disabled:opacity-50">
              {incorrectCount}
            </span>
          ) : null}
          {isEndWord ? (
            <AddStopWord className="w-5 h-5 text-zinc-500 absolute top-1 left-1 z-10" />
          ) : null}
        </button>
      </TooltipTrigger>
      <TooltipContent>
        <UtteranceItemPopover
          disabled={isLoading}
          onAddError={onAddError}
          onUndoError={onUndoError}
          onMarkStopWord={onMarkStopWord}
          setIsTooltipOpen={setIsTooltipOpen}
        />
      </TooltipContent>
    </Tooltip>
  );
};
