import React from "react";

import { useStudentAssessmentContext } from "./index";
import { BellAlertIcon } from "@heroicons/react/24/solid";
import ChooseStopWord from "assets/images/choose-stop-word.svg";

export default ({ BeforeCountdownTimer, children }) => {
  const { resource } = useStudentAssessmentContext();

  return (
    <div className="flex flex-col xl:flex-row-reverse justify-between space-y-6 xl:space-y-0">
      <div className="grow-0 shrink-0 xl:basis-[350px] xl:pl-6 xl:border-l border-zinc-200">
        <BeforeCountdownTimer />
        <button
          className="button-secondary disabled:bg-yellow-50 disabled:ring-yellow-400 w-full relative z-1 text-center text-3xl disabled:text-zinc-500 font-medium h-[44px] flex items-center justify-center -mx-2.5 xl:mx-0"
          disabled
          type="button"
        >
          00:00
        </button>
      </div>
      <div className="shrink grow basis-auto flex flex-col flex-1 xl:pr-8 relative">
        <div className="border border-yellow-400 bg-yellow-50 rounded-lg px-2 xl:px-6 py-4 md:py-6 space-y-2 md:space-y-3 -mx-2.5 xl:-mx-6 -my-2.5">
          <div className="flex items-center space-x-2">
            <BellAlertIcon className="w-8 h-8 flex-none text-yellow-500" />
            <h1 className="text-2xl md:text-3xl text-zinc-500 font-bold">Time’s Up!</h1>
          </div>
          <div className="flex items-start max-w-[34rem]">
            <div className="flex-1 text-sm space-y-1 md:space-y-1.5 xl:space-y-0 pt-1 md:pt-4">
              <p className="text-zinc-500">
                Calculate the results by marking the last {resource} the student read.
              </p>
              <p className="text-zinc-600">
                <strong>Click on the last {resource} attempted.</strong>
              </p>
            </div>
            <ChooseStopWord className="flex-none w-20 md:w-24" />
          </div>
          <div className="shrink grow basis-auto flex flex-col flex-1 relative">
            <div className="flex flex-col">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
